export const CONTACT_US = 'SPEAK TO OUR TEAM'
export const RESET_MY_PASSWORD = 'RESET MY PASSWORD'
export const RESET_PASSWORD = 'Reset Password'
export const FORGOT_PASSWORD = 'Forgot your password?'
export const REMEMBER_ME = 'Remember me'
export const REGISTERED_EMAIL =
  'Enter registered email to get reset instructions'
export const BACK_TO_LOGIN = 'Back to login'
export const LOGIN = 'LOGIN'
export const PRODUCT_DESCRIPTION =
  // eslint-disable-next-line max-len
  'Maximising your potential '
export const PRODUCT_DESCRIPTION_LINE2 = 'as a high performing leader'
export const PRODUCT_TAGLINE = 'Maximising your potential '
export const PRODUCT_SUB_TAGLINE = 'to be a high performing leader'
export const SURVEYS_DESCRIPTION = 'Looking for Hassel free surveys ?'
export const WELCOME_BACK = 'Welcome back!'
export const LOGIN_DESCRIPTION = 'login to your 360 portal'
export const DASHBOARD = 'Dashboard'
export const DASHBOARD_SUB_TITLE = 'Check your overall statics'
export const COMPANY_SUB_TITLE = 'Dashboard > Company'
export const COMPANY = 'COMPANY'
export const CREATE_NEW = 'Create New'
export const DO_NOT_HAVE_COMPANY =
  "You don't have any company or organization associated with your account please create one to proceed"
export const COPY_RIGHT =
  'Copyright © 2021 GrowMetrix PTY LTD. All Rights Reserved.'
export const ADD_NEW_COMPANY = 'Add New Company'
export const RESET_WELCOME = 'Reset password'
export const RESET_WELCOME_SUB =
  'Enter registered email to get reset instructions'
export const RESET_BUTTON_TEXT = 'RESET MY PASSWORD'
export const ENTER_CRADENTIAL = 'Please Enter Email and Password'
export const WRONG_CRADENTIAL = 'Email or Password is wrong'
export const RESET_YOUR_PASSWORD = 'Reset your password'
export const CHANGE_YOUR_PASSWORD = 'Change your password'
export const CHANGE_PASSWORD = 'CHANGE PASSWORD'
export const ENTER_PASSWORD = 'Please enter both password'
export const PASSWORD_NOT_MATCH = 'Both password does not match'
export const TOKEN_INVALID = 'Token is invalid'
export const DATE_FORMAT = 'YYYY/MM/DD'
export const ENTER_VALID_EMAIL = 'Please Enter Valid Email'
export const ENTER_VALID_PASSWORD = 'Please Enter Valid Password'
export const DO_NOT_HAVE_PROJECT =
  "You don't have any project associated with your company please create one to proceed"
export const DO_NOT_HAVE_USER =
  "You don't have any user associated with your company please create one to proceed"
export const DO_NOT_EMPLOYEE =
  "You don't have any user please create one to proceed"

// user role
export const SUPER_ADMIN_ROLE = 'super admin'
export const COMPANY_ROLE = 'company'
export const EMPLOYEE_ROLE = 'employee'

export const ROLE_KEYWORD = 'role'
export const TOKEN_KEYWORD = 'gm360_token'
export const USERNAME_KEYWORD = 'gm360_name'
export const CALIBRIBOLD = ' '
