/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import { Row, Col, Checkbox, Modal, Button, notification, Drawer } from 'antd'
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import empHeadIcon from '../../../../../assets/images/Group 9011.svg'
import WPForm from '../../../../../assets/images/Icon awesome-wpforms.svg'
import UserCirlce from '../../../../../assets/images/Icon awesome-user-circle.svg'
import PopupConfirm from '../../../../../assets/images/Group 9366.svg'
import { readCookie } from '../../../../../api/CookieScript'
import { removeParticipantApi } from '../../../../../api/API'
import { ROLE_KEYWORD, EMPLOYEE_ROLE } from '../../../../../constants/index'
import { EditParticipantForEmployee } from './EditParticipantForEmployee'

interface Props {
  data: any
  getDetail: any
  getProjectDetailFn: any
}

// when click on card get data from api and fill in details tab
export const ParticipantCard: React.FC<Props> = ({
  data,
  getDetail,
  getProjectDetailFn,
}) => {
  // state for employee user is login edit participant
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [participationId, setparticipationId] = useState('')
  const [DeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const { id } = useParams<any>() // get project id from url

  // Remove Participant api function
  // pass participant id as pid & project id as id to api for remove participant
  const deleteParticipant = (pid: any): any => {
    removeParticipantApi(pid, id).then((res: any) => {
      if (res.status !== 200) {
        notification.error({
          message: 'something went wrong refresh pages',
        })
      } else {
        notification.success({
          message: 'Participant Remove successfully',
        })
      }
    }) // api function over
  }

  return (
    <div className="border p-2 user_card">
      <Row justify="space-between">
        <Col xs={19} md={12}>
          <input type="checkbox" className="checkbox" />
        </Col>
        <Col md={4} className="text-end usercard-dropdown float-end p-0">
          <DropdownButton
            title={<MoreOutlined />}
            className="more-outlined btn p-0"
          >
            <Dropdown.Item
              className="cursor-pointer"
              // onClick={() => getDetail(data._id, 'false')}
              onClick={() => {
                setReadOnly(true)
                setDrawerVisible(true)
              }}
            >
              <span className="pe-2">
                <EyeOutlined />
              </span>
              View
            </Dropdown.Item>
            <Dropdown.Item
              className="cursor-pointer"
              // onClick={() => getDetail(data._id, 'true')}
              onClick={() => {
                setparticipationId(data._id)
                setReadOnly(false)
                setDrawerVisible(true)
              }}
            >
              <span className="pe-2">
                <EditOutlined />
              </span>
              Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setDeleteModalVisible(true)}>
              <span className="pe-2">
                <DeleteOutlined />
              </span>
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      {/* open view participant onclick for employee user */}
      <Row
        onClick={() => {
          setparticipationId(data._id)
          setDrawerVisible(true)
        }}
        className="cursor-pointer"
      >
        {/* <Row onClick={() => getDetail(data._id)} className="cursor-pointer"> */}
        <Col xs={4} sm={6} md={5} lg={8} xl={3} className="ms-4">
          <img
            src={data.image ? data.image : UserCirlce}
            alt="not availabel"
            className="company-logo"
          />
        </Col>
        <Col xs={16} sm={18} md={15} lg={12} xl={12} className="ms-2">
          <b>{data.name}</b>
          <Row justify="start">
            <Col xs={13} sm={24} md={24} lg={24} xl={12}>
              <p>
                <UserOutlined />
                &nbsp;&nbsp;
                {data.raters && data.raters.length > 0
                  ? data.raters[0].title
                  : 'Not Assign'}
              </p>
            </Col>
            <Col xs={11} sm={24} md={24} lg={24} xl={12}>
              <p>
                <img src={WPForm} alt="Document" className="w-15" />
                &nbsp;&nbsp;{data.phone}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="cursor-pointer">
        <Col md={12}>
          {/* {data.online && <div className="live-status-circle" />} */}
          <div
            className="live-status-circle"
            style={{
              backgroundColor:
                data.raters && data.raters.length > 0
                  ? data.raters[0].color
                  : '#000',
            }}
          />
        </Col>
        {/* <Col md={12} className="text-end">
          <img src={data.gravater} alt="not availabel" className="gravatar" />
        </Col> */}
      </Row>

      {/* participant drawer open when employee user login */}
      {readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE && (
        <div className="drawer-wrapper">
          <Drawer
            onClose={() => {
              setDrawerVisible(false)
            }}
            visible={drawerVisible}
            title={
              <span>
                <img
                  src={empHeadIcon}
                  alt="Edit Participation"
                  className="drawer-head-icon me-3"
                />
                {readOnly ? 'View Participation' : 'Edit Participation'}
              </span>
            }
            width={window.innerWidth > 550 ? 465 : '100%'}
          >
            <EditParticipantForEmployee
              readOnly={readOnly}
              // project={data}
              participantData={data}
              participationId={participationId}
              // projectid={id}
              setParticipantDrawerVisible={setDrawerVisible}
              getProjectDetailFn={getProjectDetailFn}
            />
          </Drawer>
        </div>
      )}
      <Modal
        visible={DeleteModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">Are you sure you want to Delete?</p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setDeleteModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                deleteParticipant(data._id)
                setDeleteModalVisible(false)
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
