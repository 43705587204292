/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { notification, Drawer } from 'antd'
import { Table, DropdownButton, Dropdown } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { UserList } from '../userlistdata'
import DrawerFooter from '../../../../../../components/Drawer/footer/drawerFooter'
import TrashIcon from '../../../../../../assets/images/Icon feather-trash-2.svg'
import UserIcon from '../../../../../../assets/images/Icon awesome-user-circle.svg'
import MoreIcon from '../../../../../../assets/images/more.svg'
import empHeadIcon from '../../../../../../assets/images/Group 9011.svg'
import {
  removeParticipantApi,
  getSingleParticipationDataApi,
} from '../../../../../../api/API'
import { EditParticipantForm } from './EditParticipantForm'

interface Props {
  participantList: any
  employeeId: any
  getDetail: any
}

export const UserTab: React.FC<Props> = ({
  participantList,
  employeeId,
  getDetail,
}): JSX.Element => {
  // get project id from url
  const params = useParams<any>()
  const [participantDrawerVisible, setParticipantDrawerVisible] =
    useState(false)
  const [participationId, setParticipationId] = useState('')
  const [participantData, setParticipantData] = useState({})
  const { id } = params // project id

  const removeParticipation = (patId: any): void => {
    // pass participation id and project id to api function to delete
    removeParticipantApi(patId, id).then((res: any) => {
      if (res.status !== 200) {
        notification.error({
          message: 'something went wrong refresh pages',
        })
      } else {
        notification.success({
          message: 'Distribution Removed successfully',
        })
        getDetail(employeeId, 'false')
      }
    })
  }

  // get single participant data
  const getParticipant = (patId: any): void => {
    getSingleParticipationDataApi(patId).then((res: any) => {
      // const pdata = {
      setParticipantData({
        company: res.data.data._id,
        name: res.data.data.name,
        first_name: res.data.data.first_name,
        last_name: res.data.data.last_name,
        email: res.data.data.email,
        phone: res.data.data.phone,
        selected_raters: res.data.data.selected_raters,
        employee_id: res.data.data.employee_id,
        project_id: id,
        participationId: patId,
      })
    })
  }
  return (
    <>
      <Table responsive className="user_detail_rater_list">
        <thead>
          <tr>
            <th>Name</th>
            {/* <th>Emp ID</th>
            <th>Surveys</th> */}
            <th>Designation</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {participantList.map((user: any, index: any) => (
            <tr key={index}>
              <td>
                <img src={UserIcon} alt="trash" className="w-25" />
                &nbsp;{user.name}
                {/* &nbsp;{user._id} participatin id */}
              </td>
              {/* <td>{user.employee_id ? user.employee_id.emp_id : ''}</td>
              <td>01</td> */}
              <td>{user.selected_raters ? user.selected_raters.title : ''}</td>
              {/* <td>
                <div className="d-flex justify-content-center">
                  <img src={TrashIcon} alt="trash" className="w-50" />
                  <DropdownButton
                    title={<img src={MoreIcon} alt="trash" className="w-75" />}
                    className="more-outlined"
                  >
                    <Dropdown.Item>Edit</Dropdown.Item>
                    <Dropdown.Item>Delete</Dropdown.Item>
                  </DropdownButton>
                </div>
              </td> */}
              <td>
                {/* <img
                  src={TrashIcon}
                  alt="trash"
                  className="w-50"
                  onClick={() => removeParticipation(user._id)}
                /> */}
                <DropdownButton
                  title={<img src={MoreIcon} alt="trash" className="w-100" />}
                  className="more-outlined"
                >
                  <Dropdown.Item
                    onClick={() => {
                      setParticipationId(user._id)
                      getParticipant(user._id)
                      setParticipantDrawerVisible(true)
                    }}
                  >
                    <EditOutlined
                      className="user-popup-edit-icon"
                      style={{ marginRight: '10px' }}
                    />
                    Edit
                  </Dropdown.Item>
                  {/* here we pass particpation id to delete record */}
                  <Dropdown.Item onClick={() => removeParticipation(user._id)}>
                    <DeleteOutlined
                      className="user-popup-edit-icon"
                      style={{ marginRight: '10px' }}
                    />
                    Delete
                  </Dropdown.Item>
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="bg-EDECEC p-2 m-3 user-popup-note">
        <b>Please Note: </b>
        <p>
          User will receive an invite email to join to the particular company
        </p>
      </div>
      <div className="drawer-wrapper">
        <Drawer
          onClose={() => {
            setParticipantDrawerVisible(false)
          }}
          visible={participantDrawerVisible}
          title={
            <span>
              <img
                src={empHeadIcon}
                alt="Edit Participation"
                className="drawer-head-icon me-3"
              />
              Edit Participation
            </span>
          }
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <EditParticipantForm
            // project={data}
            participantData={participantData}
            participationId={participationId}
            projectid={id}
            setParticipantDrawerVisible={setParticipantDrawerVisible}
          />
        </Drawer>
      </div>
    </>
  )
}
