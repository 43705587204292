/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
// import '../styles/chart.css'
import { useState, useEffect } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { Select } from 'antd'
import {
  getParticipantSurveyStatusApi,
  getSingleEmployeeSurveyStatusApi,
} from '../../../../../../api/API'

const { Option } = Select

interface Props {
  participantList: any
  employeeId: any
  details: any
}

export const EmployeeChart: React.FC<Props> = ({
  participantList,
  employeeId,
  details,
}): JSX.Element => {
  const [empId, setEmpId] = useState(employeeId)
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(0)
  const data = [
    {
      name: 'Not Completed Survey',
      // survey: notCompleteSurvey,
      survey: 100 - completeSurvey,
      color: '#A6CEE3',
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: '#1F78B4',
    },
  ]

  // function call on change and get chart data
  const getParticpantChartData = (projectId: any): any => {
    if (projectId) {
      const apidata = {
        employee_id: empId,
        participant_id: 'all',
        project_id: projectId,
      }
      getSingleEmployeeSurveyStatusApi(apidata).then((res: any) => {
        setCompleteSurvey(res.data.data.completedSurveyStatus)
        setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
      })
    }
  }

  return (
    <>
      <h5>Overall Employee</h5>
      <div className="position-relative mt-1">
        <Select
          defaultValue=""
          style={{ width: '100%' }}
          onChange={(e) => getParticpantChartData(e)}
        >
          <Option value="">Select Project</Option>
          {details.project_id.map((project: any, index: any) => (
            <Option key={project._id} value={project._id}>
              {project.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="statsbody d-flex justify-content-around mt-0">
        <div className="pie">
          <PieChart width={250} height={250}>
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={100}
              innerRadius={50}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <p className="percent-e">{completeSurvey}%</p>
        </div>
      </div>
      <div className="d-flex justify-content-around">
        {data.map((entry, index) => (
          <div className="d-flex" key={entry.name}>
            <div
              className="dot1 me-3"
              style={{ backgroundColor: entry.color }}
            />
            <p>{entry.name}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default EmployeeChart
