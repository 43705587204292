import React from 'react'
import { Row, Col, Button } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'
import EmployeeChart from './components/Charts/employee-chart'
import RaterChart from './components/Charts/rater-chart'
import { ProjectDetailFrom } from './components/detail/ProjectDetailFrom'
import shareIcon from '../../../assets/images/Icon feather-share-2.svg'

interface Prop {
  detail: any
  projectId: any
}

export const Details: React.FC<Prop> = ({ detail, projectId }): JSX.Element => {
  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={15} className="border">
          <Row className="p-3 pb-0">
            <Col xs={18} sm={20} md={20} lg={20}>
              <b>Participation</b>
              <p>Check overall Participation up to date</p>
            </Col>
            <Col xs={6} sm={4} md={4} lg={4} className="text-end">
              {/* <Button
                icon={<img src={shareIcon} alt="share" className="pe-2" />}
                style={{ borderColor: '#696867' }}
              >
                Share
              </Button> */}
            </Col>
          </Row>
          <hr className="m-0 p-0" />
          <Row className="justify-content-center justify-content-xl-around">
            <Col className="text-center">
              <EmployeeChart />
            </Col>
            <Col className="text-center vertical-line" />
            <Col className="text-center">
              <RaterChart />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={9}
          className="pt-lg-2 pt-xl-0 ps-xl-2"
        >
          {detail && (
            <ProjectDetailFrom details={detail} projectId={projectId} />
          )}
        </Col>
      </Row>
    </div>
  )
}
