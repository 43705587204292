/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Row, Col } from 'antd'
import { UserCard } from './components/user/userCard'
import { readCookie } from '../../../api/CookieScript'
import {
  COMPANY_ROLE,
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
  ROLE_KEYWORD,
} from '../../../constants'
import { ParticipantCard } from './components/user/participantCard'

interface Props {
  // eslint-disable-next-line react/require-default-props
  getProjectsEmployee: any
  userlist: any
  getDetail: any
  getProjectDetailFn: any
}

export const UserGrid: React.FC<Props> = ({
  getProjectsEmployee,
  userlist,
  getDetail,
  getProjectDetailFn,
}): JSX.Element => {
  return (
    <>
      <Row gutter={[6, 6]}>
        {userlist &&
          userlist.length > 0 &&
          userlist.map((user: any, index: any) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={index}>
              {(readCookie(ROLE_KEYWORD) === COMPANY_ROLE ||
                readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE) && (
                <UserCard
                  getProjectsEmployee={getProjectsEmployee}
                  data={user}
                  getDetail={getDetail}
                  getProjectDetailFn={getProjectDetailFn}
                />
              )}
              {readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE && (
                <ParticipantCard
                  data={user}
                  getDetail={getDetail}
                  getProjectDetailFn={getProjectDetailFn}
                />
              )}
            </Col>
          ))}
      </Row>
    </>
  )
}

export default UserGrid
