import { data } from 'jquery'
import React from 'react'
import './styles/CardComp.scss'
import { readCookie } from '../../api/CookieScript'
import {
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
  COMPANY_ROLE,
  ROLE_KEYWORD,
} from '../../constants'

interface Props {
  dashboarddata: any
}
export const CardComp: React.FC<Props> = ({ dashboarddata }): JSX.Element => {
  return (
    <div
      className={`analytics-wrapper ${
        readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE
          ? 'justify-content-between'
          : 'justify-space-around'
      }`}
    >
      {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
        <div
          className="col-xs-12 col-sm-12 col-md-3 col-lg-3 outer1"
          style={{ display: 'none' }}
        >
          <div className="col-md-7 col-lg-9 inner1">
            <span className="text1">TOTAL COMPANY</span>
            {/* <div className="in1">
              <span className="text2">+1</span>
              <span className="text3">(09-08-2021)</span>
            </div> */}
          </div>
          <div className="col-md-5 inner2">
            <span className="text4">{dashboarddata.total_company}</span>
          </div>
        </div>
      )}

      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-6 outer2"
        style={{ display: 'none' }}
      >
        <div className="inner_left">
          <div className="inner3">
            <span className="text5">{dashboarddata.total_project}</span>
            <div className="in2">
              <span className="text6">TOTAL PROJECTS</span>
              {/* <div className="joinText">
                <span className="text7">+1</span>
                <span className="text8">(09-08-2021)</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="d-flex small_counter">
          <div className="me-5 ms-5 in3">
            <span className="text9">{dashboarddata.active_project}</span>
            <div className="text10">Active Projects</div>
          </div>
          <div className="bso" />
          <div className="me-5 ms-5 in4">
            <span className="text11">{dashboarddata.close_project}</span>
            <div className="text12">Closed Projects</div>
          </div>
          <div className="bso" />
          {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE &&
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE && (
              <>
                <div className="me-5 ms-5 in4">
                  <span className="text11">{dashboarddata.total_employee}</span>
                  <div className="text12">Total Employees</div>
                </div>
                <div className="bso" />
              </>
            )}

          <div className="me-5 ms-5 in4">
            <span className="text11">{dashboarddata.total_participant}</span>
            <div className="text12">Total Raters</div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start w-100 dashBoardCounter">
        {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
          <div className="inner_left outer1">
            <div className="inner3">
              <span className="text5">{dashboarddata.total_company}</span>
              <div className="in2">
                <span className="text6">TOTAL COMPANY</span>
              </div>
            </div>
          </div>
        )}
        <div className="inner_left outer1">
          <div className="inner3">
            <span className="text5">{dashboarddata.total_project}</span>
            <div className="in2">
              <span className="text6">TOTAL PROJECTS</span>
            </div>
          </div>
        </div>
        <div className="inner_left outer1">
          <div className="inner3">
            <span className="text5">{dashboarddata.active_project}</span>
            <div className="in2">
              <span className="text6">Active Projects</span>
            </div>
          </div>
        </div>
        <div className="inner_left outer1">
          <div className="inner3">
            <span className="text5">{dashboarddata.close_project}</span>
            <div className="in2">
              <span className="text6">Closed Projects</span>
            </div>
          </div>
        </div>
        {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE &&
          readCookie(ROLE_KEYWORD) === COMPANY_ROLE && (
            <div className="inner_left outer1">
              <div className="inner3">
                <span className="text5">{dashboarddata.total_employee}</span>
                <div className="in2">
                  <span className="text6">Total Employees</span>
                </div>
              </div>
            </div>
          )}

        <div className="inner_left outer1">
          <div className="inner3">
            <span className="text5">{dashboarddata.total_participant}</span>
            <div className="in2">
              <span className="text6">Total Raters</span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-3 outer3">
        <div className="col-md-6 inner3">
          <span className="text5">02</span>
          <span className="text6">TOTAL TICKETS</span>
          <div className="in2">
            <span className="text7">+1</span>
            <span className="text8">(09-08-2021)</span>
          </div>
        </div>
        <div className="col-md-6 inner4">
          <div className="in3">
            <span className="text9">01</span>
            <span className="text10">New Tickets</span>
          </div>
          <div className="in4">
            <span className="text11">01</span>
            <span className="text12">in Review</span>
          </div>
        </div>
      </div> */}
      {/* <div className="col-xs-12 col-sm-12 col-md-6 outer3">
        <div className="inner_left">
          <div className="inner3">
            <span className="text5">02</span>
            <div className="in2">
              <span className="text6">TOTAL TICKETS</span>
              <div className="joinText">
                <span className="text7">+1</span>
                <span className="text8">(09-08-2021)</span>
              </div>
            </div>
          </div>
        </div> 
        <div className="inner_right">
          <div className="in3 mfo bso">
            <span className="text9">01</span>
            <span className="text10">New Tickets</span>
          </div>
          <div className="in4 ufo">
            <span className="text11">01</span>
            <span className="text12">In Review</span>
          </div> 
        </div> 
      </div> */}
    </div>
  )
}

export default CardComp
