/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import './styles/employeeStats.scss'

import { PieChart, Pie, Cell } from 'recharts'
import { Select } from 'antd'
import { getProjectList, EmployeeChartApi } from '../../../../api/API'

const { Option } = Select

interface Props {
  controller: any
}

const EmployeeChart: React.FC<Props> = ({ controller }) => {
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(100)
  const [defaultProject, setDefaultProject] = useState('')
  // const ndata = data === undefined ? [] : data
  const [projectList, setProjectList] = useState([])
  // get project list
  const getProjectListData = (): any => {
    getProjectList(controller).then((res: any): any => {
      // setDefaultProject(res.data.data[0].name)
      setProjectList(res.data.data)
      // eslint-disable-next-line no-console
      // console.log(res.data.data)
      console.log(res.data.data[0].name)
      getEmpChartData(res.data.data[0]._id)
    })
  }
  // get chart of given project id
  const getEmpChartData = (projectid: any): any => {
    EmployeeChartApi(projectid, controller).then((res: any): any => {
      // setChartData(res.data.data)
      setCompleteSurvey(res.data.data.completedSurveyStatus)
      setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    })
  }
  useEffect(() => {
    getProjectListData()
  }, [])
  const data = [
    {
      name: 'Not Completed Survey',
      survey: 100 - completeSurvey,
      color: '#fedd84',
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: '#febe13',
    },
  ]

  return (
    <div className="emp-pie-wrapper">
      <div className="emp-statsbody">
        <div className="emp-dropdown-wrapper w-100">
          <Select
            // placeholder="Project List"
            className="ms-4 p-1 border-0 emp-project-dropdown ms-sm-0 position-relative w-100"
            id="dropdown-basic"
            placeholder={defaultProject || 'Select Project'}
            onChange={(e) => getEmpChartData(e)}
          >
            <Option value="">Select Project</Option>
            {projectList.length > 0 &&
              projectList.map((item: any) => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </div>
        <div className="emp-pie">
          <PieChart width={308} height={308} className="">
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={110}
              innerRadius={55}
              // fill="#8884d8"
            >
              {data.map((entry: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <span className="emp-percent">{completeSurvey}%</span>
        </div>
      </div>
      <div className="d-flex justify-content-around w-75 ms-5">
        <div className="d-flex w-50">
          <div className="emp-dot1 me-3" />
          <span className="emp-label-text">Not Completed Survey</span>
        </div>
        <div className="d-flex">
          <div className="emp-dot2 me-3" />
          <span className="emp-label-text">Completed Survey</span>
        </div>
      </div>
    </div>
  )
}
export default EmployeeChart
