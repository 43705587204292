/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import {
  notification,
  Form,
  Input,
  InputNumber,
  Button,
  Upload,
  Select,
} from 'antd'
import { useParams } from 'react-router-dom'
import { getAllRaters, updateParticipationApi } from '../../../../../api/API'
import CheckBox from '../../../../../components/Checkbox'
import Doubt from '../../../../../assets/images/users.svg'

interface Props {
  setParticipantDrawerVisible: any
  //   projectid: any
  participationId: any
  participantData: any
  readOnly: any
  getProjectDetailFn: any
}

export const EditParticipantForEmployee: React.FC<Props> = ({
  setParticipantDrawerVisible,
  participationId,
  participantData,
  readOnly,
  getProjectDetailFn,
}): JSX.Element => {
  const [name, setname] = useState(participantData.name)
  const [email, setemail] = useState(participantData.email)
  const [phone, setphone] = useState(participantData.phone)
  //   const [chk, setchk] = useState(false)
  const [raterList, setRaterList] = useState([])

  const [selected_raters, setselectedRaters] = useState(
    participantData.selected_raters,
  )
  //   const [employeeList, setEmployeeList] = useState<any>([])
  const [employeeId, setEmployeeId] = useState<any>(participantData.employee_id)
  const [pId, setParticipationId] = useState<any>(participationId)
  const [projectId, setProjectId] = useState<any>(participantData.project_id)

  // Error Stats
  const [nameErr, setnameErr] = useState('')
  const [emailErr, setemailErr] = useState('')
  const [phoneErr, setphoneErr] = useState('')

  const params = useParams<any>()
  const { id } = params // project id
  const { Option } = Select

  useEffect(() => {
    setname(participantData.name)
    setemail(participantData.email)
    setphone(participantData.phone)
    setselectedRaters(participantData.selected_raters)
    setEmployeeId(participantData.employee_id)
    console.log(participantData)

    // get list of raters using project id
    getAllRaters(projectId).then((res: any) => {
      const { data } = res.data
      setRaterList(data)
    })
  }, [participantData])

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  const phoneRegex = new RegExp('^[0-9]*$')

  // call on Submit button click
  const handleSubmit = (): void => {
    setnameErr('')
    setemailErr('')
    setphoneErr('')

    // check Validation
    let valid = true
    if (name == '') {
      setnameErr('Please Enter Valid Name')
      valid = false
    }
    if (email == '' || !email.match(emailRegex)) {
      setemailErr('Please Enter Valid Email')
      valid = false
    }
    if (!phone.match(phoneRegex)) {
      setphoneErr('Please Enter Valid Phone')
      valid = false
    }
    if (valid) {
      // Create Object of Updated Data
      const apidata = {
        name,
        phone,
        email,
        selected_raters,
        company: participantData.company._id,
        project_id: participantData.project_id,
        employee_id: employeeId,
        participation_id: participationId,
      }

      // edit participant api
      updateParticipationApi(apidata, participationId).then((res: any) => {
        if (res.status !== 200) {
          notification.error({
            message: 'something went wrong refresh pages',
          })
        } else {
          setParticipantDrawerVisible(false)
          notification.success({
            message: 'Participant Update successfully',
          })
          getProjectDetailFn(params.id)
        }
      }) // api function over
    } // if over
  }
  return (
    <Form
      // name="participationForm"
      className="form-wrapper p-3"
      onFinish={handleSubmit}
      // initialValues={participantData}
    >
      <div className="survey-inner-formd">
        <div className="form-group">
          <label htmlFor="inputName4" className="form-label">
            Name
            <span className="asterik">*</span>
          </label>
          <Form.Item
            // name="name"
            // initialValue={participantData.name}
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input
              className="form-control"
              onChange={(e: any) => setname(e.target.value)}
              value={name}
              placeholder="Please enter Name"
              readOnly={readOnly}
            />
            <label className="text-danger">{nameErr}</label>
          </Form.Item>
        </div>
        <div className="form-group">
          <label htmlFor="inputEmail4" className="form-label">
            Email
            <span className="asterik">*</span>
          </label>
          <Form.Item
            // name="email"
            rules={[
              {
                type: 'email',
                message: 'The email is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter E-mail!',
              },
            ]}
          >
            <Input
              onChange={(e: any) => setemail(e.target.value)}
              placeholder="Please enter E-mail!"
              value={email}
              readOnly={readOnly}
            />
            <label className="text-danger">{emailErr}</label>
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Phone
            </label>
            <Form.Item>
              <Input
                onChange={(e: any) => setphone(e.target.value)}
                placeholder="Enter phone number"
                value={phone}
                maxLength={15}
                readOnly={readOnly}
              />
              <label className="text-danger">{phoneErr}</label>
            </Form.Item>
          </div>
          {/* <div className="col-md-6 reminder-wrapper">
          <CheckBox
            checkboxClass="survey-checkbox"
            onClick={() => {
              setchk(!chk)
            }}
          >
            <span className="reminder-text">Send Reminders ?</span>
            <img src={Doubt} alt="question" className="reminder-img" />
          </CheckBox>
        </div> */}
        </div>
        <div className="form-group">
          <label htmlFor="inputRater" className="form-label">
            Select Raters
          </label>
          <Form.Item
            rules={[{ required: true, message: 'Please Select Raters' }]}
          >
            <Select
              // onChange={(e: any) => getRaters(e)}
              value={selected_raters}
              onChange={(e: any) => setselectedRaters(e)}
              placeholder="Select Project"
              disabled={readOnly}
            >
              {raterList.length > 0 &&
                raterList.map((category: any) =>
                  category.title != 'Self' ? (
                    <Option key={category._id} value={category._id}>
                      {category.title}
                    </Option>
                  ) : null,
                )}
            </Select>
          </Form.Item>
        </div>
        {/* <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Employee
              <span className="asterik">*</span>
            </label>
            <Form.Item
              rules={[{ required: true, message: 'Please Select Employee' }]}
            >
              <Select
                value={employeeId}
                onChange={(e: any) => setEmployeeId(e)}
                placeholder="Select Project"
              >
                {employeeList &&
                  employeeList.map((emp: any) => (
                    <Option value={emp._id} key={emp._id}>
                      {emp.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div> */}
        {/* <div className="form-row">
          <div className="col-md-12 reminder-wrapper">
            <CheckBox
              checkboxClass="survey-checkbox"
              onClick={() => {
                setchk(!chk)
              }}
            >
              <span className="reminder-text">Send Reminders ?</span>
              <img src={Doubt} alt="question" className="reminder-img" />
            </CheckBox>
          </div>
        </div> */}
        <div className="survey-note-wrapper">
          <span className="survey-note-title">Please Note</span>
          <span className="survey-note-desc">
            User will receive an invite email to join to the particular Company
          </span>
        </div>
      </div>
      {readOnly === false && (
        <div className="button-group">
          <Button
            className="cancelButton"
            onClick={() => setParticipantDrawerVisible(false)}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="saveButton">
            Save
          </Button>
        </div>
      )}
    </Form>
  )
}
