/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { ProjectFilterBar } from './components/proejctFilterbar'
import './style/project.scss'
import { ProjectGrid } from '../projects/projectGrid'
import { ProjectListView } from '../projects/projectList'
import { logout, filterMyProjects, getMyProjects } from '../../../../api/API'
import Image from '../../../../components/Image'
import Button from '../../../../components/Button'
import Label from '../../../../components/Label'
import addCompany from '../../../../assets/images/addCompany.svg'
import { AddCompany } from '../addCompany'
import {
  COMPANY,
  COMPANY_SUB_TITLE,
  CREATE_NEW,
  DO_NOT_HAVE_PROJECT,
  ADD_NEW_COMPANY,
} from '../../../../constants'

interface Props {
  company: any
  deleteProject: any
  changeMode: any
  setProjectDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
}
export const ProjectList: React.FC<Props> = ({
  company,
  deleteProject,
  changeMode,
  setProjectDrawerVisible,
}): JSX.Element => {
  const history = useHistory()
  // const location = useLocation()
  const params = useParams<any>()
  const [data, setData] = useState(company)
  const [list, setList] = useState<any>([])
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [viewType, setviewType] = useState('grid')
  const [tempState, setTempState] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // setList(company.projects)
    getProjects()
  }, [currentPage, company])

  const getProjects = (): void => {
    setLoading(true)
    setTempState(false)
    const options = {
      page_no: currentPage,
      company: company._id,
    }
    getMyProjects(options).then((res: any) => {
      setList(res.data.data.docs)
      setTotalPages(res.data.data.totalPages)
      setLoading(false)
      setTempState(true)
    })
  }

  const changeViewType = (type: string): any => {
    if (type === 'grid') {
      setviewType('grid')
    } else {
      setviewType('list')
    }
  }

  const filterProject = (key: string, value: any): void => {
    const existingQury = filterQuery
    if (key === 'name' && value.target.value !== '') {
      existingQury[key] = value.target.value
    }
    if (key === 'mode' && value !== 'all') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }

    if (key === 'company') {
      existingQury[key] = value
    }

    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }

    if (key === 'date_added' && value === null) {
      getProjects()
      return
    }

    if (key === 'name' && value.target.value === '') {
      getProjects()
      return
    }

    if (key === 'mode' && value === 'all') {
      getProjects()
      return
    }

    setFilterQuery(existingQury)
    setTempState(false)
    filterMyProjects(company._id, existingQury).then((res: any): any => {
      setList(res.data.data)
      setTempState(true)
    })
    // .catch((error: any) => {
    //   if (error.response.status === 401) {
    //     logout()
    //     history.push('/login')
    //   }
    // })
  }

  const resetFilter = (): void => {
    getProjects()
    setFilterQuery({})
    // setTempState(true)
  }

  return (
    <div className="company-project-list">
      <ProjectFilterBar
        changeViewType={changeViewType}
        filterProject={filterProject}
        resetFilter={resetFilter}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <hr />
      {list.length === 0 && (
        // <p className="text-center h-100">Projects Not Available</p>
        <div className="company-main">
          <div className="company-main-section">
            <Image
              src={addCompany}
              alt="Add Company"
              style={{ marginBottom: '20px', maxWidth: '100px' }}
            />
            <Label
              title={DO_NOT_HAVE_PROJECT}
              style={{
                marginBottom: '20px',
                fontSize: '1rem',
                fontFamily: 'Calibri',
                color: '#b6becc',
                textAlign: 'center',
              }}
            />
            <Button
              onClick={() => setProjectDrawerVisible(true)}
              btnClass="create-project-btn"
            >
              <span className="material-icons">add</span>
              <span className="create-new-company">{CREATE_NEW}</span>
            </Button>
          </div>
        </div>
      )}
      {loading && <Spin className="spinner-code" />}
      {viewType === 'grid' && tempState && (
        <ProjectGrid
          company={data}
          list={list}
          deleteProject={deleteProject}
          changeMode={changeMode}
        />
      )}
      {viewType === 'list' && tempState && (
        <ProjectListView
          company={data}
          list={list}
          deleteProject={deleteProject}
          changeMode={changeMode}
        />
      )}
    </div>
  )
}
