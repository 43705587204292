import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'
import './style/details.scss'
import Stats from '../Stats/Stats'
import { CompanyDetailForm } from './components/companyForm'

interface Props {
  company: any
}
export const Details: React.FC<Props> = ({ company }): JSX.Element => {
  const [data, setData] = useState(company)
  useEffect(() => {
    setData(company)
  })
  return (
    <div className="company-details-wrapper">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
        <Stats company={data} />
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
        {data && <CompanyDetailForm company={data} />}
      </div>
    </div>
  )
}
