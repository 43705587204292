import React from 'react'
import { Tabs } from 'antd'
import { Detail } from './tabs/detail'
import { Construct } from './tabs/construct'

const { TabPane } = Tabs

interface Prop {
  addNewSlice: any
  focusAreaVisible: any
  selectedFocusarea: string
}

export const FocusAreaForm: React.FC<Prop> = ({
  addNewSlice,
  selectedFocusarea,
  focusAreaVisible,
}): JSX.Element => {
  return (
    <div>
      <Tabs className="p-3" defaultActiveKey="1">
        <TabPane tab="Detail" key="1">
          <Detail
            addNewSlice={addNewSlice}
            selectedFocusarea={selectedFocusarea}
            focusAreaVisible={focusAreaVisible}
          />
        </TabPane>
        {selectedFocusarea !== '' && (
          <TabPane tab="Construct" key="2">
            <Construct
              addNewSlice={addNewSlice}
              selectedFocusarea={selectedFocusarea}
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  )
}
