import { throws } from 'assert'
import React from 'react'
import { Link } from 'react-router-dom'
import { Table, DropdownButton, Dropdown, Button } from 'react-bootstrap'
import './styles/addedRaters.scss'
import { list } from './AddedRatersComponents/list'
import arrowDown from '../../../../../../../../assets/images/arrow_down.svg'
import { ListItem } from './AddedRatersComponents/listItem'

const Raters = (): JSX.Element => {
  return (
    <div className="added-raters">
      <div className="raters-inner-2">
        <div className="col-md-4">
          <span className="m-text-head">Name</span>
        </div>
        <div className="col-md-3">
          <span className="m-text-head">Emp ID</span>
        </div>
        <div className="col-md-2">
          <span className="m-text-head">Surveys</span>
        </div>
        <div className="col-md-3">
          <span className="m-text-head">Designation</span>
        </div>
      </div>
      <div className="raters-list-middle-wrapper">
        {list.map((item, index) => {
          return (
            <ListItem
              id={item.id}
              empName={item.empName}
              empId={item.empId}
              surveys={item.surveys}
              designation={item.designation}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Raters
