/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { Input, Select, DatePicker, Button, Col, Row, Drawer } from 'antd'
import { PicCenterOutlined, CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import { getCompanyList } from '../../api/API'
import listTextIcon from '../../assets/images/list-text.svg'
import gridTextIcon from '../../assets/images/tile.svg'
import MoreImg from '../../assets/images/more.svg'
import ResetImg from '../../assets/images/reset.svg'
import GoArrow from '../../assets/images/goArrow.svg'
import rightArrow from '../../assets/images/rightArrow.svg'
import rightDoubleArrow from '../../assets/images/right_double_arrow.svg'
import leftArrow from '../../assets/images/leftArrow.svg'
import leftDoubleArrow from '../../assets/images/left_double_arrow.svg'
import advanceFilter from '../../assets/images/Icon material-filter-list.svg'

const { Option } = Select
interface Props {
  changeViewType: any
  filterCompany?: any
  resetFilter?: any
  setCurrentPage?: any
  currentPage?: any
  totalPages?: any
}

export const CompanyFilterBar: React.FC<Props> = ({
  changeViewType,
  filterCompany,
  resetFilter,
  setCurrentPage,
  currentPage,
  totalPages,
}) => {
  // const dateFormat = 'YYYY/MM/DD'
  const dateFormatList = ['DD-MM-YYYY', 'DD/MM/YY']
  const [companyList, setCompanyList] = useState([])
  const [customPage, setCustomPage] = useState<any>(1)
  const [isRemindVisible, setIsRemindVisible] = useState<boolean>(false)

  useEffect(() => {
    getCompanyList().then((res: any): any => {
      const response = res.data
      if (res.status !== 200) {
        console.log('something is not right')
      } else {
        setCompanyList(response.data)
      }
    })
  }, [])

  const handleNumberInput = (e: any): void => {
    const valueTemp = e.target.value
    if (/[0-9]/.test(valueTemp)) {
      setCustomPage(valueTemp <= totalPages ? valueTemp : 1)
    } else {
      setCustomPage('')
    }
  }

  const [visible, setVisible] = useState(false)
  const showDrawer = (): any => {
    setVisible(true)
    setIsRemindVisible(true)
  }
  const onClose = (): any => {
    setVisible(false)
  }
  return (
    <>
      <Row
        gutter={[12, 12]}
        justify="space-between"
        className="company-filter-bar mt-3 justify-content-xxl-centers "
      >
        <Col
          xs={6.5}
          lg={1.5}
          md={1.5}
          xl={1.5}
          className="border-right-dotted grid_list_button"
        >
          <Button
            // id="grid_btn"
            icon={<img src={gridTextIcon} alt="grid" />}
            onClick={() => changeViewType('grid')}
            style={{ marginRight: '4px' }}
            className="border-0 grid_btn"
          />
          <Button
            id="list_btn"
            icon={<img src={listTextIcon} alt="list" />}
            onClick={() => changeViewType('list')}
          />
        </Col>
        <Col xs={13} sm={10} md={11} lg={8} xl={4} className="search_box">
          <div className="position-relative">
            <span className="floating-label-search font-smaller text-muted">
              Search
            </span>
            <Input
              placeholder="Search by Name, ID"
              id="textfield1"
              onChange={(e: any) => {
                filterCompany('name', e)
              }}
            />
          </div>
        </Col>
        {/* <Col md={5} lg={3} xl={3} className="position-relative desk-company">
          <span className="floating-label-company font-smaller text-muted">
            Company
          </span>
          <Select
            defaultValue=""
            style={{ width: '100%' }}
            onSelect={(e: any) => filterCompany('company', e)}
          >
            <Option value="">Select</Option>
            {companyList.length > 0 &&
              companyList.map((data: any, index) => (
                // eslint-disable-next-line no-underscore-dangle
                <Option value={data._id} key={data._id}>
                  {data.name}
                </Option>
              ))}
          </Select>
        </Col> */}
        <Col md={8} lg={5} xl={3} className="position-relative desk-mode">
          <span className="floating-label-mode font-smaller text-muted">
            Mode
          </span>
          <Select
            defaultValue="active"
            style={{ width: '100%' }}
            onSelect={(e: any) => filterCompany('mode', e)}
          >
            <Option value="active">Active</Option>
            <Option value="deactive">Deactive</Option>
          </Select>
        </Col>
        <Col md={8} lg={6} xl={3} className="position-relative desk-date">
          <span className="floating-label-date font-smaller text-muted">
            By Date
          </span>
          <DatePicker
            // defaultValue={moment('01/01/2015', dateFormatList[0])}
            format={dateFormatList}
            // onChange={(e: any) => filterCompany('createdAt', e)}
            onChange={(date, dateString) => filterCompany('date_added', date)}
            style={{ width: '100%' }}
          />
        </Col>
        <span className="border-right-dotted" />
        {/* <Col
          lg={4}
          xl={3}
          className="position-relative desk-date advance-filter"
        >
          <span className="desk_advane_filter_btn">
            <img
              src={advanceFilter}
              alt="advance filter"
              className="page-label w-15 adv_icon"
            />
            Advance Filter
          </span>
        </Col> */}
        <Col
          lg={1}
          xl={1}
          className="position-relative desk-reset-filter p-0 align-self-center"
        >
          <Button
            style={{
              padding: '8px 8px',
              backgroundColor: '#F6F9FB',
              height: '40px',
            }}
            className="ms-xl-1"
            onClick={() => {
              resetFilter()
            }}
          >
            <img src={ResetImg} alt="reset" className="w-75" />
          </Button>
        </Col>
        {/* <Col lg={1} xl={1} className="position-relative desk-more-btn p-0">
          <Button
            className="ms-2"
            style={{
              padding: '8px 8px',
              backgroundColor: '#F6F9FB',
              height: '40px',
            }}
          >
            <img src={MoreImg} alt="menu" className="w-75" />
          </Button>
        </Col> */}

        {/* Desktop Data Part over */}
        {/* --------------------------- */}
        {/* Mobile Data Part Start */}
        <Col xs={3} sm={10} md={12} lg={8} className="filter_button">
          <Button id="filter_btn" onClick={showDrawer}>
            Filter
          </Button>
        </Col>
        <Drawer
          title="Filter"
          placement="right"
          height={292}
          getContainer={false}
          onClose={onClose}
          visible={isRemindVisible}
          closable={false}
          className="mobile-drawer"
          extra={
            <CloseOutlined
              style={{ float: 'right' }}
              onClick={() => setIsRemindVisible(false)}
            />
          }
        >
          <Row
            justify="space-between"
            className="justify-content-xs-around justify-content-sm-around pt-3"
          >
            {/* <Col
              xs={11}
              sm={12}
              md={8}
              lg={6}
              xl={3}
              className="position-relative"
            >
              <span className="floating-label-company font-smaller text-muted">
                Company
              </span>
              <Select
                defaultValue=""
                style={{ width: '100%' }}
                onSelect={(e: any) => filterCompany('company', e)}
              >
                <Option value="">Select</Option>
                {companyList.length > 0 &&
                  companyList.map((data: any, index) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <Option value={data._id} key={data._id}>
                      {data.name}
                    </Option>
                  ))}
              </Select>
            </Col> */}
            <Col
              xs={11}
              sm={11}
              md={6}
              lg={6}
              xl={3}
              className="position-relative"
            >
              <span className="floating-label-mode font-smaller text-muted">
                Mode
              </span>
              <Select
                defaultValue="active"
                style={{ width: '100%' }}
                onSelect={(e: any) => filterCompany('mode', e)}
              >
                <Option value="active">Active</Option>
                <Option value="deactive">Deactive</Option>
              </Select>
            </Col>
            <Col
              xs={11}
              sm={11}
              md={6}
              className="position-relative border-right-dotted date-input"
            >
              <span className="floating-label-date font-smaller text-muted">
                By Date
              </span>
              {/* <DatePicker
                format={dateFormat}
                onChange={(e) => filterCompany('created_at', e)}
              /> */}
              <DatePicker
                defaultValue={moment('01/01/2015', dateFormatList[0])}
                format={dateFormatList}
                onChange={(date, dateString) =>
                  filterCompany(
                    'created_at',
                    moment(dateString, dateFormatList[0]),
                  )
                }
                style={{ width: '93%' }}
              />
              {/* <input
                type="date"
                className="form-control"
                style={{
                  padding: '8px 6px',
                  height: '40px',
                  color: '#000',
                }}
                onChange={(e) => filterCompany('createdAt', e)}
              />  */}
            </Col>
            {/* <Col xs={6} sm={24} md={12} id="reset_btn"> */}
            <Col xs={24} sm={2} md={12}>
              <Button
                className="ms-2 mt-3"
                id="reset_btn"
                style={{
                  padding: '8px 6px',
                  backgroundColor: '#F6F9FB',
                  height: '40px',
                }}
                onClick={() => {
                  resetFilter()
                }}
              >
                <img src={ResetImg} alt="reset" className="w-75" />
              </Button>
              {/* <Button
                className="ms-2 mt-3"
                id="more_btn"
                style={{
                  padding: '8px 6px',
                  backgroundColor: '#F6F9FB',
                  height: '40px',
                }}
              >
                <img src={MoreImg} alt="menu" className="w-75" />
              </Button> */}
            </Col>
            <span id="dash-border" />
            {/* <Col
              xs={24}
              sm={24}
              md={12}
              className="position-relative text-center"
            >
              <span className="advane_filter_btn">
                <img
                  src={advanceFilter}
                  alt="advance filter"
                  className="adv_filter_img"
                />
                Advance Filter
              </span>
            </Col> */}
            <Col
              xs={24}
              sm={24}
              md={12}
              className="position-relative text-center"
            >
              <Button
                className="apply_btn float-end mt-3 me-2"
                onClick={() => setIsRemindVisible(false)}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Drawer>
        <Col xs={24} sm={24} md={13} lg={24} xl={8} xxl={8}>
          <div className="d-flex justify-content-end">
            <Button
              icon={<img src={leftArrow} className="w-25" alt="First" />}
              onClick={() => setCurrentPage(1)}
              className="rounded-start rounded-end-0"
            />
            <Button
              icon={
                <img src={leftDoubleArrow} className="w-50" alt="Previous" />
              }
              onClick={() =>
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
              }
              className="rounded-0"
            />
            <Button
              className="page-label rounded-0"
              style={{ paddingLeft: '8px', paddingRight: '8px' }}
            >
              Page {currentPage} of {totalPages}{' '}
            </Button>
            <Button
              icon={<img src={rightDoubleArrow} className="w-50" alt="Last" />}
              onClick={() =>
                setCurrentPage(currentPage < totalPages ? currentPage + 1 : 1)
              }
              className="rounded-0"
            />
            <Button
              icon={<img src={rightArrow} className="w-25" alt="Next" />}
              onClick={() => setCurrentPage(totalPages)}
              className="rounded-end"
            />
            <Input
              type="text"
              placeholder="Go to"
              value={customPage}
              onChange={(e) => handleNumberInput(e)}
              className="goto-field ms-1 ms-1"
              // onChange={(e: any) =>
              //   /^-?\d*(\.\d*)?$/.test(e.target.value)
              //     ? setCustomPage(
              //         e.target.value <= totalPages ? e.target.value : 1,
              //       )
              //     : ''
              // }
            />
            <Button
              icon={<img src={GoArrow} alt="Go" className="w-50" />}
              className="btn-theme goto-btn ms-1"
              onClick={() => setCurrentPage(customPage)}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}
