import React from 'react'
import { Tabs } from 'antd'
import { Details } from './tabs/details'
import Raters from './tabs/addedRaters'
import { SurveyDetails } from './tabs/surveyDetails'
import './styles/tabPanel.scss'

const { TabPane } = Tabs

export const TabPanel: React.FC = (): JSX.Element => {
  return (
    <div className="tab-drawer-survey">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Detail" key="1">
          <Details />
        </TabPane>
        <TabPane tab="Projects" key="2">
          <Raters />
        </TabPane>
        <TabPane tab="User" key="3">
          <SurveyDetails />
        </TabPane>
      </Tabs>
    </div>
  )
}
