/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Checkbox } from 'antd'
import './style/checkbox.scss'
// import { CONTACT_US } from '../../constants/index'

interface Props {
  children: React.ReactNode
  onClick?: any
  checkboxClass?: string
  checked?: boolean
  // disabled?: boolean
  // width?: number
  // height?: number
}

export const CheckboxComponent: React.FC<Props> = ({
  children,
  onClick,
  checkboxClass,
  checked,
  // disabled,
  // width,
  // height,
}): JSX.Element => {
  return (
    <Checkbox
      className={`theme-checkbox ${checkboxClass}`}
      onChange={(e) => onClick(e)}
      defaultChecked={checked}
    >
      {children}
    </Checkbox>
  )
}

CheckboxComponent.defaultProps = {
  // disabled: false,
  // width: 120,
  // height: 40,
  checkboxClass: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // onClick: () => {},
}
