/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import './styles/raterStats.scss'
import { PieChart, Pie, Cell } from 'recharts'
import { Select } from 'antd'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'
import arrowDown from '../../../../assets/images/arrow_down.svg'
import {
  getProjectList,
  RatersChartApi,
  getAllRaters,
} from '../../../../api/API'

const { Option } = Select

interface Props {
  controller: any
}

export const RaterChart: React.FC<Props> = ({ controller }) => {
  const [projectList, setProjectList] = useState([])
  const [raterlist, setRaterList] = useState<any>([])
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(100)
  const [projectId, setProjectId] = useState('')
  const [defaultProject, setDefaultProject] = useState('')
  const [defaultRater, setDefaultRater] = useState('')

  // get project list
  const getProjectListData = (): any => {
    getProjectList(controller).then((res: any): any => {
      setProjectId(res.data.data[0]._id)
      // setDefaultProject(res.data.data[0].name)
      setProjectList(res.data.data)
      // eslint-disable-next-line no-console
      getRaterListData(res.data.data[0]._id)
    })
  }
  useEffect(() => {
    getProjectListData()
  }, [])

  // get raters category chart list
  const getRaterListData = (id: any): any => {
    getAllRaters(id).then((res: any) => {
      setRaterList(res.data.data)
      setProjectId(id)
      // setDefaultRater(res.data.data[0].title)
      console.log(id)
      getRaterChartData(res.data.data[0]._id, id)
    })
  }

  // get Raters Chart Data
  const getRaterChartData = (raterscategory: any, projectid?: any): any => {
    const obj = {
      raterscategory,
    }
    console.log(projectId)
    RatersChartApi(projectId || projectid, obj, controller).then(
      (res: any): any => {
        // setChartData(res.data.data)
        setCompleteSurvey(res.data.data.completedSurveyStatus)
        setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
      },
    )
  }
  const data = [
    {
      name: 'Not Completed Survey',
      survey: 100 - completeSurvey,
      color: '#FED68B',
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: '#FCB737',
    },
  ]
  const ndata = data === undefined ? [] : data
  return (
    <div className="rater-pie-wrapper">
      <div className="rater-statsbody">
        <div className="rater-dropdown-wrapper-left w-100 d-flex justify-content-between">
          {/* <Dropdown>
              <Dropdown.Toggle
                className="ms-xl-3 p-1 border-1 rater-allproject-dropdown"
                id="dropdown-basic"
              >
                <span className="rater-project-text3">Stakeholders</span>
                <img src={arrowDown} alt="arrow" className="rater-arrow_down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          {/* raters category list */}
          <Select
            placeholder={defaultRater || 'Select Raters'}
            // placeholder="Raters Category"
            className="ms-xl-3 p-1 raterlistDropdown rater-chart-dropdown"
            onChange={(e) => getRaterChartData(e)}
          >
            <Option value="">Select Raters</Option>
            {raterlist.length > 0 &&
              raterlist.map((category: any) =>
                // eslint-disable-next-line eqeqeq
                category.title != 'Self' ? (
                  <Option key={category._id} value={category._id}>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          height: '10px',
                          width: '10px',
                          marginLeft: '10px',
                          backgroundColor: category.color,
                        }}
                      />
                      <div className="ms-2">{category.title}</div>
                    </div>
                  </Option>
                ) : null,
              )}
          </Select>
          {/* </div>
            <div className="rater-dropdown-wrapper me-xl-3"> */}
          {/* <Dropdown>
              <Dropdown.Toggle
                className="p-1 border-1 rater-allproject-dropdown"
                id="dropdown-basic"
              >
                <span className="rater-project-text3">All Projects</span>
                <img src={arrowDown} alt="arrow" className="rater-arrow_down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          {/* project list  */}
          <Select
            // placeholder="Project List"
            className="p-1 me-xl-3 me-md-3 me-lg-3 projectlistDropdown rater-project-dropdown"
            id="dropdown-basic"
            placeholder={defaultProject || 'Select Project'}
            onChange={(e) => getRaterListData(e)}
          >
            <Option value="">Select Project</Option>
            {projectList.length > 0 &&
              projectList.map((item: any) => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </div>
        <div className="rater-pie d-flex">
          <PieChart width={308} height={308} className="">
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={110}
              innerRadius={55}
              fill="#8884d8"
            >
              {ndata.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <span className="rater-percent">{completeSurvey}%</span>
        </div>
      </div>
      <div className="d-flex justify-content-around w-75 ms-5 chart-data">
        <div className="d-flex w-50">
          <div className="rater-dot1 me-3" />
          <span className="rater-label-text">Not Completed Survey</span>
        </div>
        <div className="d-flex">
          <div className="rater-dot2 me-3" />
          <span className="rater-label-text">Completed Survey</span>
        </div>
      </div>
    </div>
  )
}
// RaterChart.defaultProps = {
//   percentage: 75,
//   data: [
//     { name: 'Completed Survey', survey: 25, color: '#E2B8EC' },
//     { name: 'Not Completed Survey', survey: 75, color: '#C471D8' },
//   ],
// }
