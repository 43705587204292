/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  ColumnHeightOutlined,
  ToTopOutlined,
  CopyOutlined,
  DeleteOutlined,
  CloseOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Popconfirm } from 'antd'

interface Props {
  handleDuplicate: any
  handleAddAbove: any
  handleAddBelow: any
  removeQuestion: any
  addNewQuestion: any
  addPageBreak: any
  questionIndex: any
  blockIndex: any
  questionType?: any
}
export const QuestionActions: React.FC<Props> = ({
  handleDuplicate,
  handleAddAbove,
  handleAddBelow,
  removeQuestion,
  addNewQuestion,
  addPageBreak,
  questionIndex,
  blockIndex,
  questionType = 'other',
}): JSX.Element => {
  return (
    <div className="action-icon">
      <div className="action-dropdown">
        <DropdownButton className="comman-butt" title={<MoreOutlined />}>
          <Dropdown.Item
            onClick={() => removeQuestion(blockIndex, questionIndex)}
          >
            <DeleteOutlined className="me-2" /> Delete
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleDuplicate(blockIndex, questionIndex)}
          >
            <CopyOutlined className="me-2" /> Duplicate
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => addPageBreak('page-break', questionIndex)}
          >
            <ColumnHeightOutlined className="me-2" /> Add Page Break
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleAddAbove(blockIndex, questionIndex)}
          >
            <ToTopOutlined className="me-2" /> Add question above
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleAddBelow(blockIndex, questionIndex)}
          >
            <ToTopOutlined rotate={180} className="me-2" /> Add question below
          </Dropdown.Item>
        </DropdownButton>
      </div>
      {(questionType !== 'html-editor' || questionType === 'other') &&
        questionIndex !== 0 && (
          <div className="action-remove">
            <Popconfirm
              title="Are you sure to remove this question?"
              onConfirm={() => removeQuestion(blockIndex, questionIndex)}
              okText="Yes"
              cancelText="No"
            >
              <CloseOutlined className="cursor-pointer" />
            </Popconfirm>
          </div>
        )}
    </div>
  )
}
