import './chart.css'
import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { useParams } from 'react-router-dom'
import { EmployeeChartApi } from '../../../../../api/API'

const EmployeeChart = (): JSX.Element => {
  // const [chartData, setChartData] = useState({})
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(0)
  const params = useParams<any>()
  const { id } = params // get project id from url
  // get chart Values
  const getEmpChartData = (): any => {
    EmployeeChartApi(id).then((res: any): any => {
      // setChartData(res.data.data)
      setCompleteSurvey(res.data.data.completedSurveyStatus)
      setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    })
  }

  useEffect(() => {
    getEmpChartData()
  }, [])
  const data = [
    {
      name: 'Not Completed Survey',
      // survey: notCompleteSurvey,
      survey: 100 - completeSurvey,
      color: '#A6CEE3',
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: '#1F78B4',
    },
  ]
  return (
    <div className="mt-xl-5">
      <div className="p-2">
        <div className="text-center mt-lg-2 mt-xl-0">
          <h5>Employees participation</h5>
        </div>
        <div className="statsbody d-flex justify-content-around mt-0">
          <div className="pie mt-xl-4 mt-lg-4">
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                dataKey="survey"
                outerRadius={150}
                innerRadius={100}
                fill="#8884d8"
              >
                {data.map((entry, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
            <p className="percent-e">{completeSurvey}%</p>
          </div>
        </div>
        <div className="d-flex justify-content-around">
          {data.map((entry, index) => (
            <div className="d-flex" key={entry.name}>
              <div
                className="dot1 me-3"
                style={{ backgroundColor: entry.color }}
              />
              <p>{entry.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EmployeeChart
