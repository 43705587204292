/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import {
  Input,
  Select,
  DatePicker,
  Button,
  Pagination,
  Col,
  Row,
  Drawer,
} from 'antd'
import { PicCenterOutlined, CloseOutlined } from '@ant-design/icons'
import { getCompanyList } from '../../../../../api/API'
import listTextIcon from '../../../../../assets/images/list-text.svg'
import gridTextIcon from '../../../../../assets/images/tile.svg'
import MoreImg from '../../../../../assets/images/more.svg'
import ResetImg from '../../../../../assets/images/reset.svg'
import GoArrow from '../../../../../assets/images/goArrow.svg'
import rightArrow from '../../../../../assets/images/rightArrow.svg'
import rightDoubleArrow from '../../../../../assets/images/right_double_arrow.svg'
import leftArrow from '../../../../../assets/images/leftArrow.svg'
import leftDoubleArrow from '../../../../../assets/images/left_double_arrow.svg'

const { Option } = Select
interface Props {
  changeViewType: any
  filterProject: any
  resetFilter: any
  setCurrentPage?: any
  currentPage?: any
  totalPages?: any
}

export const ProjectFilterBar: React.FC<Props> = ({
  changeViewType,
  filterProject,
  resetFilter,
  setCurrentPage,
  currentPage,
  totalPages,
}) => {
  const dateFormat = 'YYYY/MM/DD'
  const [companyList, setCompanyList] = useState([])
  const [customPage, setCustomPage] = useState<any>(1)
  const [isRemindVisible, setIsRemindVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    getCompanyList().then((res: any): any => {
      const response = res.data
      if (res.status !== 200) {
        console.log('something is not right')
      } else {
        setCompanyList(response.data)
      }
    })
  }, [])
  const showDrawer = (): any => {
    setVisible(true)
    setIsRemindVisible(true)
  }
  const onClose = (): any => {
    setVisible(false)
  }

  const handleNumberInput = (e: any): void => {
    const valueTemp = e.target.value
    if (/[0-9]/.test(valueTemp)) {
      setCustomPage(valueTemp <= totalPages ? valueTemp : 1)
    } else {
      setCustomPage('')
    }
  }

  return (
    <>
      <Row
        gutter={[16, 16]}
        className="user-filter-bar mt-3"
        justify="space-between"
      >
        <Col
          xs={6.5}
          md={1.5}
          lg={1.5}
          xl={1.5}
          className="border-right-dotted"
        >
          <Button
            icon={<img src={gridTextIcon} alt="grid" />}
            onClick={() => changeViewType('grid')}
            id="grid_btn"
          />
          <Button
            icon={<img src={listTextIcon} alt="list" />}
            className="ms-1 mr-1"
            onClick={() => changeViewType('list')}
            id="list_btn"
          />
        </Col>
        <Col xs={13} sm={10} md={12} lg={8} xl={7}>
          <div className="position-relative">
            <span className="floating-label-search font-smaller text-muted">
              Search
            </span>
            <Input
              placeholder="Search by Name, ID"
              id="textfield1"
              onChange={(e: any) => {
                filterProject('name', e)
              }}
            />
          </div>
        </Col>
        {/* <Col
          xs={24}
          sm={24}
          md={5}
          lg={3}
          xl={3}
          className="position-relative desk-company"
        >
          <span className="floating-label-company font-smaller text-muted">
            Company
          </span>
          <Select
            defaultValue=""
            style={{ width: '100%' }}
            onSelect={(e: any) => filterProject('company', e)}
          >
            <Option value="">Select</Option>
            {companyList.length > 0 &&
              companyList.map((data: any, index) => (
                // eslint-disable-next-line no-underscore-dangle
                <Option value={data._id} key={data._id}>
                  {data.name}
                </Option>
              ))}
          </Select>
        </Col> */}
        <Col
          xs={24}
          sm={24}
          md={7}
          lg={5}
          xl={3}
          className="position-relative desk-mode"
        >
          <span className="floating-label-mode font-smaller text-muted">
            Mode
          </span>
          <Select
            defaultValue="active"
            style={{ width: '100%' }}
            onSelect={(e: any) => filterProject('mode', e)}
          >
            <Option value="all">All</Option>
            <Option value="active">Active</Option>
            <Option value="deactive">Deactive</Option>
          </Select>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={6}
          xl={3}
          className="position-relative border-right-dotted desk-date"
        >
          <span className="floating-label-date font-smaller text-muted">
            By Date
          </span>
          <DatePicker
            format={dateFormat}
            className="w-100"
            onChange={(date, dateString) => filterProject('date_added', date)}
          />
        </Col>
        {/* <Col lg={4} xl={3} className="position-relative desk-advance-filter">
          <Button icon={<PicCenterOutlined />} className="page-label">
            Advace Filter
          </Button>
        </Col> */}
        <Col lg={1} xl={1} className="position-relative desk-reset-filter p-0">
          <Button
            className="ms-2"
            style={{
              padding: '8px 6px',
              backgroundColor: '#F6F9FB',
            }}
            onClick={() => {
              resetFilter()
            }}
          >
            <img src={ResetImg} alt="reset" className="w-75" />
          </Button>
        </Col>
        {/* <Col lg={1} xl={1} className="position-relative desk-more-btn p-0">
          <Button
            className="ms-2"
            style={{
              padding: '8px 6px',
              backgroundColor: '#F6F9FB',
            }}
          >
            <img src={MoreImg} alt="menu" className="w-75" />
          </Button> 
        </Col>  */}
        {/* Desktop Data Part over */}
        {/* --------------------------- */}
        {/* Mobile Data Part Start */}
        <Col xs={4} sm={10} md={12} lg={8} className="usertab_filter_button">
          <Button id="filter_btn" onClick={showDrawer}>
            Filter
          </Button>
        </Col>
        <Drawer
          title="Filter"
          placement="right"
          getContainer={false}
          onClose={onClose}
          visible={isRemindVisible}
          closable={false}
          extra={
            <CloseOutlined
              style={{ float: 'right' }}
              onClick={() => setIsRemindVisible(false)}
            />
          }
        >
          <Row justify="space-between" className="pt-3">
            {/* <Col
              xs={11}
              sm={12}
              md={8}
              lg={6}
              xl={3}
              className="position-relative"
            >
              <span className="floating-label-company font-smaller text-muted">
                Company
              </span>
              <Select
                defaultValue=""
                style={{ width: '100%' }}
                onSelect={(e: any) => filterProject('company', e)}
              >
                <Option value="">Select</Option>
                {companyList.length > 0 &&
                  companyList.map((data: any, index) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <Option value={data._id} key={data._id}>
                      {data.name}
                    </Option>
                  ))}
              </Select>
            </Col> */}
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              className="position-relative"
            >
              <span className="floating-label-mode font-smaller text-muted">
                Mode
              </span>
              <Select
                defaultValue="active"
                style={{ width: '100%' }}
                onSelect={(e: any) => filterProject('mode', e)}
              >
                <Option value="all">All</Option>
                <Option value="active">Active</Option>
                <Option value="deactive">Deactive</Option>
              </Select>
            </Col>
            <Col
              xs={11}
              sm={12}
              md={6}
              className="position-relative border-right-dotted date-input"
            >
              <span className="floating-label-date font-smaller text-muted">
                By Date
              </span>
              <DatePicker format={dateFormat} style={{ width: '93%' }} />
            </Col>
            <Col xs={12} sm={2} md={12}>
              <Button
                className="ms-2 mt-3"
                style={{
                  padding: '8px 6px',
                  backgroundColor: '#F6F9FB',
                }}
                onClick={() => {
                  resetFilter()
                }}
              >
                <img src={ResetImg} alt="reset" className="w-75" />
              </Button>
              {/* <Button
                className="ms-2 mt-3"
                style={{
                  padding: '8px 6px',
                  backgroundColor: '#F6F9FB',
                }}
              >
                <img src={MoreImg} alt="menu" className="w-75" />
              </Button> */}
            </Col>
            <span id="dash-border" />
            {/* <Col
              xs={24}
              sm={24}
              md={12}
              className="position-relative text-center"
            >
              <Button icon={<PicCenterOutlined />} className="page-label">
                Advace Filter
              </Button>
            </Col> */}
            <Col
              xs={24}
              sm={24}
              md={12}
              className="position-relative text-center"
            >
              <Button
                className="apply_btn float-end mt-3 me-2"
                onClick={() => setIsRemindVisible(false)}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Drawer>
        {/* ======= Drawer Over here ============ */}
        <Col
          xs={24}
          sm={24}
          md={14}
          // lg={{ offset: 14, span: 10 }}
          lg={10}
          xl={8}
          xxl={8}
          className="justify-content-lg-end"
        >
          <div className="d-flex justify-content-end">
            <Button
              icon={<img src={leftArrow} className="w-25" alt="First" />}
              onClick={() => setCurrentPage(1)}
            />
            <Button
              icon={
                <img src={leftDoubleArrow} className="w-50" alt="Previous" />
              }
              onClick={() =>
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
              }
            />
            <Button className="page-label">
              Page {currentPage} of {totalPages}{' '}
            </Button>
            <Button
              icon={<img src={rightDoubleArrow} className="w-50" alt="Last" />}
              onClick={() =>
                setCurrentPage(currentPage < totalPages ? currentPage + 1 : 1)
              }
            />
            <Button
              icon={<img src={rightArrow} className="w-25" alt="Next" />}
              onClick={() => setCurrentPage(totalPages)}
            />
            <Input
              type="text"
              placeholder="Go to"
              className="goto-field ms-1"
              value={customPage}
              onChange={(e) => handleNumberInput(e)}
              // onChange={(e: any) =>
              //   setCustomPage(e.target.value <= totalPages ? e.target.value : 1)
              // }
            />
            <Button
              icon={<img src={GoArrow} alt="Go" className="w-50" />}
              className="btn-theme goto-btn ms-1"
              onClick={() => setCurrentPage(customPage)}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}
