import React from 'react'
import { Form } from 'antd'

interface Props {
  children: React.ReactNode
  formItemClass?: string
}

export const FormItem: React.FC<Props> = ({
  children,
  formItemClass,
}): JSX.Element => {
  return <Form.Item className={formItemClass}>{children}</Form.Item>
}

FormItem.defaultProps = {
  formItemClass: '',
}
