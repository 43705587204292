/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactQuill, { Quill } from 'react-quill'
import moment from 'moment'
import {
  Alert,
  notification,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
} from 'antd'
import { addProject, getCompanyList } from '../../../api/API'
import TextArea from '../../../components/InputElements/TextArea'
// import Button from '../../../components/Button'
import './style/addproject.scss'

// const { Quill } = ReactQuill
const Font = Quill.import('attributors/class/font')
const fontList = [
  'Calibri Regular',
  'Calibri Bold',
  'Calibri Italic',
  'Calibri Bold Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
  'emoji',
  'math',
  'fangsong',
]
Font.whitelist = fontList.map((font) => getFontName(font))
Quill.register(Font, true)

let fontStyles = ''
fontList.forEach(function (font) {
  const fontName = getFontName(font)
  fontStyles +=
    `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {` +
    `content: '${font}';` +
    `font-family: '${font}', sans-serif;` +
    `}` +
    `.ql-font-${fontName}{` +
    ` font-family: '${font}', sans-serif;` +
    `}`
})

function getFontName(font: any): any {
  return font.toLowerCase().replace(/\s/g, '-')
}

const node = document.createElement('style')
node.innerHTML = fontStyles
document.body.appendChild(node)

const { Option } = Select

interface Props {
  setDrawerVisible?: any
  getListofProjects?: any
  cleardrawer?: any
}

export const AddProject: React.FC<Props> = ({
  setDrawerVisible,
  getListofProjects,
  cleardrawer,
}) => {
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [form] = Form.useForm()

  const [name, setName] = useState('')
  const [company, setCompany] = useState<any>('')
  const [contactPerson, setContactPerson] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [description, setDescription] = useState('')
  const [companyList, setCompanyList] = useState([])
  const [rerenderState, setRerenderState] = useState(Math.random())

  // Error States
  const [nameErr, setNameErr] = useState('')
  const [companyErr, setCompanyErr] = useState('')
  const [emailErr, setEmailErr] = useState('')
  const [startDateErr, setStartDateErr] = useState('')
  const [endDateErr, setEndDateErr] = useState('')

  useEffect(() => {
    getCompanyList().then((res: any): any => {
      const response = res.data
      if (res.status !== 200) {
        notification.error({
          message: 'something went wrong refresh pages',
        })
      } else {
        setCompanyList(response.data)
      }
    })
  }, [])

  useEffect(() => {
    setName('')
    setContactPerson('')
    setEmail('')
    setPhone('')
    setStartDate('')
    setEndDate('')
    setDescription('')
  }, [cleardrawer])

  const modules = {
    toolbar: [
      // [{ header: '1' }, { header: '2' }],
      [{ font: Font.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, 7] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  const resetform = (): void => {
    setName('Please Enter Project Name')
    setContactPerson('')
    setEmail('')
    setPhone('')
    setStartDate('')
    setEndDate('')
    setDescription('')
  }

  const handleAddProject = (): void => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    setNameErr('')
    setEndDate('')
    setCompanyErr('')
    setEmailErr('')
    setStartDateErr('')
    setEndDateErr('')
    let valid = true
    if (name == '') {
      setNameErr('Please Enter Name')
      valid = false
    }
    if (endDate == '') {
      setEndDate('Please Enter End Date')
      valid = false
    }
    if (!company) {
      setCompanyErr('Please Select Company')
      valid = false
    }
    if (email == '' || !email.match(emailRegex)) {
      setEmailErr('Please Enter Valid Email')
      valid = false
    }
    if (startDate == '') {
      setStartDateErr('Please Enter Start Date')
      valid = false
    }

    if (valid) {
      const data = {
        name,
        company,
        contact_person: contactPerson,
        email,
        phone,
        start_date: startDate,
        end_date: endDate,
        description,
      }

      addProject(data).then((res: any): any => {
        if (res.isAxiosError && res.response.status === 400) {
          notification.error({
            type: 'error',
            message: res.response.data.message,
          })
        } else {
          const response = res.data
          if (res.status !== 200) {
            notification.error({
              message: 'something went wrong refresh pages',
            })
          } else {
            // history.push('/project-detail')

            notification.success({
              message: 'Project successfully Added',
            })
            resetform()
            getListofProjects()
            setDrawerVisible(false)
          }
          setTimeout(() => {
            setRerenderState(Math.random())
          }, 100)

          setName('')
          setContactPerson('')
          setEmail('')
          setPhone('')
          setStartDate('')
          setEndDate('')
          setDescription('')
          setDrawerVisible(false)
          setRerenderState(Math.random())
        }
      })
    }
  }

  return (
    <div className="add-project">
      <div className="form-container">
        <Alert
          message={errorMsg}
          type="error"
          style={{ display: isError ? 'block' : 'none' }}
        />
        <Form onFinish={handleAddProject} key={rerenderState}>
          <div className="form-group">
            <label htmlFor="inputName4" className="form-label">
              Project Name
              <span className="asterik">*</span>
            </label>
            {/* <input
              type="name"
              className="form-control"
              id="inputName4"
              placeholder="Please Enter Project Name"
              onChange={(e: any) => setName(e.target.value)}
            /> */}
            <Form.Item
            // name="name"
            // rules={[{ required: true, message: 'Please Enter Project Name' }]}
            >
              <Input
                // defaultValue={name}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Enter Project Name"
              />
              <label className="text-danger">{nameErr}</label>
            </Form.Item>
          </div>
          <div className="form-row">
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px' }}
            >
              <label htmlFor="inputCompany" className="form-label">
                Select Company
                <span className="asterik">*</span>
              </label>
              <Form.Item
              // name="company"
              // rules={[{ required: true, message: 'Please Company' }]}
              >
                <Select
                  className="form-selec"
                  placeholder="Select Company"
                  onChange={(e: any) => setCompany(e)}
                  value={company || undefined}
                >
                  {/* <Option value="">Select </Option> */}
                  {companyList.map((data: any, index) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <Option value={data._id} key={data._id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
                <label className="text-danger">{companyErr}</label>
              </Form.Item>
            </div>
            <div
              className="form-group col col-md-6"
              style={{ paddingLeft: '7px' }}
            >
              <label htmlFor="inputEmail" className="form-label">
                Email
                <span className="asterik">*</span>
              </label>

              <Form.Item>
                <Input
                  onChange={(e: any) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter Email"
                />
                <label className="text-danger">{emailErr}</label>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px' }}
            >
              <label htmlFor="inputStartDate" className="form-label">
                Start Date
                <span className="asterik">*</span>
              </label>
              {/* <input
                type="date"
                className="form-control"
                id="inputStartDate"
                onChange={(e: any) => setStartDate(e.target.value)}
              /> */}
              <Form.Item>
                <DatePicker
                  className="w-100"
                  value={startDate !== '' ? moment(startDate) : null}
                  onChange={(date, dateString) => setStartDate(dateString)}
                />
                <label className="text-danger">{startDateErr}</label>
              </Form.Item>
            </div>
            <div
              className="form-group col col-md-6"
              style={{ paddingLeft: '7px' }}
            >
              <label htmlFor="inputEndDate" className="form-label">
                End Date
              </label>
              <span className="asterik">*</span>
              {/* <input
                type="date"
                className="form-control"
                id="inputEndDate"
                onChange={(e: any) => setEndDate(e.target.value)}
              /> */}
              <Form.Item>
                <DatePicker
                  className="w-100"
                  // value={endDate}
                  value={endDate !== '' ? moment(endDate) : null}
                  onChange={(date, dateString) => setEndDate(dateString)}
                />
                <label className="text-danger">{endDateErr}</label>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            {/* <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px' }}
            >
              <label htmlFor="inputPhoneNumber" className="form-label">
                Phone
              </label>
              <Form.Item
                name="mobile"
                rules={[
                  {
                    required: false,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Enter phone number',
                  },
                ]}
              >
                <Input
                  onChange={(e: any) => setPhone(e.target.value)}
                  value={phone}
                  placeholder="Enter phone number"
                />
              </Form.Item>
            </div> */}
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px' }}
            >
              <label htmlFor="inputContactPerson" className="form-label">
                Contact Person
              </label>
              <Form.Item>
                <Input
                  onChange={(e: any) => setContactPerson(e.target.value)}
                  value={contactPerson}
                  placeholder="Enter Contact Person Name"
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress1" className="form-label">
              Description
            </label>
            {/* <TextArea placeholder=" " onChange={setDescription} /> */}
            <ReactQuill
              theme="snow"
              formats={formats}
              modules={modules}
              className="inputBorder"
              // defaultValue={description || ''}
              value={description || ''}
              onChange={(e: any) => setDescription(e)}
            />
          </div>
          <div className="button-group">
            <Button
              className="cancelButton"
              onClick={() => setDrawerVisible(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="saveButton border-0"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
