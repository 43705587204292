// Project employee cart
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { Row, Col, Checkbox, Modal, Button } from 'antd'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import WPForm from '../../../../../assets/images/Icon awesome-wpforms.svg'
import UserCirlce from '../../../../../assets/images/Icon awesome-user-circle.svg'
import PopupConfirm from '../../../../../assets/images/Group 9366.svg'
import { changeEmployeeStatus, deleteEmployees } from '../../../../../api/API'

interface Props {
  // eslint-disable-next-line react/require-default-props
  getProjectsEmployee: any
  data: any
  getDetail: any
  getProjectDetailFn: any
}

// when click on card get data from api and fill in details tab
export const UserCard: React.FC<Props> = ({
  getProjectsEmployee,
  data,
  getDetail,
  getProjectDetailFn,
}) => {
  const params = useParams<any>()
  const [DeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [blockModalVisible, setblockModalVisible] = useState(false)
  const [adminModalVisible, setadminModalVisible] = useState(false)
  const [blockEmployeeId, setBlockEmployeeId] = useState('')
  const [deleteEmployeeId, setDeleteEmployeeId] = useState('')

  const showDeleteModal = (): any => {
    setDeleteModalVisible(true)
  }

  const changeMode = (id: any, status: boolean): void => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data = {
      status,
    }

    changeEmployeeStatus(id, data)
      .then((res: any): any => {
        getProjectsEmployee()
        getProjectDetailFn(params.id)
      })
      .catch((error: any) => {
        // getListofProjects()
      })
  }

  const deleteEmployee = (id: any): void => {
    deleteEmployees(id).then((res: any) => {
      getProjectsEmployee()
    })
  }
  return (
    <div
      className={
        data.status
          ? 'activate-card-bg cursor-pointer border p-2 user_card'
          : 'deactivate-card-bg cursor-pointer border p-2 user_card'
      }
    >
      <Row justify="space-between">
        <Col xs={19} md={12}>
          {/* <input type="checkbox" className="checkbox" /> */}
        </Col>
        <Col md={4} className="text-end usercard-dropdown float-end p-0">
          <DropdownButton
            title={<MoreOutlined />}
            className="more-outlined btn p-0"
          >
            <Dropdown.Item
              className="cursor-pointer"
              onClick={() => getDetail(data._id, 'false')}
            >
              <span className="pe-2">
                <EyeOutlined />
              </span>
              View
            </Dropdown.Item>
            <Dropdown.Item
              className="cursor-pointer"
              onClick={() => getDetail(data._id, 'true')}
            >
              <span className="pe-2">
                <EditOutlined />
              </span>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                showDeleteModal()
                setDeleteEmployeeId(data._id)
              }}
            >
              <span className="pe-2">
                <DeleteOutlined />
              </span>
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setblockModalVisible(true)
                setBlockEmployeeId(data._id)
              }}
            >
              <span className="pe-2">
                <MinusCircleOutlined />
                {/* style={{ color: 'red' }}  */}
              </span>
              {/* {data.status ? 'Block' : 'Unblock'} */}
              {data.status ? 'Deactive' : 'Active'}
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={showAdminModal}>
              <span className="pe-2">
                <UserOutlined />
              </span>
              Make Admin
            </Dropdown.Item> */}
          </DropdownButton>
          {/* Delete Modal  */}
          <Modal
            visible={DeleteModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
          >
            <div className="text-center">
              <img src={PopupConfirm} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">
                Are you sure you want to Delete?
              </p>
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="bg-theme-save-framwork-popup bg-transparent"
                  onClick={() => {
                    setDeleteModalVisible(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2"
                  onClick={() => {
                    deleteEmployee(deleteEmployeeId)
                    setDeleteModalVisible(false)
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
          </Modal>
          {/* Block Modal  */}
          <Modal
            visible={blockModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
          >
            <div className="text-center">
              <img src={PopupConfirm} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">
                Are you sure you want to {data.status ? 'Block' : 'Unblock'}?
              </p>
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="bg-theme-save-framwork-popup bg-transparent"
                  onClick={() => {
                    setblockModalVisible(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2"
                  onClick={() => {
                    setblockModalVisible(false)
                    changeMode(blockEmployeeId, !data.status)
                  }}
                >
                  {data.status ? 'Block' : 'Unblock'}
                </Button>
              </div>
            </div>
          </Modal>
          {/* Make admin Modal  */}
          <Modal
            visible={adminModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
          >
            <div className="text-center">
              <img src={PopupConfirm} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">
                Are you sure you want to Make Admin?
              </p>
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="bg-theme-save-framwork-popup bg-transparent"
                  onClick={() => {
                    setadminModalVisible(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2"
                  onClick={() => {
                    setadminModalVisible(false)
                  }}
                >
                  Make Admin
                </Button>
              </div>
            </div>
          </Modal>
        </Col>
      </Row>
      {/* <Row onClick={() => setDrawerVisible(true)} className="cursor-pointer"> */}
      <Row onClick={() => getDetail(data._id)} className="cursor-pointer">
        <Col xs={4} xl={3} className="ms-4">
          <img
            src={data.image ? data.image : UserCirlce}
            alt="not availabel"
            className="company-logo img-fluid"
          />
        </Col>
        <Col xs={12} xl={12} className="ms-2">
          <b>
            <span
              style={{ textDecoration: data.status ? 'none' : 'line-through' }}
            >
              {data.name}
            </span>
          </b>
          <Row justify="start">
            <Col xs={14} xl={12}>
              <p>
                <UserOutlined />
                &nbsp;&nbsp;{data.emp_id}
              </p>
            </Col>
            <Col xs={10} xl={12}>
              <p>
                <img src={WPForm} alt="Document" className="w-15" />
                &nbsp;&nbsp;{data.participantCount}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row onClick={() => getDetail(data._id)} className="cursor-pointer">
        <Col md={12}>
          {/* {data.online && <div className="live-status-circle" />} */}
          <div
            className={
              data.status ? 'live-status-circle' : 'deactive-status-circle'
            }
          />
        </Col>
        {/* <Col md={12} className="text-end">
          <img src={data.gravater} alt="not availabel" className="gravatar" />
        </Col> */}
      </Row>
    </div>
  )
}
