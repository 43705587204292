/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react'
import './style/company.scss'
import { Link, useHistory } from 'react-router-dom'
import { Drawer, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import DrawerFooter from '../../components/Drawer/footer/drawerFooter'
import Label from '../../components/Label'
import Button from '../../components/Button'
import rightArrow from '../../assets/images/Icon ionic-ios-arrow-back.svg'
import buildingsgray from '../../assets/images/buildingsgray.svg'
import arwDivider from '../../assets/images/Path 26578.svg'
import { CompanyListApi, logout } from '../../api/API'
import {
  COMPANY,
  COMPANY_ROLE,
  CREATE_NEW,
  DO_NOT_HAVE_COMPANY,
  SUPER_ADMIN_ROLE,
} from '../../constants'
import ListCompany from './listCompany'
import Image from '../../components/Image'
import addCompany from '../../assets/images/addCompany.svg'
import { AddCompany } from './components/addCompany'
import { storeUserInfoAction } from '../../reduxActions/userAction'
import Nav from '../../components/Navbar'
import { mock } from './mock'
import { readCookie } from '../../api/CookieScript'

export const Company: React.FC = (): JSX.Element => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [companyList, setcompanyList] = useState([])
  const [loading, setLoading] = useState(true)
  const [isFilterMode, setIsFilterMode] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const dispatch = useDispatch()
  const history = useHistory()
  // const userDataInfo = useSelector((state) => state.userInfo)

  useEffect(() => {
    getCompanyList()
  }, [currentPage])
  const getCompanyList = (): void => {
    setLoading(true)
    const data = {
      page_no: currentPage,
    }
    CompanyListApi(data)
      .then((res: any): any => {
        setLoading(false)
        if (readCookie('role') === COMPANY_ROLE) {
          history.push(`/company-detail/${res.data.data.docs._id}`)
        } else {
          setTotalPages(res.data.data.totalPages)
          setcompanyList(res.data.data.docs)
          dispatch(storeUserInfoAction(res.data.data.docs))
        }
        // setcompanyList(mock)
      })
      .catch((err: any) => {
        if (err.response) {
          if (err.response.status === 401) {
            logout()
            history.push('/login')
          }
        }
      })
  }

  // const listcompany = companyList === undefined ? mock : companyList
  return (
    <div className="min-85vh">
      {loading && <Spin className="spinner-code" />}
      {companyList.length === 0 && !loading && !isFilterMode && (
        <div className="container-fluid company-section-container">
          <div className="company-header">
            <div className="company-header-section-1">
              <Label
                title={COMPANY}
                style={{
                  fontWeight: 701,
                  fontSize: '1.5rem',
                  fontFamily: 'Calibri',
                  color: 'black',
                }}
              />
              <div className="company-header-section-link-container">
                <Link to="/dashboard">
                  <span className="dashboard-link">Dashboard</span>
                </Link>
                &nbsp;
                <img src={rightArrow} alt="rightArrow" />
                &nbsp;
                <Link to="/company">
                  <span className="company-link">Company</span>
                </Link>
              </div>
            </div>
            <div className="company-header-section-2">
              <Button
                onClick={() => setDrawerVisible(true)}
                btnClass="create-new-btn"
              >
                {CREATE_NEW}
              </Button>
            </div>
          </div>
          <hr className="mobile-hr" />
          <div className="company-main">
            <div className="company-main-section">
              <Image
                src={addCompany}
                alt="Add Company"
                style={{ marginBottom: '20px', maxWidth: '100px' }}
              />
              <Label
                title={DO_NOT_HAVE_COMPANY}
                style={{
                  marginBottom: '20px',
                  fontSize: '1rem',
                  fontFamily: 'Calibri',
                  color: '#b6becc',
                  textAlign: 'center',
                }}
              />
              <Button
                onClick={() => setDrawerVisible(true)}
                btnClass="create-new-btn2"
              >
                <span className="material-icons">add</span>
                <span className="create-new-company">{CREATE_NEW}</span>
              </Button>
            </div>
          </div>
          <div className="drawer-wrapper">
            <Drawer
              onClose={() => {
                setDrawerVisible(false)
              }}
              visible={drawerVisible}
              title={
                <span>
                  <img
                    src={buildingsgray}
                    alt="Add New Company"
                    className="me-3 drawer-head-icon"
                  />
                  ADD NEW COMPANY
                </span>
              }
              width={462}
              footer={<DrawerFooter />}
            >
              <AddCompany
                getCompanyList={getCompanyList}
                setDrawerVisible={setDrawerVisible}
              />
            </Drawer>
          </div>
        </div>
      )}
      {(companyList.length !== 0 || isFilterMode) && (
        <ListCompany
          companyList={companyList}
          setcompanyList={setcompanyList}
          getCompanyList={getCompanyList}
          setIsFilterMode={setIsFilterMode}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
    </div>
  )
}
