export const mock = [
  {
    abn: 'GM00001',
    name: 'Techytt India PTY LTD',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '0000',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: '',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00002',
    name: 'Google Corp Australia PVT LTD',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: 'PeterStark',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00003',
    name: 'Microsoft',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: '',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00004',
    name: 'Snapchat',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: 'PeterStark',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00005',
    name: 'Techytt India PTY LTD',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: 'PeterStark',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00006',
    name: 'Techytt India PTY LTD',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: '',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00007',
    name: 'Techytt India PTY LTD',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: 'PeterStark',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00008',
    name: 'Techytt India PTY LTD',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: '',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
  {
    abn: 'GM00009',
    name: 'Techytt India PTY LTD',
    logo: 'https://i.ibb.co/54GxVMv/unnamed-1.jpg',
    _id: '',
    employees: [
      '617cc74ed04fef03d25aa639',
      '617cc8989e70ad9f390e4dfe',
      '617cc8989e70ad9f390e4dfe',
      {
        name: 'employee1',
        phone: '7425077332',
        email: 'sm@gmail.com',
      },
      {
        name: 'employee1',
        company: '616f2d9ce418a1af61de9e59',
        phone: '7425077332',
        email: 'smksdf@gmail.com',
        _id: '617ccc0ab670294633d7e10c',
        created_at: '2021-10-30T04:37:30.034Z',
        updatedAt: '2021-10-30T04:37:30.034Z',
        __v: 0,
      },
    ],
    checkbox: false,
    phone: '+1 (917) 547-3246',
    survey: '80%',
    tickets: 2,
    employee: '',
    date: '20-JAN-2021',
    email: 'info@techyett.com',
  },
]
