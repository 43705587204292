/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import { Button, Modal, Spin, Table } from 'antd'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
// import { useHistory, Switch, Route, Link } from 'react-router-dom'
import './styles/Project.scss'
import './components/styles/listItem.scss'
import {
  FileExcelOutlined,
  FileOutlined,
  FolderOutlined,
  EyeOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import arrowDown from '../../../assets/images/arrow_down.svg'
import { RecentProjectApi, removeProject } from '../../../api/API'
import Ellipse from '../../../assets/images/ellipse.svg'
import modalIcon from '../../../assets/images/Group 9367.svg'
import { readCookie } from '../../../api/CookieScript'
import {
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
  COMPANY_ROLE,
  ROLE_KEYWORD,
} from '../../../constants'

interface Props {
  controller: any
}

export const Project: React.FC<Props> = ({ controller }) => {
  const [projectState, setProjectState] = useState('all')
  const [recentProject, setRecentProject] = useState([])
  const [dropdownValue, setDropdownValue] = useState('All Projects')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [deleteProjectId, setDeleteProjectId] = useState('')
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: 'Project Name',
      dataIndex: ['name', '_id'],
      render: (text: any, record: any) => (
        <Link to={`/project-detail/${record._id}`} className="link_text">
          {record.name}
        </Link>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'companyData',
      render: (text: any, record: any) => (
        <>
          {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
            <Link
              to={`/company-detail/${record.companyData[0]._id}`}
              className="link_text"
            >
              {record.companyData[0].name}
            </Link>
          )}
          {readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE && (
            <p>{record.companyData[0].name}</p>
          )}
        </>
      ),
    },
    {
      title: 'Employees',
      dataIndex: 'totalEmployee',
      render: (text: any) => <p> {text} </p>,
    },
    // {
    //   title: 'Raters',
    //   dataIndex: 'raters',
    //   render: (text: any) => <p> {text ? text.length : 0} </p>,
    // },
    {
      title: 'Participation',
      dataIndex: 'totalParticipant',
      render: (text: any) => <p> {text} </p>,
    },
    {
      title: 'Expiry Date',
      dataIndex: 'end_date',
      render: (text: any) => <p> {text.substring(0, 10)} </p>,
    },
    {
      title: 'Status',
      dataIndex: 'mode',
      render: (text: any) => (
        <p className={text === true ? 'active_label' : 'deactive_label'}>
          {text === true ? 'Active' : 'Deactive'}
        </p>
      ),
    },
    {
      render: (text: any, record: any) => (
        <div className="ellipse">
          <Dropdown>
            <Dropdown.Toggle
              className="ellipse-menu ms-4 border-1"
              id="dropdown-basic"
            >
              <img src={Ellipse} alt="menu" className="ellipse-img" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="project_menus_item">
              <Link
                to={`/project-detail/${record._id}`}
                className="dropdown-item"
              >
                <EyeOutlined className="me-2" />
                View Project
              </Link>
              {/* <Dropdown.Item href="#/action-2">Close Project</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Copy Project</Dropdown.Item>
              <Dropdown.Item href="#/action-4">Rename Project</Dropdown.Item>
              <Dropdown.Item href="#/action-5">Download Data</Dropdown.Item> */}
              {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
                readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
                // <Dropdown.Item onClick={() => deleteProject(record._id)}>
                <Dropdown.Item
                  onClick={() => {
                    setDeleteProjectId(record._id)
                    setIsModalVisible(true)
                  }}
                >
                  <DeleteOutlined className="me-2" />
                  Delete Project
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ]

  const getRecentProjectData = (): any => {
    const data = { type: projectState }
    setLoading(true)
    RecentProjectApi(data, controller).then((res: any): any => {
      setRecentProject(res.data.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    getRecentProjectData()
  }, [projectState])

  const deleteProject = (id: any): void => {
    removeProject(id)
      .then((res: any): any => {
        getRecentProjectData()
      })
      .catch((error: any) => {
        getRecentProjectData()
      })
  }

  return (
    <div className="project">
      <Spin spinning={loading}>
        <div className="Project-Container">
          <div className="d-flex justify-content-between align-items-center w-100 p-2 fixed">
            <div className="d-flex flex-column">
              <span className="project-text1">Recent Projects</span>
              <span className="project-text2">
                Check all the current projects
              </span>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 p-1 border-1 allproject-dropdown"
                id="dropdown-basic"
              >
                <span className="project-text3">{dropdownValue}</span>
                <img src={arrowDown} alt="arrow" className="arrow_down" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  // onClick={(() => setProjectState('all');getRecentProjectData())}
                  onClick={() => {
                    setProjectState('all')
                    setDropdownValue('All Projects')
                  }}
                >
                  <FolderOutlined className="me-2" />
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setProjectState('open')
                    setDropdownValue('Open Projects')
                  }}
                >
                  <FileOutlined className="me-2" />
                  Open Projects
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setProjectState('close')
                    setDropdownValue('Closed Projects')
                  }}
                >
                  <FileExcelOutlined className="me-2" />
                  Closed Projects
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="m-inner-2">
            <Table
              // rowSelection={rowSelection}
              rowClassName={(record, index) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              }
              columns={columns}
              dataSource={
                recentProject && recentProject.length > 0 ? recentProject : []
              }
              pagination={false}
              // rowKey="id"
              className="w-100 dashboardProjectTable"
            />
          </div>
        </div>
      </Spin>
      {/* Delete project modal */}
      <Modal
        visible={isModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
        className="templateModal"
      >
        <div className="text-center">
          <img src={modalIcon} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">Please Confirm</p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="ghost bg-transparent border-rounded cancelButton"
              onClick={() => {
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2 saveButton"
              onClick={() => {
                deleteProject(deleteProjectId)
                setIsModalVisible(false)
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
