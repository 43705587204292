import React from 'react'
import './Footer.css'

export const Footer = (): JSX.Element => {
  return (
    <div className="pt-2 footer">
      <div className="footer__container d-flex justify-content-between pt-3 flex-wrap">
        <p className="ms-1 footer-copy">
          Copyright © 2021 GrowMetrix PTY LTD. All Rights Reserved.
        </p>
        <div className="d-flex justify-content-between footer-menus">
          <p>TERMS & CONDITIONS</p>
          <p>COOKIE POLICY</p>
          <p>PRIVACY POLICY</p>
        </div>
      </div>
    </div>
  )
}
