/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactQuill, { Quill } from 'react-quill'
import { Alert, notification, Form, Input, Button, DatePicker } from 'antd'
import $ from 'jquery'
import { addProject, getCompanyList } from '../../../api/API'
import TextArea from '../../../components/InputElements/TextArea'
// import Button from '../../../components/Button'
// const Quill = ReactQuill?.Quill
const Font = Quill.import('attributors/class/font')
const fontList = [
  'Calibri Regular',
  'Calibri Bold',
  'Calibri Italic',
  'Calibri Bold Italic',
  'Calibri Light',
  'Calibri Light Italic',
  'serif',
  'sans-serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
  'emoji',
  'math',
  'fangsong',
]
Font.whitelist = fontList.map((font) => getFontName(font))
Quill.register(Font, true)

let fontStyles: any
const node = document.createElement('style')
node.innerHTML = fontStyles
document.body.appendChild(node)

fontStyles = ''
fontList.forEach(function (font) {
  const fontName = getFontName(font)
  fontStyles +=
    `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {` +
    `content: '${font}';` +
    `font-family: '${font}', sans-serif;` +
    `}` +
    `.ql-font-${fontName}{` +
    ` font-family: '${font}', sans-serif;` +
    `}`
})

function getFontName(font: any): any {
  return font.toLowerCase().replace(/\s/g, '-')
}

const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }],
    [{ font: Font.whitelist }],
    [{ header: [1, 2, 3, 4, 5, 6, 7] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
}
const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]

interface Props {
  setProjectDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getCompanyDetail: any
}
export const AddProject: React.FC<Props> = ({
  setProjectDrawerVisible,
  getCompanyDetail,
}) => {
  // const history = useHistory()
  const params = useParams<any>()
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [name, setName] = useState('')
  const [company, setCompany] = useState<any>(params.id)
  const [contactPerson, setContactPerson] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [description, setDescription] = useState('')
  const [companyList, setCompanyList] = useState([])

  useEffect(() => {
    setCompany(params.id)
  }, [])

  const resetform = (): void => {
    $('.name').text('')
    setName('')
    setContactPerson('')
    setEmail('')
    setPhone('')
    setStartDate('')
    setEndDate('')
    setDescription('')
  }
  const handleAddProject = (): void => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    const data = {
      name,
      company,
      contact_person: contactPerson,
      email,
      phone,
      start_date: startDate,
      end_date: endDate,
      description,
    }
    if (
      name !== '' ||
      company !== '' ||
      phone !== '' ||
      (email !== '' && email.match(emailRegex)) ||
      startDate !== '' ||
      endDate !== ''
    ) {
      addProject(data).then((res: any): any => {
        if (res.isAxiosError && res.response.status === 400) {
          notification.error({
            type: 'error',
            message: res.response.data.message,
          })
        } else {
          const response = res.data
          setName('')
          setContactPerson('')
          setEmail('')
          setPhone('')
          setStartDate('')
          setEndDate('')
          setDescription('')
          if (res.status !== 200) {
            notification.open({
              type: 'error',
              message: 'Something went wrong',
            })
            console.log('something is not right')
          } else {
            // history.push('/project-detail')
            notification.open({
              type: 'success',
              message: 'Project successfully Added',
            })
            resetform()
            setTimeout(() => {
              getCompanyDetail()
              setProjectDrawerVisible(false)
            }, 1000)
            // window.location.reload()
          }
        }
      })
    } else {
      setErrorMsg('Please Enter Required field')
      setIsError(true)
    }
  }

  return (
    <div className="add-company">
      <div className="form-container">
        <Alert
          message={errorMsg}
          type="error"
          style={{ display: isError ? 'block' : 'none' }}
        />
        <Form onFinish={handleAddProject}>
          <div className="form-group">
            <label htmlFor="inputName4" className="form-label">
              Project Name
              <span className="asterik">*</span>
            </label>
            {/* <input
              type="name"
              className="form-control"
              id="inputName4"
              placeholder="Please Enter Project Name"
              onChange={(e: any) => setName(e.target.value)}
            /> */}
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Please Enter Project Name' }]}
            >
              <Input
                id="name"
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Please Enter Project Name"
              />
            </Form.Item>
          </div>
          <div className="form-row">
            {/* <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px' }}
            >
              <label htmlFor="inputPhoneNumber" className="form-label">
                Phone
              </label>
              <Form.Item
                name="mobile"
                rules={[
                  {
                    required: false,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Enter Valid phone number',
                  },
                ]}
              >
                <Input
                  onChange={(e: any) => setPhone(e.target.value)}
                  placeholder="Enter phone number"
                />
              </Form.Item>
            </div> */}
          </div>
          <div className="form-row">
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px', width: '50%' }}
            >
              <label htmlFor="inputStartDate" className="form-label">
                Start Date
                <span className="asterik">*</span>
              </label>
              {/* <input
                type="date"
                className="form-control ant-input"
                id="inputStartDate"
                onChange={(e: any) => setStartDate(e.target.value)}
              /> */}
              <Form.Item
                name="start-date"
                rules={[{ required: true, message: 'Select Start date' }]}
              >
                <DatePicker
                  className="w-100"
                  onChange={(date, dateString) => setStartDate(dateString)}
                />
              </Form.Item>
            </div>
            <div
              className="form-group col col-md-6"
              style={{ paddingLeft: '7px', width: '50%' }}
            >
              <label htmlFor="inputEndDate" className="form-label">
                End Date
              </label>
              <span className="asterik">*</span>
              {/* <input
                type="date"
                className="form-control ant-input"
                id="inputEndDate"
                onChange={(e: any) => setEndDate(e.target.value)}
              /> */}
              <Form.Item
                name="end-date"
                rules={[{ required: true, message: 'Select End date' }]}
              >
                <DatePicker
                  className="w-100"
                  onChange={(date, dateString) => setEndDate(dateString)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div
              className="form-group col col-md-6"
              style={{ paddingRight: '7px', width: '50%' }}
            >
              <label htmlFor="inputEmail" className="form-label">
                Email
                <span className="asterik">*</span>
              </label>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The email is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please enter E-mail!',
                  },
                ]}
              >
                <Input
                  onChange={(e: any) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                />
              </Form.Item>
            </div>
            <div
              className="form-group col col-md-6"
              style={{ paddingLeft: '7px', width: '50%' }}
            >
              <label htmlFor="inputContactPerson" className="form-label">
                Contact Person
              </label>
              {/* <input
                type="name"
                className="form-control"
                id="inputContactPerson"
                onChange={(e: any) => setContactPerson(e.target.value)}
              /> */}
              <Form.Item name="contactname">
                <Input
                  onChange={(e: any) => setContactPerson(e.target.value)}
                  placeholder="Please Enter Contact Person Name"
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress1" className="form-label">
              Description
            </label>
            {/* <TextArea placeholder=" " onChange={setDescription} /> */}
            <ReactQuill
              theme="snow"
              formats={formats}
              modules={modules}
              className="inputBorder"
              // defaultValue={description || ''}
              value={description || ''}
              onChange={(e: any) => setDescription(e)}
            />
          </div>
          <div className="button-group">
            <Button
              className="cancelButton"
              onClick={() => {
                setProjectDrawerVisible(false)
                resetform()
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="saveButton border-0"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
