/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react'
import LogImg from '../../../../../assets/images/logo.png'
import PlusIcon from '../../../../../assets/images/whitePlusIcon.svg'
import Icon360 from '../../../../../assets/images/icon_360.png'

interface Props {
  setFocusAreaVisible: React.Dispatch<React.SetStateAction<boolean>>
  data: number[]
  colors: string[]
  icon: string[]
  constructPerFocus: any
  setSelectedFocusarea: any
  FocusAreaData: any
}

export const Chart: React.FC<Props> = ({
  setFocusAreaVisible,
  data,
  colors,
  icon,
  setSelectedFocusarea,
  constructPerFocus,
  FocusAreaData,
}): JSX.Element => {
  let oSvg: any
  const [tmp, setTmp] = useState(false)
  const cx = 400
  const cy = 300
  const r = 200
  let sum = 0 // Initial center radius
  let now = 0
  useEffect(() => {
    oSvg = document.getElementById('s1')
    oSvg.innerHTML = ''

    const circle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle',
    )
    circle.setAttribute('cx', `${cx}`)
    circle.setAttribute('cy', `${cy}`)
    circle.setAttribute('r', `${r + 60}`)
    circle.setAttribute('fill', 'transparent')
    circle.setAttribute('stroke', '#000')
    circle.setAttribute('stroke-dasharray', '5,5')
    oSvg.appendChild(circle)

    data.forEach((item: any) => {
      // Find the sum of data
      sum += item
    })
    data.forEach((item: any, index: any) => {
      // Circle drawing
      const ang = (360 * item) / sum
      pie(now, now + ang, index)
      now += ang
    })

    const middleImage = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'image',
    )
    middleImage.setAttribute('x', `${cx - 50}`)
    middleImage.setAttribute('y', `${cy - 50}`)
    middleImage.setAttribute('href', Icon360)
    middleImage.setAttribute('width', '100')
    middleImage.setAttribute('height', '100')
    console.log(colors)
    oSvg.appendChild(middleImage)
  }, [colors])

  const d2a = (ang: any): any => {
    // Angular radian
    return (ang * Math.PI) / 180
  }
  const point = (ang: any): any => {
    // Coordinate according to angle
    return {
      x: cx + r * Math.sin(d2a(ang)),
      y: cy - r * Math.cos(d2a(ang)),
    }
  }

  const pie = (ang1: any, ang2: any, colorIndex: any): any => {
    const oPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    oPath.setAttribute('stroke', 'white')
    oPath.setAttribute('fill', `${colors[colorIndex]}`)
    oPath.setAttribute('stroke-width', `${2}`)
    oPath.setAttribute('id', `${ang1}-${ang2}`)

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const calcD = (r: any): any => {
      // Three steps to draw a fan
      const arr = []
      // First step
      const { x: x1, y: y1 } = point(ang1)
      arr.push(`M ${cx} ${cy} L ${x1} ${y1}`) // Draw the first line
      // The second step
      const { x: x2, y: y2 } = point(ang2)
      arr.push(`A ${r} ${r} 0 ${ang2 - ang1 > 180 ? 1 : 0} 1 ${x2} ${y2}`) // Arc drawing
      // The third step
      arr.push('Z') // Close

      oPath.setAttribute('d', arr.join(' ')) // Splicing strings, executing drawing commands
      oSvg.appendChild(oPath) // Add to svg

      const innnerCircle = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle',
      )
      innnerCircle.setAttribute(
        'cx',
        `${cx + (r / 1.9) * Math.sin(d2a(ang1)) - (x1 - x2) / 3.9}`,
      ) // r * Math.sin(d2a(ang1)) / 2
      innnerCircle.setAttribute(
        'cy',
        `${cy - (r / 1.9) * Math.cos(d2a(ang1)) - (y1 - y2) / 3.9}`,
      ) // r * Math.cos(d2a(ang1)) / 2
      innnerCircle.setAttribute('r', '15')
      innnerCircle.setAttribute('fill', '#fff')

      const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text',
      )
      // text.setAttribute('x', `${cx + r * Math.sin(d2a(ang1)) + 0}`);
      // text.setAttribute('y', `${cy - r * Math.cos(d2a(ang1)) + 0}`);
      text.setAttribute(
        'x',
        `${cx + (r / 1.9) * Math.sin(d2a(ang1)) - (x1 - x2) / 3.9 - 8}`,
      )
      text.setAttribute(
        'y',
        `${cy - (r / 1.9) * Math.cos(d2a(ang1)) - (y1 - y2) / 3.9 + 8}`,
      )
      // text.setAttribute('text-anchor', "middle");
      text.setAttribute('font-size', '20px')
      text.textContent = String.fromCharCode(65 + colorIndex)

      // outer Cirlce
      const circle = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle',
      )
      circle.setAttribute(
        'cx',
        `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6}`, // it's working
      )
      circle.setAttribute(
        'cy',
        `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6}`, // it's working
      )
      circle.setAttribute('r', '25')
      circle.setAttribute('fill', '#FFF')
      circle.setAttribute('stroke', '#8C8C8C')
      // circle.setAttribute('stroke-dasharray', '5,5')
      // here select previous color from colors
      // circle.setAttribute(
      //   'fill',
      //   colorIndex === 0 ? colors[colors.length - 1] : colors[colorIndex - 1],
      // )

      // main icon
      const logoImage =
        colorIndex === 0 ? icon[icon.length - 1] : icon[colorIndex - 1]

      // Middle plus icon of there is no icon and focusarea added
      const circleIconBG = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle',
      )
      const circlePlusIcon = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'image',
      )
      if (logoImage === 'default') {
        circleIconBG.setAttribute(
          'cx',
          `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6}`,
        ) // cx + r * Math.sin(d2a(ang1)) + (x1 - x2) /  1.5
        circleIconBG.setAttribute(
          'cy',
          `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6}`,
        ) // cy - r * Math.cos(d2a(ang1)) + (y1 - y2) /  1.5
        circleIconBG.setAttribute('r', '10')
        circleIconBG.setAttribute('fill', '#589681')

        circlePlusIcon.setAttribute(
          'x',
          `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6 - 5}`,
        )
        circlePlusIcon.setAttribute(
          'y',
          `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6 - 5}`,
        )
        circlePlusIcon.setAttribute('href', PlusIcon)
        circlePlusIcon.setAttribute('width', '10')
        circlePlusIcon.setAttribute('height', '10')
        circlePlusIcon.setAttribute('class', 'plusiconforaddslice')
        circlePlusIcon.addEventListener('click', openFocusAreaForm)
      }

      const circleIcon = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'image',
      )
      circleIcon.setAttribute(
        'x',
        `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6 - 15}`,
      )
      circleIcon.setAttribute(
        'y',
        `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6 - 15}`,
      )

      circleIcon.setAttribute(
        'href',
        logoImage !== 'default' ? logoImage : PlusIcon,
      )
      circleIcon.setAttribute('width', '30')
      circleIcon.setAttribute('height', '30')

      // main icon end

      // ----------- Plus Icon
      const plusIconBG = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle',
      )
      plusIconBG.setAttribute(
        'cx',
        `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6 + 20}`,
      ) // cx + r * Math.sin(d2a(ang1)) + (x1 - x2) /  1.5
      plusIconBG.setAttribute(
        'cy',
        `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6 + 20}`,
      ) // cy - r * Math.cos(d2a(ang1)) + (y1 - y2) /  1.5
      plusIconBG.setAttribute('r', '10')
      plusIconBG.setAttribute('fill', '#589681')

      const plusIcon = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'image',
      )
      plusIcon.setAttribute(
        'x',
        `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6 + 15}`,
      )
      plusIcon.setAttribute(
        'y',
        `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6 + 15}`,
      )
      plusIcon.setAttribute('href', PlusIcon)
      plusIcon.setAttribute('width', '10')
      plusIcon.setAttribute('height', '10')
      plusIcon.setAttribute('class', 'plusiconforaddslice')
      plusIcon.addEventListener('click', openFocusAreaForm)

      // ----------- End Plus Icon

      // ---------- Counter
      const countBG = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle',
      )
      countBG.setAttribute(
        'cx',
        `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6 - 20}`,
      ) // cx + r * Math.sin(d2a(ang1)) + (x1 - x2) /  1.5
      countBG.setAttribute(
        'cy',
        `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6 - 20}`,
      ) // cy - r * Math.cos(d2a(ang1)) + (y1 - y2) /  1.5
      countBG.setAttribute('r', '10')
      countBG.setAttribute('fill', '#FFF')
      countBG.setAttribute('stroke', '#8C8C8C')

      const countNumber = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text',
      )
      countNumber.setAttribute(
        'x',
        `${cx + r * Math.sin(d2a(ang1)) + (x1 - x2) / 1.6 - 24}`,
      )
      countNumber.setAttribute(
        'y',
        `${cy - r * Math.cos(d2a(ang1)) + (y1 - y2) / 1.6 - 15}`,
      )
      countNumber.setAttribute('width', '10')
      countNumber.setAttribute('height', '10')
      countNumber.setAttribute('class', 'countNumberforaddslice')
      const numerofConstruct =
        colorIndex === 0
          ? constructPerFocus[constructPerFocus.length - 1]
          : constructPerFocus[colorIndex - 1]
      countNumber.textContent = numerofConstruct

      // ---------- End Counter

      // single big line
      const outLine = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
      )
      outLine.setAttribute(
        'd',
        `M ${cx} ${cy} L ${cx + r * Math.sin(d2a(ang1)) * 1.45} ${
          cy - r * Math.cos(d2a(ang1)) * 1.45
        }`,
      )
      outLine.setAttribute('stroke', '#CDD6DC')

      oSvg.appendChild(innnerCircle) // Add to svg
      oSvg.appendChild(text) // Add to svg
      oSvg.appendChild(outLine) // Add to svg
      oSvg.appendChild(circle) // Add to svg
      oSvg.appendChild(circleIcon) // Add to svg
      if (logoImage !== 'default') {
        if (FocusAreaData.length < 7) {
          oSvg.appendChild(plusIconBG) // Add to svg
          oSvg.appendChild(plusIcon) // Add to svg
        }
      } else {
        oSvg.appendChild(circleIconBG) // Add to svg
        oSvg.appendChild(circlePlusIcon) // Add to svg
      }

      oSvg.appendChild(countBG) // Add to svg
      oSvg.appendChild(countNumber) // Add to svg
    }
    calcD(r)
  }

  const openFocusAreaForm = (): void => {
    setSelectedFocusarea('')
    setFocusAreaVisible(true)
  }

  return (
    <div className="mt-4 pt-0" style={{ width: '90%' }}>
      <svg
        id="s1"
        viewBox="0 0 750 750"
        // width={800}
        // height={600}
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      ></svg>
    </div>
  )
}
