/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import { Table, Button, Typography, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { Doughnut } from 'react-chartjs-2'
import { Dropdown } from 'react-bootstrap'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { mock } from './mock'
import './style/listview.scss'
import more from '../../assets/images/Icon feather-more-vertical.svg'
import PopupConfirm from '../../assets/images/Group 9366.svg'
import { removeCompany } from '../../api/API'

const { Paragraph } = Typography

interface Props {
  currentPosts?: any[]
  changeMode: any
  getCompanyList: any
}

const Listview: React.FC<Props> = ({
  currentPosts,
  changeMode,
  getCompanyList,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState()

  const chartFn = (completed: any, notCompleted: any): any => {
    return {
      datasets: [
        {
          data: [completed || 0, 100 - completed],
          backgroundColor: ['#008174', '#b2d9d5'],
          hoverOffset: 0,
        },
      ],
    }
  }

  const deleteCompany = (): void => {
    removeCompany(selectedCompany).then((res: any) => {
      setIsModalVisible(false)
      getCompanyList()
    })
  }

  const columns = [
    {
      title: 'COMPANY NAME',
      dataIndex: 'image',
      key: 'image',
      render: (text: any, record: any) => (
        <div className="d-flex align-items-center">
          <div
            className={
              record.mode ? 'active-dot me-1' : 'card-deactive-greydot me-1'
            }
          />
          <img
            height="60px"
            width="60px"
            src={record.logo}
            alt={record.name}
            className="listview-image me-3"
          />
          <Link
            to={{ pathname: `/company-detail/${record._id}` }}
            className="card-link"
          >
            <h5 className="tab-item text-primary mb-0">
              <span
                style={{
                  textDecoration: record.mode ? 'none' : 'line-through',
                }}
              >
                {record.name}
              </span>
            </h5>
          </Link>
        </div>
      ),
    },
    {
      title: 'COMPANY ID',
      dataIndex: 'company_id',
      key: 'company_id',
    },
    {
      title: 'REGISTERED DATE',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: any) => <p className="mb-0">{text.substring(0, 10)}</p>,
    },
    {
      title: 'EMAIL',
      key: 'email',
      dataIndex: 'email',
      render: (text: any, record: any) => (
        <Paragraph className="mb-0" copyable>
          {record.email}
        </Paragraph>
      ),
    },
    {
      title: 'PHONE',
      key: 'phone',
      dataIndex: 'phone',
      render: (text: any, record: any) => (
        <Paragraph className="mb-0" copyable>
          {record.phone}
        </Paragraph>
      ),
    },
    {
      title: 'EMPLOYEES',
      key: 'employees',
      dataIndex: 'employees',
      render: (text: any, record: any) => (
        <h5 className="tab-item">{record.emp ? record.emp.length : 0}</h5>
      ),
    },
    {
      title: 'SURVEY',
      key: 'survey',
      dataIndex: 'survey',
      render: (text: any, record: any) => {
        return (
          // <div className="survey-chart-wrapper">
          //   <div className="label-wrapper">
          //     <span>{Math.round(record.completedSurveyStatus)}%</span>
          //   </div>
          //   <div className="chart-wrapper">
          //     <Doughnut
          //       data={chartFn(
          //         Math.round(record.completedSurveyStatus),
          //         Math.round(record.notCompletedSurveyStatus),
          //       )}
          //     />
          //   </div>
          // </div>
          <div
            style={{ height: '60px', width: '60px', textAlign: 'center' }}
            className="position-relative"
          >
            <span
              className="position-absolute"
              style={{ top: '46%', left: '0', right: '0', fontSize: '9px' }}
            >
              {/* {Math.round(data.completedSurveyCount)}% */}
              {Math.round(record.completedSurveyStatus)}%
            </span>
            <Doughnut
              data={chartFn(
                record.completedSurveyStatus,
                record.notCompletedSurveyStatus,
              )}
            />
          </div>
        )
      },
    },
    {
      title: 'Contact Person',
      key: 'hr',
      dataIndex: 'hr',
      render: (text: any, record: any) => (
        <h5 className="tab-item mb-0">{record.contact_person}</h5>
      ),
    },
    {
      render: (text: any, record: any) => (
        <div className="d-flex justify-content-end">
          <Dropdown>
            <Dropdown.Toggle
              className="ellipse-menu border-0 p-0 m-0"
              id="dropdown-basic"
            >
              <img
                src={more}
                alt="Menu"
                className="me-3 p-0"
                height="20px"
                width="20px"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link
                to={{ pathname: `/company-detail/${record._id}` }}
                className="dropdown-item"
              >
                <span className="pe-2">
                  <EditOutlined />
                </span>
                Update
              </Link>
              <Dropdown.Item
                href="#/action-2"
                onClick={() => {
                  setIsModalVisible(true)
                  setSelectedCompany(record._id)
                }}
                // onClick={() => {
                //   removeCompany(record._id)
                // }}
              >
                <span className="pe-2">
                  <DeleteOutlined />
                </span>
                Delete
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => changeMode(record._id, !record.mode)}
              >
                <span
                  className={record.mode ? 'deactivate-dot' : 'activate-dot'}
                />
                {record.mode ? 'Deactivate' : 'Active'}
              </Dropdown.Item>
              {/* <Dropdown.Item>
                <span className="pe-2">
                  <InboxOutlined />
                </span>
                Archive
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ]

  return (
    <div className="company_list_view">
      <Table
        columns={columns}
        dataSource={currentPosts}
        className="tabledesign"
        rowKey="id"
      />

      <Modal
        visible={isModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">
            Are you sure you want to remove this company? This action cannot
            undone and all the data associated with this company will be removed
            too.
          </p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => deleteCompany()}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

Listview.defaultProps = {
  currentPosts: mock,
}

export default Listview
