/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, Modal } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import { CameraOutlined } from '@ant-design/icons'
import DefaultImage from '../../../../../../assets/images/Icon ionic-ios-woman.svg'
import UserIcon from '../../../../../../assets/images/Icon awesome-user-circle.svg'
import PopupConfirm from '../../../../../../assets/images/Group 9366.svg'
import {
  addFocusArea,
  getFocusArea,
  deleteFocusArea,
  updateFocusArea,
} from '../../../../../../api/API'

const { TextArea } = Input

interface Prop {
  addNewSlice: any
  focusAreaVisible: any
  selectedFocusarea: string
}

export const Detail: React.FC<Prop> = ({
  addNewSlice,
  selectedFocusarea,
  focusAreaVisible,
}): JSX.Element => {
  // const location = useLocation<any>()
  const params = useParams<any>()
  const [color, setColor] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [file, setFile] = useState<any>()
  const [imageUrl, setImageUrl] = useState(DefaultImage)
  const [isModalVisible, setIsModalVisible] = useState(false)

  // Error State
  const [colorErr, setColorErr] = useState('')
  const [nameErr, setNameErr] = useState('')
  const [fileErr, setFileErr] = useState('')

  // clear all error on drawer open
  useEffect(() => {
    setColorErr('')
    setNameErr('')
    setFileErr('')
  }, [focusAreaVisible])

  useEffect(() => {
    if (selectedFocusarea !== '') {
      getFocusArea(selectedFocusarea).then((res: any) => {
        const { data } = res.data
        setName(data[0].name)
        setDescription(data[0].description)
        setColor(data[0].color)
        setImageUrl(data[0].image)
      })
    } else {
      setName('')
      setDescription('')
      setColor('')
      setImageUrl('')
      setFile('')
    }
  }, [selectedFocusarea])

  const handleChange = (e: any): void => {
    setColor(e.target.value)
  }

  const handleImg = (e: any): void => {
    if (e.target.files[0]) {
      setImageUrl(URL.createObjectURL(e.target.files[0]))
      setFile(e.target.files[0])
    }
  }

  const submitForm = (): void => {
    setColorErr('')
    setNameErr('')
    setFileErr('')

    let valid = true
    if (color === '') {
      setColorErr('Select Color')
      valid = false
    }
    if (name === '') {
      setNameErr('Enter Name')
      valid = false
    }
    if (!file) {
      setFileErr('Select Image')
      valid = false
    }
    if (valid) {
      const formData = new FormData()
      formData.append('name', name)
      formData.append('description', description)
      formData.append('color', color)
      formData.append('image', file)
      formData.append('project_id', params.id)
      addFocusArea(formData).then((res: any) => {
        addNewSlice()
        setName('')
        setDescription('')
        setColor('')
        setImageUrl('')
        setFile('')
      })
    }
  }

  const updateForm = (): void => {
    setColorErr('')
    setNameErr('')
    // setFileErr('')

    let valid = true
    if (color === '') {
      setColorErr('Select Color')
      valid = false
    }
    if (name === '') {
      setNameErr('Enter Name')
      valid = false
    }
    // if (!file) {
    //   setFileErr('Select Image')
    //   valid = false
    // }
    if (valid) {
      const formData = new FormData()
      formData.append('name', name)
      formData.append('description', description)
      formData.append('color', color)
      formData.append('image', file)
      formData.append('project_id', params.id)
      updateFocusArea(selectedFocusarea, formData).then((res: any) => {
        addNewSlice()
      })
    }
  }

  const removeFocusArea = (): void => {
    deleteFocusArea(selectedFocusarea).then((res: any) => {
      addNewSlice()
    })
  }

  return (
    <div className=" pt-0 focus-area-form-popup">
      <div className="d-flex justify-content-between align-items-center">
        <p>Upload Icon</p>
        <div className="position-relative h-10vhs">
          <img
            className="profile-pic border"
            src={imageUrl || UserIcon}
            alt="Upload FocusArea"
          />
          <label className="filebutton border">
            <CameraOutlined />
            <span>
              <input
                type="file"
                id="myfile"
                name="myfile"
                onChange={(e: any) => handleImg(e)}
              />
            </span>
          </label>
        </div>
        <p className="w-50 bg-EDECEC p-2 profile-pic-rule">
          please make sure the profile pic file must be less then 1mb and in a
          dimentation of 150 x 150
        </p>
      </div>
      <Row>
        <label className="text-danger">{fileErr}</label>
      </Row>
      <Row justify="space-between" className="mt-3" gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            Name <span className="text-danger">*</span>
          </label>
          <Input
            value={name}
            className="popup-sidebar-form-input"
            onChange={(e: any) => setName(e.target.value)}
          />
          <label className="text-danger">{nameErr}</label>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            Color <span className="text-danger">*</span>
          </label>
          <br />
          {/* <Input type="color" className="popup-sidebar-form-input" /> */}
          <div className="color-selector w-100 position-relative">
            <div className="color-picker position-absolute">
              <span className="circle" style={{ background: color }} />
              <input
                type="color"
                defaultValue={color || '#cecece'}
                onChange={handleChange}
                className="hidden"
              />
            </div>
            <input
              type="text"
              className="w-100 input-color"
              value={color}
              onChange={handleChange}
            />
          </div>
          <label className="text-danger">{colorErr}</label>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>Description (upto maximum 150 character)</label>
          <TextArea
            maxLength={250}
            rows={6}
            value={description}
            className="popup-sidebar-form-input"
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </Col>
      </Row>
      <div className="text-end p-3 mt-20">
        {selectedFocusarea === '' && (
          <Button
            className="bg-theme-save-framwork-popup"
            onClick={() => {
              submitForm()
              // addNewSlice()
            }}
          >
            Save
          </Button>
        )}
        {selectedFocusarea !== '' && (
          <div className="d-flex w-100 justify-content-between">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                // removeFocusArea()
                setIsModalVisible(true)
                // addNewSlice()
              }}
            >
              Delete
            </Button>
            <Button
              className="bg-theme-save-framwork-popup"
              onClick={() => {
                updateForm()
                // addNewSlice()
              }}
            >
              Update
            </Button>
          </div>
        )}
      </div>
      <Modal
        visible={isModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">
            Are you sure you want to remove the focus area? This action cannot
            be undone and all the constructs associated with this focus area
            will be removed too.
          </p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                removeFocusArea()
                setIsModalVisible(false)
                addNewSlice()
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
