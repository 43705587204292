import React, { useState } from 'react'
import { Button, Table, DropdownButton, Dropdown } from 'react-bootstrap'
import { DrawerChart } from './components/graphComponent'
import './styles/surveyDetails.scss'

interface DProps {
  title?: string
  label?: string
  elements?: any[]
}
const DropDown: React.FC<DProps> = ({
  title,
  label,
  elements,
}): JSX.Element => {
  const currElements = elements === undefined ? [] : elements
  const [dropdownValue, setDropdownValue] = useState(label)
  return (
    <div className="dropdown dropdown-container">
      <button
        className="btn dropdown-toggle button"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {dropdownValue}
      </button>
      <span className="label">{title}</span>
      <div
        className="dropdown-menu custom-menu"
        aria-labelledby="dropdownMenuButton"
      >
        {currElements.map((element) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className="dropdown-item" href="#">
            {element}
          </a>
        ))}
      </div>
    </div>
  )
}
DropDown.defaultProps = {
  title: 'title',
  label: 'dropdown',
  elements: [],
}
const data1 = [
  { name: 'Not Completed Survey', survey: 25, color: '#A6CEE3' },
  { name: 'Completed Survey', survey: 75, color: '#1F78B4' },
]
const data2 = [
  { name: 'Not Completed Survey', survey: 65, color: '#A6CEE3' },
  { name: 'Completed Survey', survey: 35, color: '#1F78B4' },
]

export const SurveyDetails: React.FC = (): JSX.Element => {
  return (
    <div className="drawer-survey-wrapper">
      <div className="dropdown-wrapper">
        <DropDown
          title="Overall participation"
          label="Select"
          elements={['Action', 'Select Action', 'Something']}
        />
      </div>
      <div className="drawer-survey-chart-wrapper">
        <DrawerChart data={data1} percentage={75} />
      </div>
      <span className="survey-rater-title">Raters Participation</span>
      <div className="dropdown-wrapper">
        <DropDown
          title="Select Raters"
          label="Select"
          elements={['Action', 'Select Action', 'Something']}
        />
      </div>
      <div className="drawer-survey-chart-wrapper">
        <DrawerChart data={data2} percentage={35} />
      </div>
    </div>
  )
}
