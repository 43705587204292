import React, { useState } from 'react'
import { Input, Select, DatePicker, Button, Row, Col } from 'antd'
import GoArrow from '../../../../../assets/images/goArrow.svg'
import rightArrow from '../../../../../assets/images/rightArrow.svg'
import rightDoubleArrow from '../../../../../assets/images/right_double_arrow.svg'
import leftArrow from '../../../../../assets/images/leftArrow.svg'
import leftDoubleArrow from '../../../../../assets/images/left_double_arrow.svg'

const { Option } = Select
interface Props {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: any
  totalPages: any
  filterProject: any
}

const FilterBar: React.FC<Props> = ({
  setCurrentPage,
  currentPage,
  totalPages,
  filterProject,
}) => {
  const [customPage, setCustomPage] = useState<any>(1)
  const dateFormat = 'YYYY/MM/DD'
  const handleNumberInput = (e: any): void => {
    const valueTemp = e.target.value
    if (/[0-9]/.test(valueTemp)) {
      setCustomPage(valueTemp <= totalPages ? valueTemp : 1)
    } else {
      setCustomPage('')
    }
  }

  return (
    <div className="distribution-filter-container mt-3">
      <Row gutter={[8, 8]} justify="space-between">
        <Col
          xs={9}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          className="position-relative"
        >
          <span className="floating-label-search font-smaller text-muted">
            Search
          </span>
          <Input
            placeholder="Search by Name, ID"
            className="search_input"
            onChange={(e: any) => {
              filterProject('name', e)
            }}
          />
        </Col>
        <Col xs={7} sm={24} md={12} lg={6} xl={4} className="position-relative">
          <span className="floating-label-mode font-smaller text-muted">
            Mode
          </span>
          <Select
            defaultValue="active"
            style={{ width: '100%' }}
            onSelect={(e: any) => filterProject('mode', e)}
          >
            <Option value="active">Active</Option>
            <Option value="deactive">Deactive</Option>
          </Select>
        </Col>
        <Col xs={8} sm={24} md={12} lg={6} xl={4} className="position-relative">
          <span className="floating-label-date font-smaller text-muted">
            By Date
          </span>
          <DatePicker
            format={dateFormat}
            className="w-100"
            placeholder="By Date"
            onChange={(date) => filterProject('date_added', date)} // dateString
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={24} xl={7}>
          <div className="d-flex justify-content-end">
            <Button
              icon={<img src={leftArrow} className="w-25" alt="First" />}
              onClick={() => setCurrentPage(1)}
              className="rounded-start rounded-end-0"
            />
            <Button
              icon={
                <img src={leftDoubleArrow} className="w-50" alt="Previous" />
              }
              onClick={() =>
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
              }
              className="rounded-0"
            />
            <Button className="page-label rounded-0">
              Page {currentPage} of {totalPages}{' '}
            </Button>
            <Button
              icon={<img src={rightDoubleArrow} className="w-50" alt="Last" />}
              onClick={() =>
                setCurrentPage(currentPage < totalPages ? currentPage + 1 : 1)
              }
              className="rounded-0"
            />
            <Button
              icon={<img src={rightArrow} className="w-25" alt="Next" />}
              onClick={() => setCurrentPage(totalPages)}
              className="rounded-start-0 rounded-end"
            />
            <Input
              type="text"
              placeholder="Go to"
              className="goto-field ms-1"
              value={customPage}
              onChange={(e) => handleNumberInput(e)}
              // onChange={(e: any) =>
              //   setCustomPage(e.target.value <= totalPages ? e.target.value : 1)
              // }
            />
            <Button
              icon={<img src={GoArrow} alt="Go" className="w-50" />}
              className="btn-theme goto-btn ms-1"
              onClick={() => setCurrentPage(customPage)}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FilterBar
