/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
// import { Button } from 'react-bootstrap'
import { notification, Form, Input, Button, Upload, Select } from 'antd'
import CheckBox from '../../../components/Checkbox'
import Doubt from '../../../assets/images/users.svg'
import FileIcon from '../../../assets/images/FileIcon.png'
import { addParticipant, bulkParticipant, getAllRaters } from '../../../api/API'
import Plus from '../../../assets/images/plus.svg'
import Bulk from '../../../assets/images/bulk.svg'

const { Dragger } = Upload
const { Option } = Select

interface dProps {
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  dataIds: any
}
export const BulkUpload: React.FC<dProps> = ({
  setDrawerVisible,
  dataIds,
}): JSX.Element => {
  const [file, setFile] = useState<any>()
  const [chk, setchk] = useState(false)

  const props = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    beforeUpload: async (files: any) => {
      setFile(files)
      // Prevent upload
      return false
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        notification.success({
          message: `${info.file.name} file uploaded successfully`,
        })
      } else if (status === 'error') {
        notification.error({
          message: `${info.file.name} file upload failed.`,
        })
      }
    },
    onDrop(e: any) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const addFile = (e: any): void => {
    setFile(e[0])
  }
  const handleBulkSubmit = (): void => {
    const formData = new FormData()
    formData.append('companyId', dataIds.companyId)
    formData.append('projectId', dataIds.projectId)
    formData.append('employeeId', dataIds.employeeId)
    // formData.append('selected_raters', selectedRaters)
    formData.append('file', file)
    bulkParticipant(formData)
      .then((res: any) => {
        console.log(res)
        setDrawerVisible(false)
        notification.success({
          message: 'Participant successfully Added',
        })
        window.location.reload()
      })
      .catch((e: any) => {
        notification.error({
          message: 'something goes worong',
        })
      })
  }
  return (
    <div className="bulk-upload-wrapper">
      <Form className="form-wrapper" onFinish={handleBulkSubmit}>
        <div className="bulk-upload1">
          <Dragger {...props} className="file-upload-container">
            <img src={FileIcon} alt="fileIcon" className="file-upload-img" />
            <span className="file-upload-msg">
              Drag CSV file to upload the data,
            </span>
            <div className="or-wrapper">
              <hr className="or-line" />
              <span className="or-span">OR</span>
              <hr className="or-line" />
            </div>
            <Button className="browse-btn" style={{ zIndex: 0 }}>
              <span className="btn-text">Browse</span>
            </Button>
          </Dragger>
          <div className="bulk-reminder-wrapper">
            <CheckBox
              checkboxClass="survey-checkbox"
              onClick={() => {
                setchk(!chk)
              }}
            >
              <span className="reminder-text">Send Reminders ?</span>
              <img src={Doubt} alt="question" className="reminder-img" />
            </CheckBox>
            <div className="survey-note-wrapper">
              <span className="survey-note-title">Please Note</span>
              <span className="survey-note-desc">
                User will receive an invite email to join to the particular
                Company
              </span>
            </div>
            <div className="survey-note-wrapper">
              <span className="survey-note-title">
                Check the sample CSV file:{' '}
              </span>
              <span className="survey-note-desc">
                Please create the fields of your csv file same as{' '}
                <a href="./assets/Sample/participant.csv" download>
                  sample
                </a>{' '}
                file to bulk upload the data
              </span>
            </div>
          </div>
        </div>
        <div className="button-group">
          <Button className="cancelButton">Cancel</Button>
          <Button type="primary" htmlType="submit" className="saveButton">
            Save
          </Button>
        </div>
      </Form>
    </div>
  )
}
interface Props {
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  dataIds: any
}
const SingleEmp: React.FC<Props> = ({ setDrawerVisible, dataIds }) => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [firstName, setFirstname] = useState('')
  const [lastName, setLastname] = useState('')
  const [chk, setchk] = useState(false)
  const [selectedRaters, setselectedRaters] = useState('')
  const [raterList, setRaterList] = useState<any>([])

  useEffect(() => {
    getAllRaters(dataIds.projectId).then((res2: any) => {
      const { data } = res2.data
      setRaterList(data)
    })
  }, [])

  const handleSubmit = (): void => {
    const data = {
      name,
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      chk,
      selected_raters: selectedRaters,
      projectId: dataIds.projectId,
      company: dataIds.companyId,
      employeeId: dataIds.employeeId,
    }
    addParticipant(data)
      .then((res: any) => {
        if (res.isAxiosError && res.response.status === 400) {
          notification.error({
            type: 'error',
            message: res.response.data.message,
          })
        } else {
          console.log(res)
          setname('')
          setemail('')
          setFirstname('')
          setLastname('')
          setphone('')
          setchk(false)
          setDrawerVisible(false)
          notification.open({
            message: 'Participant successfully Added',
          })
        }
      })
      .catch((e: any) => {
        notification.error({
          message: 'something goes worong',
        })
      })
  }
  return (
    <Form className="form-wrapper" onFinish={handleSubmit}>
      <div className="survey-inner-form">
        <div className="form-group">
          <label htmlFor="inputName4" className="form-label">
            User Name
            <span className="asterik">*</span>
          </label>
          <Form.Item
            name="name"
            initialValue={name}
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input
              onChange={(e: any) => setname(e.target.value)}
              placeholder="Please enter name"
            />
          </Form.Item>
        </div>
        <div className="form-group">
          <label htmlFor="inputName4" className="form-label">
            First Name
            <span className="asterik">*</span>
          </label>
          <Form.Item
            name="firstname"
            rules={[{ required: true, message: 'Please Enter First Name' }]}
          >
            <Input
              onChange={(e: any) => setFirstname(e.target.value)}
              defaultValue={firstName}
              placeholder="Please Enter First Name"
            />
          </Form.Item>
        </div>
        <div className="form-group">
          <label htmlFor="inputName4" className="form-label">
            Last Name
            <span className="asterik">*</span>
          </label>
          <Form.Item
            name="lastname"
            rules={[{ required: true, message: 'Please Enter Last Name' }]}
          >
            <Input
              onChange={(e: any) => setLastname(e.target.value)}
              defaultValue={lastName}
              placeholder="Please Enter Last Name"
            />
          </Form.Item>
        </div>
        <div className="form-group">
          <label htmlFor="inputEmail4" className="form-label">
            Email
            <span className="asterik">*</span>
          </label>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The email is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter E-mail!',
              },
            ]}
          >
            <Input
              onChange={(e: any) => setemail(e.target.value)}
              defaultValue={email}
              placeholder="Please enter E-mail!"
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Phone
              <span className="asterik">*</span>
            </label>
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: true,
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Enter Valid phone number',
                },
              ]}
            >
              <Input
                onChange={(e: any) => setphone(e.target.value)}
                defaultValue={phone}
                placeholder="Enter phone number"
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputRater" className="form-label">
            Select Raters
          </label>
          <Form.Item
            name="raters"
            rules={[{ required: true, message: 'Please Select Raters' }]}
          >
            <Select
              // onChange={(e: any) => getRaters(e)}
              onChange={(e: any) => setselectedRaters(e)}
              allowClear
              placeholder="Select Project"
            >
              {raterList.length > 0 &&
                raterList.map((category: any) =>
                  // eslint-disable-next-line eqeqeq
                  category.title != 'Self' ? (
                    <Option key={category._id} value={category._id}>
                      {category.title}
                    </Option>
                  ) : null,
                )}
            </Select>
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="col-md-12 reminder-wrapper">
            <CheckBox
              checkboxClass="survey-checkbox"
              onClick={() => {
                setchk(!chk)
              }}
            >
              <span className="reminder-text">Send Reminders ?</span>
              <img src={Doubt} alt="question" className="reminder-img" />
            </CheckBox>
          </div>
        </div>
        <div className="survey-note-wrapper">
          <span className="survey-note-title">Please Note</span>
          <span className="survey-note-desc">
            User will receive an invite email to join to the particular Company
          </span>
        </div>
      </div>
      <div className="button-group">
        <Button
          className="cancelButton"
          onClick={() => setDrawerVisible(false)}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="saveButton">
          Save
        </Button>
      </div>
    </Form>
  )
}

interface Props {
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  dataIds: any
}
export const ParticipantForm: React.FC<Props> = ({
  setDrawerVisible,
  dataIds,
}): JSX.Element => {
  const [radio, setRadio] = useState(1)
  return (
    <div className="survey-form">
      <div className="upper-form">
        <div className="img-container">
          <img src={Bulk} alt="dd" className="upper-img" />
          <img src={Plus} alt="plus" className="upper-img-plus ms-3 me-3" />
          <img src={Bulk} alt="bulk" className="upper-img-bulk" />
        </div>
        <div className="survey-radio-container">
          <Button
            className="survey-radio-item"
            onClick={() => {
              setRadio(1)
            }}
          >
            <span className="survey-radio-title">Single Rater</span>
          </Button>
          <Button
            className="survey-radio-item"
            onClick={() => {
              setRadio(2)
            }}
          >
            <span className="survey-radio-title">Bulk Upload</span>
          </Button>
        </div>
        <hr className="survey-stats-line" />
      </div>
      <div className="vh-100">
        {radio === 2 && (
          <BulkUpload setDrawerVisible={setDrawerVisible} dataIds={dataIds} />
        )}
        {radio === 1 && (
          <SingleEmp setDrawerVisible={setDrawerVisible} dataIds={dataIds} />
        )}
      </div>
    </div>
  )
}
