import React from 'react'
import { Image } from 'antd'
import './style/image.scss'

interface Props {
  alt: string
  src: string
  preview?: boolean
  imgClass?: string
  style?: React.CSSProperties
  // height: number
  // width: number
}

export const ImageComponent: React.FC<Props> = ({
  alt,
  src,
  preview,
  imgClass,
  style,
  // height,
  // width,
}): JSX.Element => {
  return (
    <Image
      src={src}
      alt={alt}
      preview={preview}
      style={style}
      className={imgClass}
    />
  )
}

ImageComponent.defaultProps = {
  preview: false,
  imgClass: 'img',
  style: { color: '#000' },
}
