export const list = [
  {
    id: 1,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empID: 50,
    surveys: 50,
    designation: 'Co worker',
  },
  {
    id: 2,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 30,
    designation: 'Co worker',
  },
  {
    id: 3,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 50,
    surveys: 75,
    designation: 'Co worker',
  },
  {
    id: 4,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 60,
    surveys: 25,
    designation: 'Co worker',
  },
  {
    id: 5,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 50,
    surveys: 50,
    designation: 'Co worker',
  },
  {
    id: 6,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 23,
    surveys: 35,
    designation: 'Co worker',
  },
  {
    id: 7,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 30,
    surveys: 20,
    designation: 'Co worker',
  },
  {
    id: 8,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 9,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 10,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 6,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 23,
    surveys: 35,
    designation: 'Co worker',
  },
  {
    id: 7,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 30,
    surveys: 20,
    designation: 'Co worker',
  },
  {
    id: 8,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 9,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 10,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 7,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 30,
    surveys: 20,
    designation: 'Co worker',
  },
  {
    id: 8,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 9,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
  {
    id: 10,
    empName: 'Work Place Survey ',
    company: 'Techytt PTY LTD',
    empId: 20,
    surveys: 10,
    designation: 'Co worker',
  },
]
