import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import './styles/card.scss'
import { Doughnut, defaults } from 'react-chartjs-2'
import { Dropdown } from 'react-bootstrap'
import {
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import buildingdark from '../../assets/images/buildingsgray.svg'
import user from '../../assets/images/users.svg'
import Dots from '../../assets/images/dots-light.svg'
import PopupConfirm from '../../assets/images/Group 9366.svg'
import { removeCompany } from '../../api/API'

defaults.animation = false
interface Props {
  id: string
  name: string
  image: string
  employees: number
  hr: string
  checkbox: boolean
  _id: string
  mode: any
  changeMode: any
  employeeType: any
  getCompanyList: any
  completedSurveyStatus: any
  notCompletedSurveyStatus: any
}
const data = {
  datasets: [
    {
      data: [80, 20],
      backgroundColor: ['#008174', 'transparent'],
      hoverOffset: 0,
    },
  ],
}
const LableChart: React.FC = () => {
  return <Doughnut data={data} />
}
export const Card: React.FC<Props> = ({
  id,
  name,
  image,
  employees,
  hr,
  checkbox,
  _id,
  mode,
  changeMode,
  employeeType,
  getCompanyList,
  completedSurveyStatus,
  notCompletedSurveyStatus,
}): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const chartFn = (completed: any, notCompleted: any): any => {
    return {
      datasets: [
        {
          data: [completed || 0, 100 - completed],
          backgroundColor: ['#008174', '#b2d9d5'],
          hoverOffset: 0,
        },
      ],
    }
  }

  const deleteCompany = (cid: string): void => {
    removeCompany(cid).then((res: any) => {
      setIsModalVisible(false)
      getCompanyList()
    })
  }

  return (
    <>
      <div
        className={
          mode
            ? 'activate-card-bg cursor-pointer card company-card mb-0'
            : 'deactivate-card-bg cursor-pointer card company-card mb-0'
        }
      >
        <div className="card-upper-wrapper">
          {/* <input type="checkbox" className="checkbox mt-2 ms-2" /> */}
          <span className="checkbox mt-2 ms-2" />
          <div className="company-card-ellipse">
            <Dropdown>
              <Dropdown.Toggle
                className="ellipse-menu ms-4 border-1"
                id="dropdown-basic"
              >
                <img src={Dots} alt="Menu" className="card-menu" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Link
                  to={{ pathname: `/company-detail/${_id}` }}
                  className="dropdown-item"
                >
                  <span className="pe-2">
                    <EditOutlined />
                  </span>
                  Update
                </Link>
                <Dropdown.Item onClick={() => setIsModalVisible(true)}>
                  <span className="pe-2">
                    <DeleteOutlined />
                  </span>
                  Delete
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeMode(_id, !mode)}>
                  <span className={mode ? 'deactivate-dot' : 'activate-dot'} />
                  {mode ? 'Deactivate' : 'Active'}
                </Dropdown.Item>
                {/* <Dropdown.Item>
                  <span className="pe-2">
                    <InboxOutlined />
                  </span>
                  Archive
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Link to={{ pathname: `/company-detail/${_id}` }} className="card-link">
          <div className="cardBody d-flex align-items-center pb-1 pt-1 ">
            <div className="card-dot-wrapper">
              <div className={mode ? 'card-greendot' : 'card-greydot'}>
                &nbsp;
              </div>
              <div className="card-chart-wrapper">
                <span>{Math.round(completedSurveyStatus) || 0}%</span>
                <div className="card-chart">
                  <Doughnut
                    data={chartFn(
                      Math.round(completedSurveyStatus),
                      Math.round(100 - completedSurveyStatus),
                    )}
                  />
                </div>
              </div>
              <img
                width="120px"
                height="120px"
                src={image}
                alt="logo"
                className="card-logo"
              />
            </div>
            <div className="card-text d-flex flex-column justify-content-start ms-3">
              <span
                className="w-75"
                style={{ textDecoration: mode ? 'none' : 'line-through' }}
              >
                {name}
              </span>
              <div className="d-flex mt-2">
                <div className="d-flex  align-items-center justify-content-center mt-2">
                  <img
                    src={buildingdark}
                    alt="building"
                    className="card-det-icon"
                  />
                  <span className="mb-0 ms-1 card-det-text">{id}</span>
                </div>
                <div className="d-flex  align-items-center justify-content-center ms-4 mr-2">
                  <img src={user} alt="user" className="card-det-icon" />
                  <span className="mb-0 ms-1 card-det-text">{employees}</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
        {/* <div className="d-flex w-100 justify-content-end">
          {employeeType && (
            <div className="hrcircle justify-content-center align-items-center d-flex">
              <span className="hrtext mb-0">{employeeType}</span>
            </div>
          )}
        </div> */}
      </div>

      <Modal
        visible={isModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">
            Are you sure you want to remove this company? This action cannot
            undone and all the data associated with this company will be removed
            too.
          </p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => deleteCompany(_id)}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Card
