export const storeProjectInfoAction = (data) => (dispatch) => {
  return dispatch({
    type: 'STORE_PROJECT_INFO',
    payload: data,
  })
}

export const AddProjectInfoAction = (data) => (dispatch) => {
  return dispatch({
    type: 'ADD_PROJECT_INFO',
    payload: data,
  })
}
