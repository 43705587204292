import React from 'react'
import { Form } from 'antd'

interface Props {
  children: React.ReactNode
}

export const FormComponent: React.FC<Props> = ({ children }): JSX.Element => {
  return <Form>{children}</Form>
}
