/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
// import { Button } from 'react-bootstrap'
import { notification, Form, Input, Button, Upload, Select, Spin } from 'antd'
import './style/surveyForm.scss'
import CheckBox from '../../../components/Checkbox'
import Doubt from '../../../assets/images/users.svg'
import FileIcon from '../../../assets/images/FileIcon.png'
import {
  addParticipant,
  bulkParticipant,
  getAllRaters,
  getMyProjectEmployeeLists,
  currentEmployeeDetail,
} from '../../../api/API'
import Plus from '../../../assets/images/plus.svg'
import Bulk from '../../../assets/images/bulk.svg'
import { readCookie } from '../../../api/CookieScript'
import {
  COMPANY_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
} from '../../../constants/index'

const { Dragger } = Upload
const { Option } = Select

interface dProps {
  project: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getProjectDetailFn: any
}
export const BulkUpload: React.FC<dProps> = ({
  project,
  setDrawerVisible,
  getProjectDetailFn,
}): JSX.Element => {
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState(project)
  const [file, setFile] = useState<any>()
  const [chk, setchk] = useState(false)
  // const [raterList, setRaterList] = useState([])
  const [selectedRaters, setselectedRaters] = useState('')
  // const [employeeList, setEmployeeList] = useState<any>([])
  const [employeeId, setEmployeeId] = useState<any>([])

  // useEffect(() => {
  //   getAllRaters(project._id).then((res: any) => {
  //     setRaterList(res.data.data)
  //   })

  //   getMyProjectEmployeeLists(project._id).then((res: any) => {
  //     setEmployeeList(res.data.data)
  //   })
  // }, [])

  const props = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    beforeUpload: async (files: any) => {
      setFile(files)
      // Prevent upload
      return false
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        notification.success({
          message: `${info.file.name} file uploaded successfully`,
        })
      } else if (status === 'error') {
        notification.error({
          message: `${info.file.name} file upload failed.`,
        })
      }
    },
    onDrop(e: any) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const addFile = (e: any): void => {
    setFile(e[0])
  }
  const handleBulkSubmit = (): void => {
    setLoader(true)
    const projectId = data._id
    const formData = new FormData()
    // formData.append('project', projectId)
    formData.append('companyId', data.company)
    formData.append('projectId', projectId)
    // formData.append('employeeId', employeeId)
    // formData.append('selected_raters', selectedRaters)
    formData.append('file', file)
    bulkParticipant(formData)
      .then((res: any) => {
        console.log(res)
        setDrawerVisible(false)
        notification.success({
          message: 'Participant successfully Added',
        })
        getProjectDetailFn(projectId)
        setLoader(false)
      })
      .catch((e: any) => {
        notification.error({
          message: 'something goes worong',
        })
        setLoader(false)
      })
  }
  return (
    <div className="bulk-upload-wrapper">
      <Spin spinning={loader}>
        <Form className="form-wrapper" onFinish={handleBulkSubmit}>
          <div className="bulk-upload1">
            <Dragger {...props} className="file-upload-container">
              <img src={FileIcon} alt="fileIcon" className="file-upload-img" />
              <span className="file-upload-msg">
                Drag CSV file to upload the data,
              </span>
              <div className="or-wrapper">
                <hr className="or-line" />
                <span className="or-span">OR</span>
                <hr className="or-line" />
              </div>
              <Button className="browse-btn" style={{ zIndex: 0 }}>
                <span className="btn-text">Browse</span>
              </Button>
            </Dragger>
            {/* <div className="form-group col-md-12">
              <label htmlFor="inputRater" className="form-label">
                Select Raters
                <span className="asterik">*</span>
              </label>
              <Form.Item
                name="raters"
                rules={[{ required: true, message: 'Please Select Raters' }]}
              >
                <Select
                  // onChange={(e: any) => getRaters(e)}
                  onChange={(e: any) => setselectedRaters(e)}
                  allowClear
                  placeholder="Select Project"
                >
                  {raterList.length > 0 &&
                    raterList.map((category: any) => (
                      <Option key={category._id} value={category._id}>
                        {category.title}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div> */}
            {/* <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="inputPhoneNumber" className="form-label">
                  Employee
                  <span className="asterik">*</span>
                </label>
                <Form.Item
                  name="ReferUser"
                  rules={[{ required: true, message: 'Please Select Employee' }]}
                >
                  <Select
                    onChange={(e: any) => setEmployeeId(e)}
                    allowClear
                    placeholder="Select Project"
                  >
                    {employeeList &&
                      employeeList.map((emp: any) => (
                        <Option value={emp._id} key={emp._id}>
                          {emp.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div> */}
            <div className="bulk-reminder-wrapper">
              {/* <CheckBox
                checkboxClass="survey-checkbox"
                onClick={() => {
                  setchk(!chk)
                }}
              >
                <span className="reminder-text">Send Reminders ?</span>
                <img src={Doubt} alt="question" className="reminder-img" />
              </CheckBox> */}
              <div className="survey-note-wrapper">
                <span className="survey-note-title">Please Note</span>
                <span className="survey-note-desc">
                  User will receive an invite email to join to the particular
                  Company
                </span>
              </div>
              <div className="survey-note-wrapper">
                <span className="survey-note-title">
                  Check the sample CSV file:{' '}
                </span>
                <span className="survey-note-desc">
                  Please create the fields of your csv file same as{' '}
                  <a href="/assets/Sample/participant.csv" download>
                    sample
                  </a>{' '}
                  file to bulk upload the data
                </span>
              </div>
            </div>
          </div>
          <div className="button-group">
            <Button
              className="cancelButton"
              onClick={() => setDrawerVisible(false)}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="saveButton">
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
interface Props {
  project: any
  participantDrawerVisible: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getProjectDetailFn: any
}
const SingleEmp: React.FC<Props> = ({
  project,
  setDrawerVisible,
  participantDrawerVisible,
  getProjectDetailFn,
}) => {
  // const [emp, setemp] = useState()
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [firstName, setFirstname] = useState('')
  const [lastName, setLastname] = useState('')
  const [referralEmail, setreferralEmail] = useState('')
  // const [chk, setchk] = useState(false)
  const [raterList, setRaterList] = useState([])

  const [selectedRaters, setselectedRaters] = useState('')
  const [employeeList, setEmployeeList] = useState<any>([])
  const [employeeId, setEmployeeId] = useState<any>([])
  const [isRaterAvailable, setIsRaterAvailable] = useState(false)
  const [rerenderState, setRerenderState] = useState(Math.random())
  const [form] = Form.useForm()

  // const [empId, setEmpId] = useState<any>()

  // clear all input when drawer open
  useEffect(() => {
    setname('')
    setemail('')
    setphone('')
    setFirstname('')
    setLastname('')
    setselectedRaters('')
    setTimeout(() => {
      setRerenderState(Math.random())
    }, 100)
  }, [participantDrawerVisible])

  useEffect(() => {
    getAllRaters(project._id).then((res: any) => {
      const { data } = res.data
      if (data.length > 0) {
        setIsRaterAvailable(true)
      } else {
        setIsRaterAvailable(false)
      }
      setRaterList(data)
    })

    getMyProjectEmployeeLists(project._id).then((res: any) => {
      setEmployeeList(res.data.data)
    })

    if (readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE) {
      // get employee id of current login user
      currentEmployeeDetail().then((res: any) => {
        // setEmpId(res.data.data._id)
        setEmployeeId(res.data.data._id)
      })
    }
    setTimeout(() => {
      setRerenderState(Math.random())
    }, 100)
  }, [])

  const onReset = (): void => {
    form.resetFields()
  }

  const handleSubmit = (): void => {
    // eslint-disable-next-line no-underscore-dangle
    const projectId = project._id
    const data = {
      name,
      email,
      phone,
      first_name: firstName,
      last_name: lastName,
      // chk,
      // referralEmail,
      selected_raters: selectedRaters,
      projectId,
      company: project.company,
      employeeId,
      // employeeId: empId,
    }
    // console.log(data)
    setname('')
    setemail('')
    setRerenderState(Math.random())
    setphone('')
    setFirstname('')
    setLastname('')
    setselectedRaters('')
    if (readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE) {
      setEmployeeList([])
    }
    // setchk(false)
    setDrawerVisible(false)

    addParticipant(data)
      .then((res: any) => {
        if (res.isAxiosError && res.response.status === 400) {
          notification.error({
            type: 'error',
            message: res.response.data.message,
          })
        } else {
          setname('')
          setemail('')
          setphone('')
          setFirstname('')
          setLastname('')
          setselectedRaters('')
          if (readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE) {
            setEmployeeList([])
          }
          // setchk(false)
          onReset()
          setDrawerVisible(false)
          notification.success({
            message: 'Participant successfully Added',
          })
          getProjectDetailFn(projectId)
        }
      })
      .catch((e: any) => {
        notification.error({
          message: 'something goes wrong',
        })
      })
  }
  return (
    <Form
      form={form}
      className="form-wrapper"
      onFinish={handleSubmit}
      key={rerenderState}
    >
      <div className="survey-inner-form">
        <div className="form-group">
          <label htmlFor="inputName4" className="form-label">
            Name
            <span className="asterik">*</span>
          </label>
          {/* <input
            type="name"
            className="form-control"
            id="inputName4"
            placeholder="Please enter full name"
            onChange={(e: any) => setname(e.target.value)}
          /> */}
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input
              onChange={(e: any) => setname(e.target.value)}
              placeholder="Please enter name"
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="form-group col col-md-6 me-1">
            <label htmlFor="inputName4" className="form-label">
              First Name
              <span className="asterik">*</span>
            </label>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'Please enter first name' }]}
            >
              <Input
                onChange={(e: any) => setFirstname(e.target.value)}
                placeholder="Please enter first name"
                value={firstName}
              />
            </Form.Item>
          </div>
          <div className="form-group col col-md-6">
            <label htmlFor="inputName4" className="form-label">
              Last Name
              <span className="asterik">*</span>
            </label>
            <Form.Item
              name="last_name"
              rules={[{ required: true, message: 'Please enter last name' }]}
            >
              <Input
                onChange={(e: any) => setLastname(e.target.value)}
                placeholder="Please enter last name"
                value={lastName}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputEmail4" className="form-label">
            Email
            <span className="asterik">*</span>
          </label>
          {/* <input
            type="name"
            className="form-control"
            id="inputEmail4"
            placeholder="Enter email"
            onChange={(e: any) => setemail(e.target.value)}
          /> */}
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The email is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter E-mail!',
              },
            ]}
          >
            <Input
              onChange={(e: any) => setemail(e.target.value)}
              placeholder="Please enter E-mail!"
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="form-group col col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Phone
              {/* <span className="asterik">*</span> */}
            </label>
            {/* <input
              type="name"
              className="form-control"
              id="inputPhoneNumber"
              placeholder="Enter phone number"
              onChange={(e: any) => setphone(e.target.value)}
            /> */}
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: false,
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Enter Valid phone number',
                },
              ]}
            >
              <Input
                onChange={(e: any) => setphone(e.target.value)}
                placeholder="Enter phone number"
              />
            </Form.Item>
          </div>
        </div>
        {/* if rater is not available it will display below error */}
        <div className="form-group">
          <label htmlFor="inputRater" className="form-label">
            Select Raters
            <span className="asterik">*</span>
          </label>
          <Form.Item
            name="raters"
            rules={[{ required: true, message: 'Please Select Raters' }]}
          >
            <Select
              // onChange={(e: any) => getRaters(e)}
              onChange={(e: any) => setselectedRaters(e)}
              allowClear
              placeholder="Select Raters"
              // 628dafae485e0ff88d0391d5
            >
              {raterList.length > 0 &&
                raterList.map((category: any) =>
                  // eslint-disable-next-line eqeqeq
                  category.title != 'Self' ? (
                    <Option key={category._id} value={category._id}>
                      {category.title}
                    </Option>
                  ) : null,
                )}
            </Select>
          </Form.Item>
          {!isRaterAvailable && (
            <p>
              <b className="text-danger">Please Insert Rater First</b>
            </p>
          )}
        </div>

        {/* <div className="form-group">
          <label htmlFor="inputReferEmail" className="form-label">
            ReferEmail
          </label>
          <Form.Item name="Referemail">
            <Input
              onChange={(e: any) => setreferralEmail(e.target.value)}
              placeholder="Please enter E-mail!"
            />
          </Form.Item>
        </div> */}
        {/* employee list only display to super admin and company */}
        {/* if employee is login then directly pass employee id to api */}
        {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
          readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
          <div className="form-row">
            <div className="form-group col  col-md-12">
              <label htmlFor="inputPhoneNumber" className="form-label">
                Employee
                <span className="asterik">*</span>
              </label>
              <Form.Item
                name="employee"
                rules={[{ required: true, message: 'Please Select Employee' }]}
              >
                {/* <p>{empId}</p> */}
                <Select
                  onChange={(e: any) => setEmployeeId(e)}
                  allowClear
                  placeholder="Select Employee"
                >
                  {employeeList &&
                    employeeList.map((emp: any) => (
                      <Option value={emp._id} key={emp._id}>
                        {emp.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        )}
        {/* <div className="form-row">
          <div className="col-md-12 reminder-wrapper">
            <CheckBox
              checkboxClass="survey-checkbox"
              onClick={() => {
                setchk(!chk)
              }}
            >
              <span className="reminder-text">Send Reminders ?</span>
              <img src={Doubt} alt="question" className="reminder-img" />
            </CheckBox>
          </div>
        </div> */}
        <div className="survey-note-wrapper">
          <span className="survey-note-title">Please Note</span>
          <span className="survey-note-desc">
            User will receive an invite email to join to the particular Company
          </span>
        </div>
      </div>
      <div className="button-group">
        <Button
          className="cancelButton"
          onClick={() => {
            onReset()
            setDrawerVisible(false)
          }}
        >
          Cancel
        </Button>
        {!isRaterAvailable ? (
          <Button type="primary" disabled className="saveButton border-0">
            Save
          </Button>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            className="saveButton border-0"
          >
            Save
          </Button>
        )}
      </div>
    </Form>
  )
}

interface Props {
  project: any
  participantDrawerVisible: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getProjectDetailFn: any
}
export const ParticipantForm: React.FC<Props> = ({
  project,
  setDrawerVisible,
  participantDrawerVisible,
  getProjectDetailFn,
}): JSX.Element => {
  const [radio, setRadio] = useState(1)
  // state for active tab bottom border
  const [singleTab, setSingleTab] = useState(true)
  const [bulkTab, setBulkTab] = useState(false)
  return (
    <div className="survey-form">
      <div className="upper-form">
        <div className="img-container">
          <img
            src={project.company.logo || Bulk}
            alt="dd"
            className="upper-img"
          />
          <img src={Plus} alt="plus" className="upper-img-plus ms-3 me-3" />
          <img src={Bulk} alt="bulk" className="upper-img-bulk" />
        </div>
        <div className="survey-radio-container">
          <Button
            className="survey-radio-item"
            id={singleTab ? 'active-tab-border' : 'deactive-tab-border'}
            onClick={() => {
              setRadio(1)
              setSingleTab(true)
              setBulkTab(false)
            }}
          >
            <span className="survey-radio-title">Single Rater</span>
          </Button>
          <Button
            className="survey-radio-item"
            id={bulkTab ? 'active-tab-border' : 'deactive-tab-border'}
            onClick={() => {
              setRadio(2)
              setSingleTab(false)
              setBulkTab(true)
            }}
          >
            <span className="survey-radio-title">Bulk Upload</span>
          </Button>
        </div>
        <hr className="survey-stats-line" />
      </div>
      <div className="vh-100">
        {radio === 2 && (
          <BulkUpload
            project={project}
            setDrawerVisible={setDrawerVisible}
            getProjectDetailFn={getProjectDetailFn}
          />
        )}
        {radio === 1 && (
          <SingleEmp
            project={project}
            participantDrawerVisible={participantDrawerVisible}
            setDrawerVisible={setDrawerVisible}
            getProjectDetailFn={getProjectDetailFn}
          />
        )}
      </div>
    </div>
  )
}
