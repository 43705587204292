/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Drawer } from 'antd'
import './style/participants.scss'
import { ParticipantForm } from './components/participantForm'
import { ParticipantList } from './components/participantList'
import { currentEmployeeDetail, getAllRaters } from '../../api/API'

export const Participants: React.FC = (): JSX.Element => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [drowerTitle, setDrowerTitle] = useState('Add User')
  const [dataIds, setDataIds] = useState({})

  useEffect(() => {
    currentEmployeeDetail().then((res: any) => {
      const { data } = res.data
      setDataIds({
        companyId: data.company,
        employeeId: data._id,
        projectId: data.project_id[0],
      })
    })
  }, [])

  const getParticipantDetail = (id: any): void => {
    setDrowerTitle('Edit User')
    setIsOpenDrawer(true)
  }

  return (
    <div className="min-85vh participant-role-user-list">
      <Row justify="space-between">
        <Col>
          <h3>Users</h3>
        </Col>
        <Col>
          <Button
            className="comman-button"
            onClick={() => setIsOpenDrawer(true)}
          >
            Add User
          </Button>
        </Col>
      </Row>
      <div className="table">
        <ParticipantList getParticipantDetail={getParticipantDetail} />
      </div>
      <Drawer
        title={drowerTitle}
        placement="right"
        visible={isOpenDrawer}
        onClose={() => {
          setIsOpenDrawer(false)
          setDrowerTitle('Add User')
        }}
      >
        <ParticipantForm setDrawerVisible={setIsOpenDrawer} dataIds={dataIds} />
      </Drawer>
    </div>
  )
}
