import React, { useState } from 'react'
import { Row, Col, Button, Tabs } from 'antd'
import {
  ContainerOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Detail } from './tabs/detail'
import { User } from './tabs/user'
import { Framwork } from './tabs/framwork'
import '../../../style/userDetail.scss'
import UsersIcon from '../../../../../assets/images/users.svg'
import ProfileIcon from '../../../../../assets/images/profile-icon.svg'
import Share from '../../../../../assets/images/Share.svg'
import MoreIcon from '../../../../../assets/images/more.svg'
import UserIcon from '../../../../../assets/images/Icon awesome-user-circle.svg'

const { TabPane } = Tabs

interface Props {
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getProjectsEmployee: any
  details: any
  participantList: any
  employeeId: any
  getDetail: any
  isEdit: any
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
}

export const UserDetail: React.FC<Props> = ({
  setDrawerVisible,
  getProjectsEmployee,
  details,
  participantList,
  employeeId,
  getDetail,
  isEdit,
  setIsEdit,
}): JSX.Element => {
  // const [isReadOnly, setIsReadOnly] = useState(!isEdit)
  return (
    <div className="mt-4 pt-0 user-popup-form-container">
      <Row className="p-3">
        <Col
          xs={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={details.image ? details.image : UserIcon}
            alt="not availabel"
            className="company-logo img-fluid"
          />
        </Col>
        <Col xs={16}>
          <Row justify="start" className="ps-2">
            <Col xs={12}>
              <b>{details.first_name}</b>
              <p className="p-1">
                <img src={ProfileIcon} alt="users" className="img-fluid" />
                &nbsp;&nbsp;{details.emp_id != null ? details.emp_id : '0'}
              </p>
            </Col>
            {/* <Col xs={6} sm={24} md={24} lg={24} xl={7}>
              <p>
                <ContainerOutlined />
                &nbsp;&nbsp;02
              </p>
            </Col> */}
            <Col xs={12} className="d-flex align-items-center">
              <p className="p-1 m-0">
                <img src={UsersIcon} alt="users" className="img-fluid" />
                &nbsp;&nbsp;
                {participantList.length > 0 ? participantList.length : 0}
              </p>
            </Col>
          </Row>
        </Col>
        {/* <Col xs={2} className="text-end"> */}
        {/* <Button icon={<img src={Share} alt="share" className="w-50" />} /> */}
        {/* </Col> */}
        <Col xs={2} className="text-end">
          <Dropdown className="p-0">
            <Dropdown.Toggle className="p-0 border-0">
              <img src={MoreIcon} alt="arrow" className="img-fluid" />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setIsEdit(false)}>
                  <EditOutlined
                    className="user-popup-edit-icon"
                    style={{ marginRight: '10px' }}
                  />
                  Edit
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Toggle>
          </Dropdown>
        </Col>
        <Col xs={2} className="text-center">
          <CloseOutlined
            onClick={() => setDrawerVisible(false)}
            style={{ fontSize: '26px', cursor: 'pointer', paddingTop: '2px' }}
          />
        </Col>
      </Row>
      <hr className="m-0" />
      <Tabs className="p-3" defaultActiveKey="1">
        <TabPane tab="Detail" key="1">
          <Detail
            getProjectsEmployee={getProjectsEmployee}
            data={details}
            isEdit={isEdit}
            setDrawerVisible={setDrawerVisible}
            setIsEdit={setIsEdit}
          />
        </TabPane>
        <TabPane tab="Participants" key="2">
          <User
            participantList={participantList}
            employeeId={employeeId}
            getDetail={getDetail}
          />
        </TabPane>
        <TabPane tab="Participation" key="3">
          <Framwork participantList={participantList} employeeId={employeeId} />
        </TabPane>
      </Tabs>
    </div>
  )
}
