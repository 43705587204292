/* eslint-disable camelcase */
// export const storeUserInfoAction = (user_info) => (dispatch) => {
//   return dispatch({
//     type: 'STORE_USER_INFO',
//     payload: user_info,
//   })
// }

export const storeUserInfoAction = (data) => (dispatch) => {
  return dispatch({
    type: 'STORE_USER_INFO',
    payload: data,
  })
}

export const AddCompanyInfoAction = (data) => (dispatch) => {
  return dispatch({
    type: 'ADD_COMPANY_INFO',
    payload: data,
  })
}
