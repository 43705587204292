/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import { Row, Col, Drawer, Spin } from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FilterBar } from './components/userFilterbar'
import { UserCard } from './components/userCard'
import { UserList } from './components/userlistdata'
import addCompany from '../../../../assets/images/addCompany.svg'
import { UserDextailForm } from './components/UserDetailForm'
import { UserListView } from './UserListView'
import { UserGrid } from './UserGrid'
import Image from '../../../../components/Image'
import Button from '../../../../components/Button'
import Label from '../../../../components/Label'
import {
  COMPANY,
  COMPANY_SUB_TITLE,
  CREATE_NEW,
  DO_NOT_HAVE_USER,
  ADD_NEW_COMPANY,
} from '../../../../constants'

import {
  getEmployeeDetail,
  filterCompanysEmployees,
  logout,
  getCompanysEmployees,
} from '../../../../api/API'

interface Props {
  company: any
}
export const User: React.FC<Props> = ({ company }): JSX.Element => {
  const history = useHistory()
  // const location = useLocation()
  const params = useParams<any>()
  // const [data, setData] = useState(company)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [list, setList] = useState(company.employees)
  // const [userList, setUserList] = useState(UserList)
  const [details, setDetails] = useState()
  const [participantList, setParticipantList] = useState()
  const [xl, setXl] = useState<number>(8)
  const [lg, setLg] = useState<number>(12)

  const [filterQuery, setFilterQuery] = useState<any>({})
  const [tempState, setTempState] = useState(false)
  const [viewType, setviewType] = useState('grid')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [EmployeeId, setEmployeeId] = useState('')

  const [isReadOnly, setIsReadOnly] = useState(true)

  useEffect(() => {
    getEmployee()
  }, [currentPage, company])

  const getEmployee = (): void => {
    setLoading(true)
    setTempState(false)
    const options = {
      page_no: currentPage,
      company: company._id,
    }
    getCompanysEmployees(options).then((res: any) => {
      setList(res.data.data.docs)
      setTotalPages(res.data.data.totalPages)
      setLoading(false)
      setTempState(true)
      setviewType('grid')
    })
  }

  const getDetail = (id: any, chkIsReadOnly: any): void => {
    if (chkIsReadOnly === 'true') {
      setIsReadOnly(true)
    } else {
      setIsReadOnly(false)
    }

    setEmployeeId(id)
    getEmployeeDetail(id).then((res: any) => {
      const { data } = res.data
      // console.log(data)
      setDetails(data.detail)
      setParticipantList(data.participant)
      setDrawerVisible(true)
      // eslint-disable-next-line no-console
      // console.log(data)
    })
  }
  const changeViewType = (type: string): any => {
    if (type === 'grid') {
      setviewType('grid')
      // setLg(12)
      // setXl(8)
    } else {
      // setLg(24)
      // setXl(12)
      setviewType('list')
    }
  }
  const filterUser = (key: string, value: any): void => {
    const existingQury = filterQuery

    if (key === 'name' && value.target.value !== '') {
      existingQury[key] = value.target.value
    }
    if (key === 'mode' && value !== 'all') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }

    if (key === 'company') {
      existingQury[key] = value
    }

    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }

    if (key === 'date_added' && value === null) {
      getEmployee()
      return
    }

    if (key === 'name' && value.target.value === '') {
      getEmployee()
      return
    }

    if (key === 'mode' && value === 'all') {
      getEmployee()
      return
    }
    setFilterQuery(existingQury)
    setTempState(false)
    filterCompanysEmployees(params.id, existingQury)
      .then((res: any): any => {
        setList(res.data.data.docs)
        setTempState(true)
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
        }
      })
  }
  return (
    <>
      <FilterBar
        changeViewType={changeViewType}
        filterProject={filterUser}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <hr />
      {list.length === 0 && (
        // <p className="text-center h-100">Projects Not Available</p>
        <div className="company-main">
          <div className="company-main-section">
            <Image
              src={addCompany}
              alt="Add Project"
              style={{ marginBottom: '20px', maxWidth: '100px' }}
            />
            <Label
              title={DO_NOT_HAVE_USER}
              style={{
                marginBottom: '20px',
                fontSize: '1rem',
                fontFamily: 'Calibri',
                color: '#b6becc',
                textAlign: 'center',
              }}
            />
            {/* <Button
              onClick={() => setDrawerVisible(true)}
              btnClass="create-project-btn"
            >
              <span className="material-icons">add</span>
              <span className="create-new-company">{CREATE_NEW}</span>
            </Button> */}
          </div>
        </div>
      )}
      {loading && <Spin className="spinner-code" />}
      {viewType === 'grid' && (
        <UserGrid
          userlist={list}
          getDetail={getDetail}
          getEmployee={getEmployee}
        />
      )}
      {viewType === 'list' && <UserListView data={list} />}
      <div className="drawer-wrapper">
        <Drawer
          closable={false}
          maskClosable
          visible={drawerVisible}
          width={window.innerWidth > 550 ? 465 : '100%'}
        >
          <UserDextailForm
            setDrawerVisible={setDrawerVisible}
            details={details}
            participantList={participantList}
            employeeId={EmployeeId}
            getDetail={getDetail}
            isReadOnly={isReadOnly}
            setIsReadOnly={setIsReadOnly}
          />
        </Drawer>
      </div>
    </>
  )
}
