import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  MoreOutlined,
  UserOutlined,
  ContainerOutlined,
} from '@ant-design/icons'
import './styles/userDrawerHeader.scss'
import Share from '../../../../../../../assets/images/Share.svg'
import Dots from '../../../../../../../assets/images/dots.svg'

export const DrawerHeader: React.FC = (): JSX.Element => {
  return (
    <div className="drawer-header-wrapper">
      <div className="drawer-item-left">
        <img
          src="https://i.ibb.co/54GxVMv/unnamed-1.jpg"
          alt="userimg"
          className="user-img"
        />
        <div className="drawer-title-details">
          <span className="drawer-user-name">Clark Kent</span>
          <div className="drawer-user-detail-wrapper">
            <div>
              <span className="user-id first-detail">
                <UserOutlined />
                &nbsp;&nbsp;TT005
              </span>
            </div>
            <div className="user-documentid-wrapper">
              <span className="user-id">
                <ContainerOutlined />
                &nbsp;&nbsp;02
              </span>
            </div>
            <div className="user-documentid-wrapper">
              <span className="user-id">
                <ContainerOutlined />
                &nbsp;&nbsp;23
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer-item-right">
        <Button className="share-btn">
          <img src={Share} alt="share" className="share-img" />
        </Button>
        <img src={Dots} className="menu" alt="" />
      </div>
    </div>
  )
}
