import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import { Details } from './tabs/details'
import { User } from './tabs/user'
import { ProjectList } from './tabs/projects'

const { TabPane } = Tabs
interface Props {
  company: any
  deleteProject: any
  changeMode: any
  setProjectDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
}
export const TabPanel: React.FC<Props> = ({
  company,
  deleteProject,
  changeMode,
  setProjectDrawerVisible,
}): JSX.Element => {
  const [data, setData] = useState(company)
  useEffect(() => {
    setData(company)
  })
  return (
    <div className="h-100">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Detail" key="1">
          {data && <Details company={data} />}
        </TabPane>
        <TabPane tab="Projects" key="2">
          <ProjectList
            company={data}
            deleteProject={deleteProject}
            changeMode={changeMode}
            setProjectDrawerVisible={setProjectDrawerVisible}
          />
        </TabPane>
        <TabPane tab="Users" key="3">
          <User company={data} />
        </TabPane>
      </Tabs>
    </div>
  )
}
