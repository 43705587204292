import React, { useState } from 'react'
import { Input, Select, DatePicker, Button, Row, Col, Drawer } from 'antd'
import {
  PicCenterOutlined,
  LeftOutlined,
  DoubleLeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import MoreImg from '../../../../../assets/images/more.svg'
import ResetImg from '../../../../../assets/images/reset.svg'
import GoArrow from '../../../../../assets/images/goArrow.svg'
import rightArrow from '../../../../../assets/images/rightArrow.svg'
import rightDoubleArrow from '../../../../../assets/images/right_double_arrow.svg'
import leftArrow from '../../../../../assets/images/leftArrow.svg'
import leftDoubleArrow from '../../../../../assets/images/left_double_arrow.svg'
import { readCookie } from '../../../../../api/CookieScript'
import {
  COMPANY_ROLE,
  SUPER_ADMIN_ROLE,
  ROLE_KEYWORD,
  EMPLOYEE_ROLE,
} from '../../../../../constants'

const { Option } = Select

interface Props {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: any
  totalPages: any
  filterData: any
  resetFilter: any
}

const FilterBar: React.FC<Props> = ({
  setCurrentPage,
  currentPage,
  totalPages,
  filterData,
  resetFilter,
}) => {
  const [isRemindVisible, setIsRemindVisible] = useState<boolean>(false)
  const dateFormat = 'YYYY/MM/DD'
  const [customPage, setCustomPage] = useState<any>(1)
  const [visible, setVisible] = useState(false)
  const showDrawer = (): any => {
    setVisible(true)
    setIsRemindVisible(true)
  }
  const onClose = (): any => {
    setVisible(false)
  }
  const handleNumberInput = (e: any): void => {
    const valueTemp = e.target.value
    if (/[0-9]/.test(valueTemp)) {
      setCustomPage(valueTemp <= totalPages ? valueTemp : 1)
    } else {
      setCustomPage('')
    }
  }

  return (
    <div className="data-analysis-filter-container mt-3 mobile-filterbar-drawer">
      <Row gutter={[8, 8]} justify="space-between">
        <Col
          xs={18}
          sm={24}
          md={12}
          lg={12}
          xl={5}
          className="position-relative"
        >
          <span className="floating-label-search font-smaller text-muted">
            Search
          </span>
          <Input
            placeholder="Search by Name, ID"
            id="search_input"
            onChange={(e: any) => {
              filterData('name', e)
            }}
          />
        </Col>
        {readCookie(ROLE_KEYWORD) !== EMPLOYEE_ROLE && (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={3}
            className="position-relative desk-mode"
          >
            <span className="floating-label-mode font-smaller text-muted">
              Mode
            </span>
            <Select
              defaultValue="active"
              style={{ width: '100%' }}
              onSelect={(e: any) => filterData('mode', e)}
            >
              <Option value="active">Active</Option>
              <Option value="deactive">Deactive</Option>
            </Select>
          </Col>
        )}

        <Col
          xs={24}
          sm={24}
          lg={5}
          md={8}
          xl={3}
          className="position-relative border-right-dotted desk-date"
        >
          <span className="floating-label-date font-smaller text-muted">
            By Date
          </span>
          <DatePicker
            format={dateFormat}
            className="w-100"
            onChange={(date, dateString) => filterData('date_added', date)}
          />
        </Col>
        {/* <span className="border-right-dotted" /> */}
        <Col
          xs={24}
          sm={24}
          md={2}
          lg={2}
          xl={2}
          className="position-relative desk-reset-filter"
        >
          {/* <div className="d-flex"> */}
          {/* <Button icon={<PicCenterOutlined />} className="page-label">
              Advace Filter
            </Button> */}
          <Button
            className="ms-2"
            onClick={() => resetFilter()}
            style={{
              padding: '8px 7px',
              backgroundColor: '#F6F9FB',
            }}
          >
            <img src={ResetImg} alt="reset" className="w-75" />
          </Button>
          {/* <Button
              className="ms-2"
              style={{
                padding: '4px 5px',
                backgroundColor: '#F6F9FB',
              }}
            >
              <img src={MoreImg} alt="menu" className="w-75" />
            </Button> */}
          {/* </div> */}
        </Col>
        {/* Mobile Data Part Start */}
        <Col xs={3} sm={10} md={13} lg={8} className="data_filter_btn">
          <Button id="data_filter_btn" onClick={showDrawer}>
            Filter
          </Button>
        </Col>
        <Drawer
          title="Filter"
          placement="right"
          height={279}
          getContainer={false}
          onClose={onClose}
          visible={isRemindVisible}
          closable={false}
          extra={
            <CloseOutlined
              style={{ float: 'right' }}
              onClick={() => setIsRemindVisible(false)}
            />
          }
        >
          <Row className="ps-2 pt-3">
            {readCookie(ROLE_KEYWORD) !== EMPLOYEE_ROLE && (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={3}
                className="position-relative"
              >
                <span className="floating-label-mode font-smaller text-muted">
                  Mode
                </span>
                <Select defaultValue="active" style={{ width: '100%' }}>
                  <Option value="active">Active</Option>
                  <Option value="deactive">Deactive</Option>
                </Select>
              </Col>
            )}

            {/* </Row>
          <Row justify="space-around" className="pt-3"> */}
            <Col
              xs={11}
              sm={12}
              md={6}
              className="position-relative border-right-dotted date-input ps-2"
            >
              <span className="floating-label-date font-smaller text-muted">
                By Date
              </span>
              {/* <DatePicker
                format={dateFormat}
                onChange={(e) => filterCompany('created_at', e)}
              /> */}
              <DatePicker
                // defaultValue={moment('01/01/2015', dateFormatList[0])}
                // format={dateFormatList}
                // onChange={(e: any) => filterCompany('createdAt', e)}
                style={{ width: '100%' }}
              />
            </Col>
            {/* <Col xs={6} sm={24} md={12} id="reset_btn"> */}
            <Col xs={4} sm={2} md={12}>
              <Button
                className=" mt-3"
                id="reset_btn"
                style={{
                  padding: '8px 6px',
                  backgroundColor: '#F6F9FB',
                  height: '40px',
                }}
                // onClick={() => {
                //   // resetFilter()
                // }}
              >
                <img src={ResetImg} alt="reset" className="w-75 " />
              </Button>
            </Col>
          </Row>
          <span id="dash-border" />
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              className="position-relative text-center"
            >
              <Button className="apply_btn float-end mt-3 me-2">Apply</Button>
            </Col>
          </Row>
        </Drawer>
        {/* Mobile data part over */}
        <Col xs={24} sm={24} md={14} lg={17} xl={11}>
          <div className="d-flex justify-content-end">
            <Button
              icon={<img src={leftArrow} className="w-25" alt="First" />}
              onClick={() => setCurrentPage(1)}
              className="rounded-start rounded-end-0"
            />
            <Button
              icon={
                <img src={leftDoubleArrow} className="w-50" alt="Previous" />
              }
              onClick={() =>
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
              }
              className="rounded-0"
            />
            <Button className="page-label rounded-0">
              Page {currentPage} of {totalPages}{' '}
            </Button>
            <Button
              icon={<img src={rightDoubleArrow} className="w-50" alt="Last" />}
              onClick={() =>
                setCurrentPage(currentPage < totalPages ? currentPage + 1 : 1)
              }
              className="rounded-0"
            />
            <Button
              icon={<img src={rightArrow} className="w-25" alt="Next" />}
              onClick={() => setCurrentPage(totalPages)}
              className="rounded-start-0 rounded-end"
            />
            <Input
              type="text"
              placeholder="Go to"
              className="goto-field ms-1"
              value={customPage}
              onChange={(e) => handleNumberInput(e)}
              // onChange={(e: any) =>
              //   setCustomPage(e.target.value <= totalPages ? e.target.value : 1)
              // }
            />
            <Button
              icon={<img src={GoArrow} alt="Go" className="w-50" />}
              className="btn-theme goto-btn ms-1"
              onClick={() => setCurrentPage(customPage)}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FilterBar
