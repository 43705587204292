import React from 'react'
import { Input } from 'antd'

interface Props {
  placeholder: any
  name: string
}

export const FreeText: React.FC<Props> = ({
  placeholder,
  name,
}): JSX.Element => {
  return (
    <div className="mt-2 mb-2">
      <Input placeholder={placeholder} name={name} />
    </div>
  )
}
