/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Label } from './Label'

interface Props {
  data: any
  block: any
  updateQuestionList: any
}

export const DropDown: React.FC<Props> = ({
  data,
  block,
  updateQuestionList,
}): JSX.Element => {
  return (
    <div className="mt-2 mb-2">
      {data.map((options: any, index: any) => {
        return (
          <Label
            placeholder={options.text}
            name={`checkbox_question_of_${index}_${block}`}
            updateQuestionList={updateQuestionList}
            forWhat="dropdown-options"
            currentLablePosition={index}
            value={options.text}
            key={options.value}
          />
        )
      })}
    </div>
  )
}
