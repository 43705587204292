/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Drawer,
  Modal,
  Select,
  notification,
  Popconfirm,
  Space,
  Spin,
  Input,
  Form,
  Tooltip,
} from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import { DropdownButton, Dropdown, Table } from 'react-bootstrap'
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  BulbOutlined,
  SaveOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons'
import { FocusArea } from './components/framework/FocusArea'
import { RaterCategory } from './components/framework/focusarealistdata'
import Ellipse from '../../../assets/images/ellipse.svg'
import modalIcon from '../../../assets/images/Group 9367.svg'

import plusIcon from '../../../assets/images/Icon awesome-plus.svg'
import { Chart } from './components/framework/Chart'
import { RateForm } from './components/framework/RateForm'
import { FocusAreaForm } from './components/framework/FocusAreaForm'
import {
  getAllFocusArea,
  getAllRaters,
  deleteRaters,
  createTemplateFocusArea,
  getAllFocusAreaTemplate,
  importTemplateFocusArea,
  getCompanyList,
  addChartImageApi,
  getProjectDetail,
  removeChartImage,
} from '../../../api/API'
import arrowDownWhite from '../../../assets/images/arrow-down-white.svg'
import { readCookie } from '../../../api/CookieScript'
import {
  COMPANY_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
} from '../../../constants/index'

const { Option } = Select

interface Props {
  projectDetail: any
}

export const FrameWork: React.FC<Props> = ({ projectDetail }) => {
  // const location = useLocation()
  const params = useParams<any>()
  const [ratersdrawerVisible, setRatersDrawerVisible] = useState(false)
  const [isFocusAreaFatched, setIsFocusAreaFatched] = useState(false)
  const [ratersEditMode, setRatersEditMode] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [templateNameDraft, setTemplateNameDraft] = useState('')
  const [raterlist, setRaterList] = useState<any>([])
  const [companyId, setCompanyId] = useState<any>(projectDetail.company)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectFromTempleteModal, setSelectFromTempleteModal] = useState(false)
  const [draftModalVisible, setDraftModalVisible] = useState(false)
  const [errorLable, setErrorLable] = useState('')
  const [errorLableindicator, setErrorLableindicator] = useState(false)
  const [templateData, setTemplateData] = useState([])
  const [focusAreaVisible, setFocusAreaVisible] = useState(false)
  const [FocusAreaData, setFocusAreaData] = useState([])
  const [selectedFocusarea, setSelectedFocusarea] = useState('')
  const [loading, setLoading] = useState(false)
  const [sliceWidth, setSliceWidth] = useState([
    100, 100, 100, 100, 100, 100, 100,
  ])
  const [colors, setColors] = useState([
    '#F0F2F4',
    '#F0F2F4',
    '#F0F2F4',
    '#F0F2F4',
    '#F0F2F4',
    '#F0F2F4',
    '#F0F2F4',
  ])
  const [icon, setIcon] = useState([
    'default',
    'default',
    'default',
    'default',
    'default',
    'default',
    'default',
  ])
  // const [sliceWidth, setSliceWidth] = useState<any>([])
  // const [colors, setColors] = useState<any>([])
  // const [icon, setIcon] = useState<any>([])

  const [selectedTemplateForApply, setSelectedTemplateForApply] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [constructPerFocus, setConstructPerFocus] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ])

  const [selectTemplatErrorLable, setSelectTemplatErrorLable] = useState('')

  const [listOfCompany, setListOfCompany] = useState([])
  const [templateLoading, setTemplateLoading] = useState(false)
  const [selcetTemplateData, setSelcetTemplateData] = useState('')

  const [priviewImg, setPriviewImg] = useState<any>()
  const [file, setfile] = useState<any>()
  const [ImageErrMsg, setImageErrMsg] = useState('')
  const [isImageUploaded, setIsImageUploaded] = useState(false)

  useEffect(() => {
    getFocusAreas()
    getRaters()
    getCompanyLists()
    get360Image()
  }, [])

  const getCompanyLists = (): void => {
    setTemplateLoading(true)
    getCompanyList().then((res: any) => {
      setListOfCompany(res.data.data.reverse())
      setTemplateLoading(false)
    })
  }

  const selcetTemplateOfCompany = (e: any): void => {
    setTemplateData([])

    setTemplateLoading(true)
    const { id } = params // project id
    const object = {
      projectid: id,
      company_id: e,
    }
    getAllFocusAreaTemplate(object).then((res: any) => {
      setTemplateData(res.data.data)
      setTemplateLoading(false)
    })
  }

  const getRaters = (): void => {
    getAllRaters(params.id).then((res: any) => {
      const { data } = res.data
      setRaterList(data)
    })
  }

  const removeRater = (id: any): void => {
    deleteRaters(id).then((res: any) => {
      getRaters()
    })
  }

  const getFocusAreas = (): void => {
    getAllFocusArea(params.id).then((res: any) => {
      const { data } = res.data
      const tmp2 = colors
      const tmp3 = icon
      const tmp4 = constructPerFocus
      // const tmp5 = sliceWidth
      for (let i = 0; i < data.length; i++) {
        tmp2[i] = data[i].color
        tmp3[i] = data[i].image
        tmp4[i] = data[i].constructs.length
        // tmp2.push(data[i].color)
        // tmp3.push(data[i].image)
        // tmp4.push(data[i].constructs.length)
        // tmp5.push(100)
      }
      // setSliceWidth(tmp5)
      setColors(tmp2)
      setIcon(tmp3)
      setConstructPerFocus(tmp4)
      setFocusAreaVisible(false)
      setFocusAreaData(data)
      setIsFocusAreaFatched(true)
    })
  }

  const addNewSlice = (): void => {
    setIsFocusAreaFatched(false)
    getFocusAreas()
    setSelectedFocusarea('')
  }

  const editRater = (id: any): void => {
    setRatersEditMode(id)
    setRatersDrawerVisible(true)
  }

  const templateCreate = (obj: any): void => {
    setErrorLable('')

    setTemplateNameDraft('')

    setDraftModalVisible(false)
    setErrorLableindicator(false)
    setLoading(true)
    createTemplateFocusArea(obj).then((res: any) => {
      setLoading(false)
      if (res.data.code === 200) {
        setTemplateName('')
        setIsModalVisible(false)
        notification.open({
          message: 'Focus area template successfully Added',
        })
      } else {
        setIsModalVisible(true)
        setErrorLableindicator(true)
        setErrorLable('Template name already exist')
      }
    })
  }

  const newTemplate = (status: any): void => {
    const { id } = params // project id
    if (templateName === '') {
      setErrorLable('Enter name !')
      setErrorLableindicator(true)
    } else {
      const obj = {
        project_id: id,
        name: templateName,
        status,
      }
      templateCreate(obj)
    }
  }

  const newTemplateDraft = (status: any): void => {
    const { id } = params // project id
    if (templateNameDraft === '') {
      setErrorLable('Enter name !')
      setErrorLableindicator(true)
    } else {
      const obj = {
        project_id: id,
        name: templateNameDraft,
        status,
      }
      templateCreate(obj)
    }
  }

  const getTempateData = (): void => {
    if (readCookie(ROLE_KEYWORD) === COMPANY_ROLE) {
      setTemplateData([])
      const { id } = params // project id
      const data = {
        projectid: id,
      }
      getAllFocusAreaTemplate(data).then((res: any) => {
        setTemplateData(res.data.data)
      })
    }
  }

  const applyFocusAreaTemplate = (): void => {
    setSelectTemplatErrorLable('')
    if (!selectedTemplateForApply) {
      setSelectTemplatErrorLable('Select Template')
    } else {
      const { id } = params // project id
      const object = {
        project_id: id,
        template_id: selectedTemplateForApply,
      }
      setConfirmLoading(true)
      setTemplateLoading(true)
      if (!confirmLoading) {
        importTemplateFocusArea(object).then((res: any) => {
          notification.success({
            message: 'Focus area template successfully Applyed',
          })
          setConfirmLoading(false)
          setSelectFromTempleteModal(false)
          addNewSlice()
          setTemplateLoading(false)
          // window.location.reload();
        })
      }
    }
  }

  // function to display an selected image
  const imagePreview = (e: any): any => {
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPriviewImg(objectUrl)
    setfile(e.target.files[0])
  }

  // upload 360chart image code
  const uploadChartImage = (): void => {
    setImageErrMsg('')
    const formData = new FormData()
    if (file) {
      setLoading(true)
      formData.append('projectID', params.id)
      formData.append('image', file)
      addChartImageApi(formData).then((res: any) => {
        if (res.status === 200) {
          setIsImageUploaded(true)
          notification.success({
            message: '360 Image Upload Successfully',
          })
        } else {
          notification.success({
            message: 'Try agian',
          })
        }
        setLoading(false)
      })
    } else {
      setImageErrMsg('Select Chart Image')
    }
  }

  // code to get the image of 360 chart
  const get360Image = (): void => {
    getProjectDetail(params.id).then((res: any): any => {
      const chartImage = res.data.data.chart_img
      if (chartImage) {
        setPriviewImg(chartImage)
        setIsImageUploaded(true)
      } else {
        setIsImageUploaded(false)
      }
    })
  }
  const RemoveChartImage = (): void => {
    removeChartImage(params.id).then((res: any): any => {
      const chartImage = res.data.data.chart_img
      if (chartImage) {
        setPriviewImg('')
        setIsImageUploaded(false)
      }
    })
  }

  return (
    <>
      <Spin spinning={loading} className="spinner-code">
        <div className="border framework-container">
          <Row
            className="p-3 justify-content-xl-around justify-content-md-between justify-content-sm-between"
            align="middle"
          >
            <Col xs={24} sm={24} md={12} lg={16} xl={12}>
              {/* <b>360 GM Framework</b>
              <p className="m-0"> This graph includes focus area and construct. </p> */}
            </Col>
            <Col xs={24} xl={12} sm={6} md={12} lg={8}>
              <div className="d-flex justify-content-lg-between justify-content-xl-end justify-content-md-between justify-content-center">
                <Button
                  className="btn ms-xs-0 ms-xl-3 me-sm-2 me-xl-3"
                  onClick={() => {
                    setSelectFromTempleteModal(true)
                    getTempateData()
                  }}
                >
                  Select from template
                </Button>

                <DropdownButton
                  className="comman-button btn-theme"
                  title={
                    <span>
                      Save as template
                      <img
                        src={arrowDownWhite}
                        alt="arrow"
                        className="arrow_down"
                      />
                    </span>
                  }
                >
                  {/* <Dropdown.Item href="#/action-1">Update Existing</Dropdown.Item> */}
                  <Dropdown.Item onClick={() => setIsModalVisible(true)}>
                    <span className="w-25 pe-1 me-2">
                      <SaveOutlined />
                    </span>
                    Save as New
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => setDraftModalVisible(true)}>
                    Save as Draft
                  </Dropdown.Item> */}
                </DropdownButton>
              </div>
            </Col>
          </Row>
          {/* <hr style={{ width: '98%', margin: '0 auto' }} /> */}
          <Row justify="space-around">
            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
              <div className="pt-3 pt-lg-0 mx-md-3 mx-xs-1 mt-2">
                <b>360 GM Framework</b>
                <p className="m-0">
                  This graph includes focus area and construct.
                </p>
              </div>
              <hr />
              {isFocusAreaFatched && (
                <Chart
                  setFocusAreaVisible={setFocusAreaVisible}
                  data={sliceWidth}
                  colors={colors}
                  icon={icon}
                  constructPerFocus={constructPerFocus}
                  setSelectedFocusarea={setSelectedFocusarea}
                  FocusAreaData={FocusAreaData}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={13} xl={7}>
              <Row className="pt-3 pt-lg-0 justify-content-around justify-content-md-between mx-md-3 mx-xs-1 mt-2">
                <Col xs={14} xl={12}>
                  <b>Focus Area and Construct</b>
                  <p className="mb-0">Add Focus Area and Construct</p>
                </Col>
                <Col xs={8} xl={12} className="text-end">
                  <Button
                    disabled={FocusAreaData.length > 6}
                    className="btn"
                    onClick={() => {
                      setSelectedFocusarea('')
                      setFocusAreaVisible(true)
                    }}
                  >
                    <img src={plusIcon} alt="plus Icon" className="pe-1" />
                    Add New
                  </Button>
                </Col>
              </Row>
              <hr />
              <Row className=" justify-content-around justify-content-md-center">
                {FocusAreaData.length === 0 && (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={11}
                    xl={11}
                    className="p-2 mt-2 mb-2 vertical-middle text-center cursor-pointer"
                    onClick={() => {
                      setFocusAreaVisible(true)
                    }}
                    style={{
                      border: '2px dashed #D9E0E4',
                      color: '#D9E0E4',
                      fontSize: '16px',
                    }}
                  >
                    <p className="mb-3 pb-0 mt-3">
                      Add focus area to begin with creating the focus area
                    </p>
                  </Col>
                )}
                {FocusAreaData.length > 0 &&
                  FocusAreaData.map((area: any) => (
                    <Col
                      xs={23}
                      sm={24}
                      md={23}
                      lg={24}
                      xl={24}
                      xxl={11}
                      className="border p-2 mt-2 mb-2 mx-md-2 vertical-middle focus-area-data-shadow"
                      key={area._id}
                      onClick={() => {
                        setSelectedFocusarea(area._id)
                        setFocusAreaVisible(true)
                      }}
                    >
                      <FocusArea data={area} />
                    </Col>
                  ))}
              </Row>
              <hr />
              <Row className="pt-1 pb-3 justify-content-between">
                <Col xs={14} xl={24}>
                  <div className="d-flex justify-content-between">
                    <b>
                      <h4>Upload FocusArea Image</h4>
                    </b>
                    <Tooltip
                      className="me-2 mt-1"
                      title="Upload 360 framework chart Image for Report PDF"
                    >
                      <QuestionCircleFilled />
                    </Tooltip>
                  </div>
                  <hr />
                </Col>
                <Col xs={14} xl={17} className="text-end mt-2 mb-2">
                  <Input type="file" onChange={(e) => imagePreview(e)} />
                  {!file && (
                    <p className="text-center text-danger">{ImageErrMsg}</p>
                  )}
                </Col>
                <Col xs={24} xl={5} className="text-center mt-2">
                  <Button
                    className="text-white border-0 pt-2 pb-2"
                    style={{ backgroundColor: '#008174' }}
                    onClick={() => uploadChartImage()}
                  >
                    Submit
                  </Button>
                </Col>
                <hr />
                <Col xs={24} xl={24} className="text-center">
                  {file && <img src={priviewImg || ''} alt="360" width={200} />}
                  {!file && (
                    <img src={priviewImg || ''} alt="360" width={200} />
                  )}
                </Col>
                {isImageUploaded && (
                  <Col xs={24} xl={24} className="text-center mt-2">
                    <Popconfirm
                      title="Are you sure to remove this image?"
                      onConfirm={RemoveChartImage}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="text-white btn-danger border-0 pt-2 pb-2">
                        Delete
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={23}
              lg={10}
              xl={5}
              className="ms-2 rater-section"
            >
              <Row className="ps-1 pm-3 pt-3 pt-lg-0 justify-content-xl-between justify-content-around mt-2">
                <Col xs={16} sm={16} lg={17} md={20} xl={16} xxl={16}>
                  <b>Raters</b>
                  <p className="mb-0">Add rater category</p>
                </Col>
                <Col xs={8} sm={8} md={4} lg={7} xl={8} xxl={8}>
                  <Button
                    className="btn"
                    onClick={() => {
                      setRatersEditMode('')
                      setRatersDrawerVisible(true)
                    }}
                  >
                    <img src={plusIcon} alt="plus Icon" className="pe-2" />
                    Add New
                  </Button>
                </Col>
              </Row>
              <hr />
              <Table striped hover className="raters-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Color</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {raterlist.length > 0 &&
                    raterlist.map((category: any) => (
                      <tr key={category._id}>
                        <td className="text-primary">{category.title}</td>
                        <td>
                          <div
                            style={{
                              height: '10px',
                              width: '10px',
                              marginLeft: '10px',
                              backgroundColor: category.color,
                            }}
                          />
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              className="ellipse-menu border-1"
                              id="dropdown-basic"
                            >
                              <img
                                src={Ellipse}
                                alt="menu"
                                className="ellipse-img"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => editRater(category._id)}
                              >
                                <EditOutlined className="me-2" />
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => removeRater(category._id)}
                              >
                                <DeleteOutlined className="me-2" />
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr style={{ width: '98%', margin: '0 auto' }} />
          <div className="p-3">
            <BulbOutlined />
            You can add upto 7 focus area by clicking on add new button
          </div>
          <div className="drawer-wrapper">
            <Drawer
              onClose={() => {
                setRatersDrawerVisible(false)
              }}
              visible={ratersdrawerVisible}
              title="Raters"
              width={window.innerWidth > 550 ? 465 : '100%'}
            >
              <RateForm
                setRatersDrawerVisible={setRatersDrawerVisible}
                getRaters={getRaters}
                ratersEditMode={ratersEditMode}
                companyId={companyId}
                ratersdrawerVisible={ratersdrawerVisible}
              />
            </Drawer>
            {/* Focus Area Form */}
            <Drawer
              onClose={() => {
                setFocusAreaVisible(false)
              }}
              visible={focusAreaVisible}
              title="Focus Area"
              width={window.innerWidth > 550 ? 465 : '100%'}
            >
              <FocusAreaForm
                addNewSlice={addNewSlice}
                selectedFocusarea={selectedFocusarea}
                focusAreaVisible={focusAreaVisible}
              />
            </Drawer>
          </div>

          {/* Select from templet  */}
          <Modal
            visible={selectFromTempleteModal}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <Spin spinning={templateLoading} className="spinner-code">
              <div className="text-center">
                <img src={modalIcon} alt="Confirm" />
                <p className="mt-3 mb-3 fw-bold">Import FocusArea Template</p>
                {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
                  <Select
                    className="mb-3"
                    style={{ width: 300 }}
                    onChange={(e: any) => {
                      selcetTemplateOfCompany(e)
                      setSelcetTemplateData('')
                    }}
                    placeholder="Select Company"
                  >
                    {listOfCompany.map((company: any) => (
                      <Option value={company._id}>{company.name}</Option>
                    ))}
                  </Select>
                )}

                <Select
                  className="mb-3"
                  style={{ width: '300px' }}
                  onChange={(e: any) => {
                    setSelectedTemplateForApply(e)
                    setSelcetTemplateData(e)
                  }}
                  defaultValue={selcetTemplateData}
                  placeholder="Select Template"
                  key={selcetTemplateData}
                >
                  {templateData &&
                    templateData.map((tempatates: any) => (
                      <Option value={tempatates._id} key={tempatates._id}>
                        {tempatates.name}
                      </Option>
                    ))}
                </Select>
                {selectTemplatErrorLable !== '' && (
                  <p className="text-danger">{selectTemplatErrorLable}</p>
                )}
                <div className="d-flex w-100 justify-content-center">
                  <Button
                    className="ghost bg-transparent border-rounded cancelButton"
                    onClick={() => {
                      setSelectFromTempleteModal(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Popconfirm
                    title={
                      <div>
                        Are you sure?
                        <br /> you want to apply this template
                        <br /> beacuse this will replace old data
                        <br />
                        <b className="text-danger">
                          you have to reassign User/Participant designation
                          manually
                        </b>
                      </div>
                    }
                    okText="Yes"
                    onConfirm={applyFocusAreaTemplate}
                    cancelText="No"
                  >
                    <Button className="bg-theme-save-framwork-popup ms-2 saveButton">
                      Apply
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </Spin>
          </Modal>

          {/* save as templet  */}
          <Modal
            visible={isModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <div className="text-center">
              <img src={modalIcon} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">Save this New template</p>
              <input
                type="text"
                placeholder="Name of Template"
                className="mb-3 w-75 templateName"
                value={templateName}
                onChange={(e: any) => setTemplateName(e.target.value)}
              />
              {errorLableindicator && (
                <p style={{ color: 'red' }}>{errorLable}</p>
              )}
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="ghost bg-transparent border-rounded cancelButton"
                  onClick={() => {
                    setErrorLable('')
                    setTemplateName('')
                    setIsModalVisible(false)
                    setErrorLableindicator(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2 saveButton"
                  onClick={() => {
                    newTemplate('publish')
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
          {/* draft modal  */}
          <Modal
            visible={draftModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <div className="text-center">
              <img src={modalIcon} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">Save as Draft</p>
              <input
                type="text"
                placeholder="Name of Draft"
                className="mb-3 w-75 templateName"
                value={templateNameDraft}
                onChange={(e: any) => setTemplateNameDraft(e.target.value)}
              />
              {errorLableindicator && (
                <p style={{ color: 'red' }}>{errorLable}</p>
              )}

              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="ghost bg-transparent border-rounded cancelButton"
                  onClick={() => {
                    setErrorLable('')
                    setDraftModalVisible(false)
                    setTemplateNameDraft('')
                    setErrorLableindicator(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2 saveButton"
                  onClick={() => {
                    newTemplateDraft('draft')
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    </>
  )
}
