import { Table, Button } from 'antd'
import { useState } from 'react'
// import { Doughnut, defaults } from 'react-chartjs-2'
import logo from '../../../assets/images/logo.png'
import avatar from '../../../assets/images/Icon awesome-user-circle.svg'

const icon = {
  datasets: [
    {
      data: [80, 20],
      backgroundColor: ['#008174', 'transparent'],
      hoverOffset: 0,
    },
  ],
}

const columns = [
  {
    dataIndex: 'image',
    key: 'image',
    render: (text: any, data: any) => (
      <div className="d-flex justify-content-around align-items-center">
        <div
          className={
            data.status ? 'user-active-circle' : 'user-deactive-circle'
          }
        />
        <img
          height="50px"
          width="50px"
          src={data.image ? data.image : avatar}
          alt={data.name}
          className="listview-image ms-2"
        />
      </div>
    ),
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    render: (text: any, data: any) => (
      <p className="mb-0">
        <span style={{ textDecoration: data.status ? 'none' : 'line-through' }}>
          {data.name}
        </span>
      </p>
    ),
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    render: (text: any, data: any) => <p className="mb-0">{data.last_name}</p>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text: any, data: any) => <p className="mb-0">{data.email}</p>,
  },
  {
    title: 'Employee Id',
    dataIndex: 'emp_id',
    render: (text: any, data: any) => <p className="mb-0">{data.emp_id}</p>,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    render: (text: any, data: any) => <p className="mb-0">{data.phone}</p>,
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    render: (text: any, data: any) => (
      <p className="mb-0">{data.created_at.substring(0, 10)}</p>
    ),
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    render: (text: any, data: any) => (
      <p className="mb-0">{data.updatedAt.substring(0, 10)}</p>
    ),
  },
]

interface Props {
  userlist: any
  getDetail: any
}

export const UserListView: React.FC<Props> = ({ userlist, getDetail }) => {
  const [state, setState] = useState({ selectedRowKeys: [] })
  const onSelectChange = (selectedRowKeys: any): any => {
    setState({ selectedRowKeys })
  }

  const { selectedRowKeys } = state
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys: any): any => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter(
            (key: any, index: any) => {
              if (index % 2 !== 0) {
                return false
              }
              return true
            },
          )
          setState({ selectedRowKeys: newSelectedRowKeys })
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys: any): any => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter(
            (key: any, index: any) => {
              if (index % 2 !== 0) {
                return true
              }
              return false
            },
          )
          setState({ selectedRowKeys: newSelectedRowKeys })
        },
      },
    ],
  }

  return (
    <Table
      // rowSelection={rowSelection}
      columns={columns}
      dataSource={userlist}
      pagination={false}
      rowKey="id"
    />
  )
}
