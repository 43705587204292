import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'antd'
import { readCookie, eraseCookie } from '../../api/CookieScript'
import Header from '../../components/Header'
import CardComp from '../../components/CardComp/CardComp'
import { Project } from './Project/Project'
import Stats from './Stats/Stats'
import './style/dashboard.scss'
import { DashBoardHeaderApi } from '../../api/API'

export const Dashboard: React.FC = (): JSX.Element => {
  const history = useHistory()
  const controller = new AbortController()

  // dashboard card data state
  const [DashboardHeader, setDashboardHeader] = useState('')
  const getDashBoardData = (): any => {
    DashBoardHeaderApi(controller).then((res: any): any => {
      setDashboardHeader(res.data.data)
    })
  }

  useEffect(() => {
    const isTokenAvailable = readCookie('gm360_token')
    if (!isTokenAvailable) {
      history.push('/login')
    }
    getDashBoardData()

    return () => controller.abort()
  }, [])

  return (
    <div className="min-85vh">
      <Header />
      <CardComp dashboarddata={DashboardHeader} />
      <Row className="middle-dash-wrapper">
        <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
          <Project controller={controller} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={10}
          xxl={10}
          className="State_part"
        >
          <Stats controller={controller} />
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard
