import React from 'react'
import RaterChart from '../Chart/RaterChart'
import EmployeeChart from '../Chart/EmployeeChart'

interface Props {
  participantList: any
  employeeId: any
  details: any
}

export const FrameworkTab: React.FC<Props> = ({
  participantList,
  employeeId,
  details,
}): JSX.Element => {
  // export const FrameworkTab: React.FC = (): JSX.Element => {
  return (
    <div className="user-detail-charts-in-popup">
      <EmployeeChart
        participantList={participantList}
        employeeId={employeeId}
        details={details}
      />
      <RaterChart employeeId={employeeId} details={details} />
    </div>
  )
}
