/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Row, Col } from 'antd'

interface Props {
  data: any
}

export const FocusArea: React.FC<Props> = ({ data }): JSX.Element => {
  return (
    <Row
      justify="space-around"
      align="middle"
      className="focus-area-data cursor-pointer"
    >
      <Col
        xs={6}
        sm={6}
        md={4}
        lg={6}
        xl={7}
        style={{ borderLeft: `5px solid ${data.color}` }}
      >
        <img src={data.image} alt="icon" className="focus-area-icon" />
      </Col>
      <Col xs={18} sm={18} md={20} lg={18} xl={17} className="ps-2">
        <b className="text-uppercase">{data.name}</b>
        <p>
          Total Count&nbsp;&nbsp;
          <b>{data.constructs.length}</b>
        </p>
      </Col>
    </Row>
  )
}
