/* eslint-disable prettier/prettier */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { Drawer, Col, Row, Button } from 'antd'
import { Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import './formPreview.scss'
import { getProjectDetail } from '../../../../../api/API'
import GrowMetrixLogo from '../../../../../assets/images/logo-gm.png'

interface Props {
  visible: boolean
  setIsOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  questionList: any
  multiStepFormContainer: any
}

export const PublishableFrom: React.FC<Props> = ({
  visible,
  setIsOpenDrawer,
  questionList,
  multiStepFormContainer,
}): JSX.Element => {
  const [count, setCount] = useState(0)
  const params: any = useParams()
  const projectId = params.id
  const [projectDetail, setProjectDetail] = useState<any>()

  useEffect(() => {
    getProjectDetail(projectId).then((res: any) => {
      const result = res.data.data
      setProjectDetail(result)
    })
  }, [])

  const incrementCounter = (): void => {
    if (count + 1 !== multiStepFormContainer.length) {
      setCount(count + 1)
    } else {
      setCount(0)
    }
  }
  return (
    <Drawer
      title="Preview"
      className="preview-form"
      width={550}
      onClose={() => setIsOpenDrawer(false)}
      visible={visible}
      getContainer={false}
      style={{ position: 'absolute' }}
    >
      <div className="border p-3" style={{ borderRadius: '7px' }}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={3} xl={3}>
            <img
              src={
                projectDetail
                  ? projectDetail.companyData[0].logo
                  : GrowMetrixLogo
              }
              alt=""
              className="company-logo"
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={20} xl={20}>
            <h5>{projectDetail ? projectDetail.name : ''}</h5>
          </Col>
        </Row>
        <hr />
        <div className="mt-4 mb-4 p-3 border servey-preview">
          {multiStepFormContainer.length > 0 &&
            multiStepFormContainer[count].map((question: any, index: any) => (
              <div key={question.questionCounter} className="mt-1 mb-1">
                {question.questionType === 'html-editor' && (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: question.htmlData ? question.htmlData : '',
                    }}
                  />
                )}
                {question.questionType === 'free-text' && (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter answer" />
                  </Form.Group>
                )}
                {question.questionType === 'multiple-choice' && (
                  <Form.Group className="custom-buttons">
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                    </Form.Label>
                    <div className="d-flex flex-wrap mb-4">
                      {question.options.map((option: any, i: any) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Form.Check
                            key={option.value}
                            type={question.allowMultiple ? 'checkbox' : 'radio'}
                            label={option.text}
                            name="abnc"
                          />
                        )
                      })}
                    </div>
                  </Form.Group>
                )}
                {question.questionType === 'checkbox' && (
                  <Form.Group className="custom-buttons">
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                    </Form.Label>
                    <div className="d-flex flex-wrap mb-4">
                      {question.options.map((option: any, i: any) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Form.Check
                            key={option.value}
                            type={question.allowMultiple ? 'checkbox' : 'radio'}
                            label={option.text}
                            name="abnc"
                          />
                        )
                      })}
                    </div>
                  </Form.Group>
                )}
                {question.questionType === 'dropdown' && (
                  <Form.Group>
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                    </Form.Label>
                    <Form.Control as="select">
                      <option>Open this select menu</option>
                      {question.options.map((option: any, i: any) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <option key={option.value} value={option}>
                            {option.text}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                )}
                {question.questionType === 'matrix-table' && (
                  <Form.Group className="custom-buttons">
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                    </Form.Label>
                    <table className="mt-2 mb-2 matrix-table">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          {question.metrixtableScaleArray.map(
                            (scalePoint: any, i: any) => {
                              return (
                                <th key={i}>
                                  <p>{scalePoint}</p>
                                </th>
                              )
                            },
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {question.options.map((option: any, i: any) => {
                          return (
                            <tr key={option.value} className="h-50px">
                              <td style={{ width: '20%' }}>
                                <p className="mb-0">{option.text}</p>
                              </td>
                              {question.metrixtableScaleArray.map(
                                (_: any, indexofspa: any) => (
                                  <td key={indexofspa}>
                                    <Form.Check
                                      type={
                                        question.allowMultiple
                                          ? 'checkbox'
                                          : 'radio'
                                      }
                                      name={`matrix_table_scale_point_radio_${i}_${question.blockNo}`}
                                    />
                                  </td>
                                ),
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Form.Group>
                )}
              </div>
            ))}
          <div className="text-end">
            <Button
              className="comman-button btn-theme"
              onClick={() => incrementCounter()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="text-end mt-3">
        <Button className="comman-button btn-theme">Save</Button>
      </div> */}
    </Drawer>
  )
}
