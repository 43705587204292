/* eslint-disable react/require-default-props */
import React from 'react'
import { Row, Col, Checkbox, Typography } from 'antd'
import { Label } from './Label'

const { Text, Link } = Typography
interface Props {
  block: any
  question: any
  updateQuestionList: any
  value?: any
  setSelectedBlock: React.Dispatch<React.SetStateAction<number>>
  setSelectedQuestion: React.Dispatch<React.SetStateAction<number>>
  selectedQuestion?: any
  isMandatory?: any
}

export const QuestionCompnent: React.FC<Props> = ({
  block,
  question,
  updateQuestionList,
  value,
  setSelectedBlock,
  setSelectedQuestion,
  selectedQuestion,
  isMandatory,
}): JSX.Element => {
  return (
    <Row justify="end" className="question-bg-gray" align="middle">
      <Col xs={20} sm={20} md={20} lg={20} xl={20}>
        <Label
          placeholder="Click here to wite question"
          name={`question_title_${question}_${block}`}
          updateQuestionList={updateQuestionList}
          forWhat="question"
          value={value}
          selectedQuestion={selectedQuestion}
        />
      </Col>
      <Col xs={4} sm={4} md={4} lg={4} xl={4} className="text-center">
        <Checkbox
          name={`mandator_question_is_${question}_${block}`}
          onMouseEnter={() => {
            setSelectedBlock(block)
            setSelectedQuestion(question)
          }}
          onChange={(e) => {
            updateQuestionList(e.target.checked, 'mandatory')
          }}
          checked={isMandatory}
        >
          <Text type="danger"> Mark As Mandatory</Text>
        </Checkbox>
      </Col>
    </Row>
  )
}
