/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { Drawer, notification } from 'antd'
import { mock } from './mock'
import Card from '../../components/CardComp/Card'
import DrawerFooter from '../../components/Drawer/footer/drawerFooter'
import './style/company.scss'
import Listview from './listview'
import rightArrow from '../../assets/images/rightArrow.svg'
import buildingsgray from '../../assets/images/buildingsgray.svg'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import { AddCompany } from './components/addCompany'
import { changeCompanyMode, filterCompanyAPI, logout } from '../../api/API'
import { CompanyFilterBar } from './companyFilterbar'

interface PropsList {
  companyList: any
  getCompanyList: any
  setcompanyList: any
  setIsFilterMode: any
  currentPage: any
  setCurrentPage: any
  totalPages: any
}

const ListCompany: React.FC<PropsList> = ({
  companyList,
  getCompanyList,
  setcompanyList,
  setIsFilterMode,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const history = useHistory()
  // const listCompany = mock
  const [state, setState] = React.useState('grid')
  const [drawerVisible, setDrawerVisible] = useState(false)
  // const [companyList, setcompanyList] = useState<any>([])
  const [filterQuery, setFilterQuery] = useState<any>({})

  const changeMode = (id: any, mode: boolean): void => {
    const data1 = {
      mode,
    }
    changeCompanyMode(id, data1)
      .then((res: any): any => {
        notification.open({
          message: `Company successfully ${mode ? 'Activated' : 'Deactivated'}`,
        })
        getCompanyList()
      })
      .catch((error: any) => {
        getCompanyList()
      })
  }

  const filterCompany = (key: string, value: any): void => {
    const existingQury = filterQuery
    if (key === 'name') {
      existingQury[key] = value.target.value
    }
    // if (key === 'mode' || key === 'company') {
    //   existingQury[key] = value === 'active'
    // }

    if (key === 'mode') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }

    if (key === 'company') {
      existingQury[key] = value
    }

    if (key === 'date_added') {
      existingQury[key] = value
    }

    setFilterQuery(existingQury)
    if (value) {
      filterCompanyAPI(existingQury)
        .then((res: any): any => {
          // getCompanyList()
          setIsFilterMode(true)
          setcompanyList(res.data.data)
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
          }
        })
    }
  }

  const resetFilter = (): void => {
    getCompanyList()
  }

  return (
    <>
      <div className="list-company w-100 mt-3">
        <div className="company-container w-100 d-flex flex-column ">
          <div className="company-header d-flex justify-content-between mb-0">
            <div className="company-header-title d-flex flex-column">
              <span className="company_header-title-text">COMPANY</span>
              <div className="company-header-subtitle">
                <Link to="/dashboard">
                  <span className="dashboard-link">Dashboard</span>
                </Link>
                <img src={rightArrow} className="link-arrow" alt="rightArrow" />
                <Link to="/company">
                  <span className="company-link">Company</span>
                </Link>
              </div>
            </div>
            <div className="create-btn-wrapper">
              <Button
                className="create-btn"
                onClick={() => setDrawerVisible(true)}
              >
                Create New
              </Button>
            </div>
          </div>
          <hr className="mobile-hr" />
          <div className="">
            <CompanyFilterBar
              changeViewType={setState}
              filterCompany={filterCompany}
              resetFilter={resetFilter}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </div>
          <hr />
          <div className="drawer-wrapper">
            <Drawer
              onClose={() => {
                setDrawerVisible(false)
              }}
              visible={drawerVisible}
              title={
                <span>
                  <img
                    src={buildingsgray}
                    alt="Add New Company"
                    className="me-3 drawer-head-icon"
                  />
                  ADD NEW COMPANY
                </span>
              }
              width={window.innerWidth > 550 ? 465 : '100%'}
              footer={<DrawerFooter />}
            >
              <AddCompany
                getCompanyList={getCompanyList}
                setDrawerVisible={setDrawerVisible}
              />
            </Drawer>
          </div>
          {state === 'grid' ? (
            <div className="company-grid">
              {companyList.length > 0 &&
                companyList.map((item: any) => (
                  <Card
                    image={item.logo}
                    name={item.name}
                    employees={item.emp ? item.emp.length : 0}
                    id={item.company_id}
                    hr={item.employee}
                    mode={item.mode}
                    _id={item._id}
                    checkbox
                    changeMode={changeMode}
                    employeeType={item.employee_type}
                    key={item._id}
                    getCompanyList={getCompanyList}
                    completedSurveyStatus={item.completedSurveyStatus}
                    notCompletedSurveyStatus={item.notCompletedSurveyStatus}
                  />
                ))}
            </div>
          ) : (
            <div className="company-list mt-1">
              <Listview
                currentPosts={companyList}
                changeMode={changeMode}
                getCompanyList={getCompanyList}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ListCompany
