/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import Button from '../../../../../../../../components/Button'
import './styles/details.scss'
import Camera from '../../../../../../../../assets/images/camera.svg'
import Bulb from '../../../../../../../../assets/images/quick_tip.svg'

export const Details: React.FC = () => {
  return (
    <div className="user-details">
      <div className="form-container">
        <div className="user-details-row1">
          <div className="profile-label">
            <span>Upload Photo</span>
          </div>
          <div className="profile-photo">
            <div className="profile-circle">
              <img src="https://i.ibb.co/54GxVMv/unnamed-1.jpg" alt="dd" />
            </div>
            <div className="input-form">
              <label htmlFor="profile-input">
                <div className="cam-circle">
                  <img src={Camera} alt="Camera" />
                </div>
              </label>
              <input type="file" className="profile-input" id="profile-input" />
            </div>
          </div>
          <div className="profile-desc">
            <span>
              Please make sure the logo file must be less than 1mb and in a
              dimension of 150x150
            </span>
          </div>
        </div>
        <form>
          <div className="form-row">
            <div
              className="form-group col-md-6"
              style={{ paddingRight: '7px' }}
            >
              <label htmlFor="inputFirstName" className="form-label">
                First Name
                <span className="asterik">*</span>
              </label>
              <input
                type="name"
                className="form-control"
                id="inputFirstName"
                placeholder="Enter first name"
              />
            </div>
            <div className="form-group col-md-6" style={{ paddingLeft: '7px' }}>
              <label htmlFor="inputLastName" className="form-label">
                Last Name
                <span className="asterik">*</span>
              </label>
              <input
                type="name"
                className="form-control"
                id="inputLastName"
                placeholder="Enter last name"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="inputPermissions" className="form-label">
              Permissions
              <span className="asterik">*</span>
            </label>
            <select id="inputPermissions" className="form-select">
              <option>Select Permissions</option>
              <option>...</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="inputEmail" className="form-label">
              Email<span className="asterik">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputPhone" className="form-label">
              Phone<span className="asterik">*</span>
            </label>
            <input
              type="name"
              className="form-control"
              id="inputPhone"
              placeholder="Enter contact"
            />
          </div>
        </form>
      </div>
    </div>
  )
}
