/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import { Row, Col, Checkbox, Typography, Modal, Button } from 'antd'
import { Doughnut, defaults } from 'react-chartjs-2'

import {
  MoreOutlined,
  UserOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import './styles/userCard.scss'
import PopupConfirm from '../../../../../assets/images/Group 9366.svg'
import profileIcon from '../../../../../assets/images/Group 9011.svg'
import WPForm from '../../../../../assets/images/Icon awesome-wpforms.svg'
import UserCirlce from '../../../../../assets/images/Icon awesome-user-circle.svg'
import { changeEmployeeStatus } from '../../../../../api/API'

interface Props {
  data: any
  getDetail: any
  getEmployee: any
}
export const UserCard: React.FC<Props> = ({ data, getDetail, getEmployee }) => {
  const { Title } = Typography
  const [DeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [blockModalVisible, setblockModalVisible] = useState(false)
  const [adminModalVisible, setadminModalVisible] = useState(false)
  const [blockEmployeeId, setBlockEmployeeId] = useState('')
  // const [blockEmployeeId, setBlockEmployeeId] = useState('')

  const showDeleteModal = (): any => {
    setDeleteModalVisible(true)
  }

  const changeMode = (id: any, status: boolean): void => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const data = {
      status,
    }

    changeEmployeeStatus(id, data)
      .then((res: any): any => {
        // getListofProjects()
        getEmployee()
      })
      .catch((error: any) => {
        // getListofProjects()
      })
  }

  return (
    <div
      className={
        data.status
          ? 'activate-card-bg cursor-pointer border p-2 user_card justify-content-xs-center'
          : 'deactivate-card-bg cursor-pointer border p-2 user_card justify-content-xs-center'
      }
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          {/* <input type="checkbox" className="checkbox" /> */}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="text-end border-0"
        >
          <DropdownButton
            title={<MoreOutlined />}
            className="card-dropdown-icon"
          >
            <Dropdown.Item
              className="cursor-pointer"
              onClick={() => getDetail(data._id, 'true')}
            >
              <span className="pe-2">
                <EyeOutlined />
              </span>
              View
            </Dropdown.Item>
            <Dropdown.Item
              className="cursor-pointer"
              onClick={() => getDetail(data._id, 'false')}
            >
              <span className="pe-2">
                <EditOutlined />
              </span>
              Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={showDeleteModal}>
              <span className="pe-2">
                <DeleteOutlined />
              </span>
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setblockModalVisible(true)
                setBlockEmployeeId(data._id)
              }}
            >
              <span className="pe-2">
                <MinusCircleOutlined />
              </span>
              {/* {data.status ? 'Block' : 'Unblock'} */}
              {data.status ? 'Deactive' : 'Active'}
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={showAdminModal}>
              <span className="pe-2">
                <UserOutlined />
              </span>
              Make Admin
            </Dropdown.Item> */}
          </DropdownButton>
        </Col>
      </Row>
      <Row
        onClick={() => getDetail(data._id, 'true')}
        className="cursor-pointer"
      >
        <Col
          xs={{ span: 5, offset: 1 }}
          sm={6}
          md={5}
          lg={{ span: 5, offset: 1 }}
          xl={{ span: 4, offset: 1 }}
        >
          <img
            src={data.image ? data.image : UserCirlce}
            alt="not available"
            className="company-logo"
          />
        </Col>
        <Col xs={18} sm={18} md={15} lg={18} xl={12} className="ms-xl-2">
          <span
            style={{ textDecoration: data.status ? 'none' : 'line-through' }}
          >
            <Title level={5}>{data.name}</Title>
          </span>
          <Row justify="start">
            <Col xs={10} sm={24} md={12} lg={12} xl={12}>
              <p>
                <img src={profileIcon} alt="ProfileIcon" className="w-15" />
                &nbsp;&nbsp;{data.emp_id}
              </p>
            </Col>
            <Col xs={14} sm={24} md={12} lg={12} xl={12}>
              <p>
                <img src={WPForm} alt="Document" className="w-15" />
                &nbsp;&nbsp;{data.participantCount}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row onClick={() => getDetail(data._id)} className="cursor-pointer">
        <Col md={12}>
          <div
            className={
              data.status ? 'list-active-circle' : 'list-deactive-circle'
            }
          />
        </Col>
        {/* {data.gravater && (
          <Col md={12} className="text-end">
            <img src={data.gravater} alt="not availabel" className="gravatar" />
          </Col>
        )} */}
      </Row>
      {/* Delete Modal  */}
      <Modal
        visible={DeleteModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">Are you sure you want to Delete?</p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setDeleteModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                setDeleteModalVisible(false)
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
      {/* Block Modal  */}
      <Modal
        visible={blockModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">
            Are you sure you want to {data.status ? 'Block' : 'Unblock'}?
          </p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setblockModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                setblockModalVisible(false)
                changeMode(blockEmployeeId, data.status ? !data.status : true)
              }}
            >
              {data.status ? 'Block' : 'Unblock'}
            </Button>
          </div>
        </div>
      </Modal>
      {/* Make admin Modal  */}
      <Modal
        visible={adminModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">
            Are you sure you want to Make Admin?
          </p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setadminModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                setadminModalVisible(false)
              }}
            >
              Make Admin
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
