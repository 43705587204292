/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'
import './style/reportPdf.scss'
import * as Highcharts from 'highcharts'
import HC_EXPORTS from 'highcharts/modules/exporting'
// import offlineExporting from "highcharts/modules/offline-exporting"
import HighchartsReact from 'highcharts-react-official'
import HCMore from 'highcharts/highcharts-more'
import axios from 'axios'

HC_EXPORTS(Highcharts)
HCMore(Highcharts)
interface Props {
  focusarea?: any
  construct?: any
  onUpdate?: any
  overviewRadarData?: any
}

export const OverView2: React.FC<Props> = ({
  focusarea,
  construct,
  overviewRadarData,
  onUpdate,
}): JSX.Element => {
  const [options, setOptions] = useState<Highcharts.Options>({})
  const chartComponent = useRef(null)
  const splitInteger = function (num: any, parts: any) {
    // Complete this function
    let val
    let retData
    const mod = num % parts
    if (mod === 0) {
      val = num / parts
      retData = Array(parts).fill(val)
    } else {
      val = (num - mod) / parts
      retData = Array(parts).fill(val)
      for (let i = 0; i < mod; i++) {
        retData[i] = retData[i] + 1
      }
      retData.reverse()
    }

    return retData
  }

  useEffect(() => {
    // const chart: any = chartComponent ? chartComponent.current<any>?.chart: null;
    const focusArray: any = []
    const constrcutArray: any = []
    const radarArray: any = []
    const tickPositoins: any = []
    for (let index = 0; index < focusarea.length; index++) {
      const temp: any = {}
      temp.dataLabels = {}
      temp.dataLabels.style = {}
      // temp.dataLabels.textPath = {}
      // temp.dataLabels.textPath.attributes = {}
      temp.id = index
      temp.name = focusarea[index].name
      temp.color = focusarea[index].color
      //  temp.style
      temp.y = parseFloat(focusarea[index].survey) // focusarea[index].construct.length
      // temp.dataLabels.rotation = 0
      temp.dataLabels.style.width = 150
      // temp.dataLabels.textPath.attributes.startOffset = 250
      // temp.dataLabels.textPath.attributes.textAnchor = 'start'
      focusArray.push(temp)
      const spitedNumber = splitInteger(
        focusarea[index].survey,
        focusarea[index].construct.length,
      )
      for (
        let index2 = 0;
        index2 < focusarea[index].construct.length;
        index2++
      ) {
        const temp2: any = {}
        temp2.dataLabels = {}
        temp2.dataLabels.style = {}
        temp2.id = `${index}.${index2}`
        temp2.name =
          focusarea[index].construct[index2].short_name ||
          focusarea[index].construct[index2].name
        // temp2.name = focusarea[index].construct[index2].name.length > 9
        //   ? `${focusarea[index].construct[index2].name.substring(0, 9)}`
        //   : focusarea[index].construct[index2].name
        temp2.color = focusarea[index].construct[index2].color
        temp2.dataLabels.rotation = 0
        temp2.dataLabels.style.width = 50
        temp2.dataLabels.style.fontWeight = 'bold'
        // temp2.dataLabels.style.fontSize = 10
        temp2.dataLabels.style.textOverflow = 'clip'
        temp2.y = spitedNumber[index2]
        // console.log(spitedNumber[index2])
        tickPositoins.push(temp2.y)
        constrcutArray.push(temp2)
      }
    }
    const seriesDatat: any = []
    const outerpie: any = {}
    outerpie.type = 'pie'
    outerpie.pointPlacement = 'between'
    outerpie.innerSize = '80%'
    outerpie.size = '100%'
    outerpie.style = {
      fontFamily: 'Calibri Regular',
      fontSize: '1.2em',
    }

    const focusArrayCount = focusArray.length
    const constrcutArrayCount = constrcutArray.length
    const yForPerBlock: any = Number(100 / constrcutArrayCount)
    outerpie.data = focusArray.map((item: any) => {
      const itemss = constrcutArray.filter((x: any) => {
        const maximumValue = parseFloat(item.id) + 1
        const minimumValue = parseFloat(item.id)
        const currentValue = parseFloat(x.id)
        return currentValue >= minimumValue && currentValue < maximumValue
      })
      const calculatio22: any = itemss.length * yForPerBlock
      item.y = calculatio22
      return item
    })

    seriesDatat.push(outerpie)

    const innerpir: any = {}
    innerpir.type = 'pie'
    innerpir.innerSize = '70%'
    // innerpir.pointPlacement = 'on'
    // innerpir.dataLabels.rotation = 0
    innerpir.size = '80%'
    innerpir.data = constrcutArray.map((i: any) => {
      i.y = yForPerBlock
      return i
    })
    seriesDatat.push(innerpir)

    const columnChart: any = {}
    columnChart.type = 'column'
    columnChart.name = ' '
    columnChart.color = '#ffffff'
    columnChart.className = 'innercolor'
    columnChart.data = []
    const tdata = overviewRadarData.columnChart
    for (let index2 = 0; index2 < tdata.length; index2++) {
      const temp: any = {}
      temp.type = 'column'
      temp.color = `${tdata[index2].color}`
      // temp.pointPlacement = 0.5
      temp.y = tdata[index2].result
      // temp.width = index2 * 4
      // temp.y = Math.floor(Math.random() * (5 - 0 + 1)) + 0
      // columnChart.data.push(temp)
    }
    seriesDatat.push(columnChart)

    for (
      let index = 0;
      index < overviewRadarData.overviewRadarData.length;
      index++
    ) {
      const temp: any = {}
      temp.type = 'line'
      temp.name = overviewRadarData.overviewRadarData[index].rater_title
      temp.color = overviewRadarData.overviewRadarData[index].color
      temp.pointPlacement = 'between'
      temp.data = overviewRadarData.overviewRadarData[index].construct.map(
        (result: any) => result.result || 0,
      )
      seriesDatat.push(temp)
    }

    // console.log('seriesDatat', seriesDatat)

    let allY: any
    let angle1: any
    let angle2: any
    let angle3: any
    const rotate = function () {
      seriesDatat.forEach((element: any, i: any) => {
        if (element.type === 'pie') {
          angle1 = 0
          angle2 = 0
          angle3 = 0
          allY = 0
          element.data.forEach((element2: any) => {
            allY += element2.y
          })

          element.data.forEach((element3: any) => {
            angle2 = angle1 + (element3.y * 360) / allY
            angle3 = angle2 - (element3.y * 360) / (2 * allY)
            if (i === 0) {
              // element3.dataLabels.textPath.attributes.startOffset = `${angle2}`
              // element3.dataLabels.textPath.attributes.textAnchor = 'start'
            }
            if (angle3 >= 180) {
              element3.dataLabels.rotation = angle3
            } else {
              element3.dataLabels.rotation = angle3
            }

            if (angle3 > 100 && angle3 < 260) {
              element3.dataLabels.rotation = angle3 + 180
            }
            angle1 = angle2
          })
        }
      })
    }

    rotate()
    const optionals: Highcharts.Options = {
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        fallbackToExportServer: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        polar: true,
        height: 700,
        width: 700,
        style: {
          // fontFamily: 'CalibriBold',
          letterSpacing: 1,
        },
      },
      title: {
        text: '',
      },
      legend: {
        enabled: true,
        itemStyle: {
          color: 'rgb(95, 98, 98)',
          fontFamily: 'Calibri, sans-serif',
        },
      },
      pane: {
        size: '60%',
      },
      plotOptions: {
        pie: {
          // dataLabels: {},
          dataLabels: {
            // enabled: true,
            distance: -37,
            color: 'white',
            y: 20,
            style: {
              // width: 75,
              // color: '#FFF',
              fontSize: '14px',
              textShadow: false,
              textOutline: 'none',
            },
          },
          borderWidth: 0.3,
          borderColor: 'white',
        },
        column: {
          pointPadding: 1,
          groupPadding: 1,
          pointPlacement: 'between',
        },
        line: {
          // connectNulls: false,
          pointPlacement: 'between',
        },
        // series: {
        //   states: {
        //     inactive: {
        //       enabled: false,
        //     },
        //   },
        // },
      },
      xAxis: {
        visible: true,
        gridLineInterpolation: 'circle',
        labels: {
          enabled: false,
        //  format: ' ',
        },
        gridLineWidth: 1,
        
        // tickInterval: 1,
        // tickPositions: tickPositoins,
        // tickPixelInterval: 100,
        // max: 10,
      },
      yAxis: {
        gridLineInterpolation: 'circle',
        
        min: 0,
        tickInterval: 1,
        max: 6,
        labels: {
          enabled: false,
        //  format: ' ',
        },
      },
      series: seriesDatat,
    }
    setOptions(optionals)
    afterChartCreated(optionals)
  }, [])

  const afterChartCreated = async (optionals: any) => {
    // const exportUrl = 'https://export.highcharts.com/';
    // const exportUrl = 'http://127.0.0.1:7801/';
    const exportUrl =
      process.env.REACT_APP_EXPORT_URL || 'https://export.growmetrix.com'
    const object = {
      infile: JSON.stringify(optionals),
      scale: '3',
      constr: 'Chart',
      b64: true,
      width: false,
      type: 'image/png',
    }
    await axios.post(exportUrl, object).then(async (res) => {
      onUpdate(`data:image/png;base64,${res.data}`)
    })
  }
  return (
    <>
      <div className="statsbody d-flex justify-content-around mt-0">
        <div className="pie mt-0 ms-0">
          <div className="position-relative" id="chart">
            <HighchartsReact
              ref={chartComponent}
              highcharts={Highcharts}
              options={options}
            />
          </div>
        </div>
      </div>
    </>
  )
}
