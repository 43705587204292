import { Radio } from 'antd'
import React from 'react'
import { Anchor } from '../../components/Link/link'

interface Props {
  tabName: any
  // eslint-disable-next-line react/require-default-props
  onChangeTab?: unknown
  state: any
}

const Header: React.FC<Props> = ({ tabName, onChangeTab, state }) => {
  return (
    <>
      <div className="list-report w-100 mt-3">
        <div className="report-container w-100 d-flex flex-column ">
          <div className="report-header d-flex justify-content-between mb-0 py-3">
            <div className="report-header-title d-flex flex-column">
              <h5 className="text-uppercase report-header-title-text">
                My Reports
              </h5>
              <div className="report-header-subtitle d-flex">
                <p className="p-0 mb-0">
                  <Anchor url="/dashboard" target="">
                    Dashboard
                  </Anchor>
                  {' > '}
                  <Anchor url="#" target="">
                    My Reports
                  </Anchor>
                </p>
              </div>
            </div>
            {/* <div className="radio-button-group"> */}
            {/* <Radio.Group
              options={tabName}
              onChange={(e) => onChangeTab(e)}
              value={state}
              optionType="button"
            /> */}
            {/* </div> */}
          </div>
          {/* <hr /> */}
        </div>
      </div>
    </>
  )
}

export default Header
