import React, { useEffect, useState } from 'react'
import { Input, Select, DatePicker, Button, Row, Col, Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
// import MoreImg from '../../assets/images/more.svg'
import ResetImg from '../../assets/images/reset.svg'
// import AdvanceFilter from '../../assets/images/filter.svg'
import GoArrow from '../../assets/images/goArrow.svg'
import rightArrow from '../../assets/images/rightArrow.svg'
import rightDoubleArrow from '../../assets/images/right_double_arrow.svg'
import leftArrow from '../../assets/images/leftArrow.svg'
import leftDoubleArrow from '../../assets/images/left_double_arrow.svg'
import { readCookie } from '../../api/CookieScript'
import { SUPER_ADMIN_ROLE } from '../../constants/index'
import { getCompanyList } from '../../api/API'

const { Option } = Select
interface Props {
  // searchTerm: any
  // company: any
  // mode: any
  // date: any
  filterCompany: any
  tableData: any
  setCurrentPage: any
  currentPage: any
  filterSearchBar: any
  filterStatus: any
  filterCompanyName: any
  getCompanyReportTableData: any
  totalPages: any
}

export const CompanyFilterBar: React.FC<Props> = ({
  // searchTerm,
  // company,
  // mode,
  // date,
  filterCompany,
  filterSearchBar,
  filterCompanyName,
  filterStatus,
  getCompanyReportTableData,
  tableData,
  setCurrentPage,
  currentPage,
  totalPages,
}) => {
  // export const CompanyFilterBar: React.FC = (): JSX.Element => {
  const dateFormat = 'YYYY/MM/DD'
  const [isRemindVisible, setIsRemindVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState(false)
  const [customPage, setCustomPage] = useState<any>(1)
  const [companyList, setCompanyList] = useState([])
  const [rerender, setRerender] = useState(Math.random())

  const showDrawer = (): any => {
    setVisible(true)
    setIsRemindVisible(true)
  }
  const onClose = (): any => {
    setVisible(false)
  }
  useEffect(() => {
    getCompanyList().then((res: any): any => {
      const response = res.data
      if (res.status !== 200) {
        console.log('something is not right')
      } else {
        setCompanyList(response.data)
      }
    })
  }, [])

  const handleNumberInput = (e: any): void => {
    const valueTemp = e.target.value
    if (/[0-9]/.test(valueTemp)) {
      setCustomPage(valueTemp <= totalPages ? valueTemp : 1)
    } else {
      setCustomPage('')
    }
  }

  return (
    <>
      <Row
        gutter={[16, 16]}
        className="report-filter-container mt-3"
        justify="space-between"
      >
        <Col
          xs={19}
          sm={20}
          md={12}
          lg={12}
          xl={6}
          className="position-relative"
        >
          <span className="floating-label-search font-smaller">Search</span>
          <Input
            key={rerender}
            defaultValue={filterSearchBar}
            placeholder="Search by Name, ID"
            onChange={(e: any) => {
              filterCompany('name', e)
            }}
          />
        </Col>
        {readCookie('role') === SUPER_ADMIN_ROLE && (
          <Col
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={3}
            className="position-relative desk-data"
          >
            <span className="floating-label-company font-smaller">Company</span>

            <Select
              key={rerender}
              defaultValue={filterCompanyName}
              style={{ width: '100%' }}
              onSelect={(e: any) => filterCompany('companyId', e)}
            >
              <Option value="">Select</Option>
              {companyList.map((element: any) => (
                // eslint-disable-next-line no-underscore-dangle
                <Option value={element._id} key={element._id}>
                  {element.name}
                </Option>
              ))}
            </Select>
          </Col>
        )}

        <Col
          xs={12}
          sm={24}
          md={5}
          lg={5}
          xl={3}
          className="position-relative desk-data"
        >
          <span className="floating-label-mode font-smaller">Mode</span>
          <Select
            key={rerender}
            defaultValue={filterStatus}
            style={{ width: '100%' }}
            onSelect={(e: any) => filterCompany('status', e)}
          >
            <Option value="">All</Option>
            <Option value="active">Active</Option>
            <Option value="deactive">Deactive</Option>
          </Select>
        </Col>
        <Col
          xs={12}
          sm={24}
          md={7}
          lg={7}
          xl={3}
          className="position-relative border-right-dotted desk-data"
        >
          <span className="floating-label-date font-smaller">By Date</span>
          <DatePicker
            key={rerender}
            format={dateFormat}
            onChange={(date) => filterCompany('date_added', date)}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={3}
          lg={3}
          xl={3}
          className="position-relative desk-data"
        >
          {/* <Button
            icon={<img src={AdvanceFilter} alt="reset" className="w" />}
            className="page-label border-radius-6px"
          >
            Advace Filter
          </Button> */}
          <Button
            className="ms-2 border-radius-6px"
            style={{
              padding: '8px 6px',
              backgroundColor: '#F6F9FB',
            }}
            onClick={() => {
              setRerender(Math.random())
              getCompanyReportTableData()
            }}
          >
            <img src={ResetImg} alt="reset" className="w-75" />
          </Button>
          {/* <Button
            className="ms-2 border-radius-6px"
            style={{
              padding: '8px 6px',
              backgroundColor: '#F6F9FB',
            }}
          >
            <img src={MoreImg} alt="menu" className="w-75" />
          </Button> */}
        </Col>
        {/* Desktop Data Part over */}
        {/* --------------------------- */}
        {/* Mobile Data Part Start */}
        <Col xs={5} sm={10} md={12} lg={8} className="report_filter_button">
          <Button id="report_filter_btn" onClick={showDrawer}>
            Filter
          </Button>
        </Col>
        <Drawer
          title="Filter"
          placement="right"
          getContainer={false}
          onClose={onClose}
          visible={isRemindVisible}
          closable={false}
          extra={
            <CloseOutlined
              style={{ float: 'right' }}
              onClick={() => setIsRemindVisible(false)}
            />
          }
        >
          <Row justify="space-between" className="pt-3">
            <Col
              xs={11}
              sm={12}
              md={8}
              lg={6}
              xl={3}
              className="position-relative"
            >
              <span className="floating-label-company font-smaller text-muted">
                Company
              </span>
              <Select
                key={rerender}
                defaultValue={filterCompanyName}
                style={{ width: '100%' }}
                onSelect={(e: any) => filterCompany('company_name', e)}
              >
                <Option value="">Select</Option>
                {tableData.map((element: any) => (
                  <Option value={element.company_id} key={element.company_id}>
                    {element.company_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              className="position-relative"
            >
              <span className="floating-label-mode font-smaller text-muted">
                Mode
              </span>
              <Select
                defaultValue="active"
                style={{ width: '100%' }}
                onSelect={(e: any) => filterCompany('status', e)}
              >
                <Option value="active">Active</Option>
                <Option value="deactive">Deactive</Option>
              </Select>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              className="position-relative border-right-dotted date-input mt-3"
            >
              <span className="floating-label-date font-smaller text-muted">
                By Date
              </span>
              <DatePicker
                format={dateFormat}
                style={{ width: '93%', borderRadius: '5px' }}
                onChange={(date) => filterCompany('date_added', date)}
              />
            </Col>
            <Col xs={12} sm={2} md={12}>
              <Button
                className="ms-2 mt-3"
                style={{
                  padding: '8px 6px',
                  backgroundColor: '#F6F9FB',
                }}
              >
                <img src={ResetImg} alt="reset" className="w-75" />
              </Button>
              {/* <Button
                className="ms-2 mt-3"
                style={{
                  padding: '8px 6px',
                  backgroundColor: '#F6F9FB',
                }}
              >
                <img src={MoreImg} alt="menu" className="w-75" />
              </Button> */}
            </Col>
            <span id="dash-border" />
            {/* <Col
              xs={24}
              sm={24}
              md={12}
              className="position-relative text-center"
            >
              <span className="advane_filter_btn">
                <img
                  src={advanceFilter}
                  alt="advance filter"
                  className="adv_filter_img me-1"
                />
                Advance Filter
              </span>
            </Col> */}
            <Col
              xs={24}
              sm={24}
              md={12}
              className="position-relative text-center"
            >
              <Button
                className="apply_btn float-end mt-3 me-2"
                onClick={() => setIsRemindVisible(false)}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Drawer>
        {/*= ===Drawer Over==== */}
        <Col xs={24} sm={24} md={14} lg={14} xl={6}>
          <div className="d-flex justify-content-between justify-content-lg-end justify-content-md-end justify-content-xl-end">
            <Button
              icon={<img src={leftArrow} className="w-25" alt="First" />}
              onClick={() => setCurrentPage(1)}
              className="rounded-start"
            />
            <Button
              icon={<img src={leftDoubleArrow} className="w-50" alt="First" />}
              onClick={() =>
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
              }
            />
            <Button className="page-label">
              Page {currentPage} of {totalPages}
            </Button>
            <Button
              icon={<img src={rightDoubleArrow} className="w-50" alt="First" />}
              onClick={() =>
                setCurrentPage(currentPage < totalPages ? currentPage + 1 : 1)
              }
            />
            <Button
              icon={<img src={rightArrow} className="w-25" alt="First" />}
              className="rounded-end"
              onClick={() => setCurrentPage(totalPages)}
            />
            <Input
              type="text"
              placeholder="Go to"
              className="goto-field ms-1"
              value={customPage}
              onChange={(e) => handleNumberInput(e)}
              // onChange={(e: any) =>
              //   setCustomPage(e.target.value <= totalPages ? e.target.value : 1)
              // }
            />
            <Button
              icon={<img src={GoArrow} alt="Go" className="w-50" />}
              className="btn-theme goto-btn ms-1 border-radius-6px"
              onClick={() => setCurrentPage(customPage)}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}
