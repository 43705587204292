/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Button, Input, notification, Popconfirm } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import {
  getConstructs,
  addConstructs,
  updateConstructs,
} from '../../../../../../api/API'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { v4: uuidv4 } = require('uuid')

interface Prop {
  addNewSlice: any
  selectedFocusarea: string
}

export const Construct: React.FC<Prop> = ({
  selectedFocusarea,
  addNewSlice,
}): JSX.Element => {
  const [construct, setConstruct] = useState([
    {
      serial_number: 'A1',
      name: '',
      short_name: '',
      deleted: false,
      color: '',
      focus_area_id: selectedFocusarea,
    },
  ])
  const [color, setColor] = useState('#cecece')

  useEffect(() => {
    getConstructs(selectedFocusarea).then((res: any) => {
      setConstruct([])
      const { data } = res.data
      // console.log(data)
      setConstruct(
        data.sort((a: any, b: any) =>
          a.serial_number.localeCompare(b.serial_number),
        ),
      )
    })
  }, [selectedFocusarea])

  const handleAdd = (): void => {
    const tmp = [...construct]
    if (tmp.length < 7) {
      const t = {
        serial_number: `A${construct.length + 1}`,
        name: '',
        short_name: '',
        deleted: false,
        color: '#cecece',
        focus_area_id: selectedFocusarea,
      }
      tmp.push(t)
      setConstruct(tmp)
    }
  }

  const updateName = (index: any, name: any): void => {
    const tmp = [...construct]
    tmp[index].name = name
    setConstruct(tmp)
  }
  const updateShortName = (index: any, name: any): void => {
    const tmp = [...construct]
    tmp[index].short_name = name
    setConstruct(tmp)
  }

  const updateColor = (index: any, colorCode: any): void => {
    const tmp = [...construct]
    tmp[index].color = colorCode
    setConstruct(tmp)
    setColor(colorCode)
  }

  const handleRemove = (index: any): void => {
    const tmp = [...construct]
    tmp[index].deleted = true
    // console.log(tmp[index])
    // tmp.splice(index, 1)
    setConstruct(tmp)
  }

  const saveConstruct = (): void => {
    updateConstructs(selectedFocusarea, construct).then((res: any) => {
      notification.success({
        message: 'Suceesfully saved',
        description: 'Construct suceesfully saved',
      })
      addNewSlice()
    })
  }

  const changeConstruct = (data: any): void => {
    updateConstructs(selectedFocusarea, data).then((res: any) => {
      console.log('success')
    })
  }

  return (
    <div className=" pt-0 focus-area-form-popup">
      <div className="p-3 construct-tab" style={{ height: 'auto' }}>
        {construct
          .filter((item) => !item.deleted)
          .map((data, index) => {
            return (
              <div key={data.serial_number + index.toString()}>
                <div className="construct-type">{data.serial_number}</div>
                {/* <div className="d-flex justify-content-between bg-white"> */}
                <div className="row bg-white">
                  <div className="col-12">
                    <Input
                      placeholder="Enter Name"
                      defaultValue={data.name}
                      onChange={(e: any) => updateName(index, e.target.value)}
                      className="popup-sidebar-form-input w-100 m-2"
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      placeholder="Short Name"
                      defaultValue={data.short_name}
                      onChange={(e: any) =>
                        updateShortName(index, e.target.value)
                      }
                      className="popup-sidebar-form-input w-100 m-2"
                    />
                  </div>
                  <div className="col-5">
                    {/* <Input type="color" className="popup-sidebar-form-input" /> */}
                    <div className="color-selector mt-2 me-1 w-100 position-relative">
                      <div className="color-picker position-absolute">
                        <span
                          className="circle"
                          style={{ background: data.color }}
                        />
                        <input
                          type="color"
                          defaultValue={data.color || '#cecece'}
                          onChange={(e: any) =>
                            updateColor(index, e.target.value)
                          }
                          className="hidden"
                        />
                      </div>
                      <input
                        type="text"
                        value={data.color}
                        onChange={(e: any) =>
                          updateColor(index, e.target.value)
                        }
                        className="w-75"
                      />
                    </div>
                  </div>
                  <div className="col-1">
                    <Popconfirm
                      title="Are you sure to remove this construct?"
                      onConfirm={() => handleRemove(index)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined
                        className="cursor-pointer"
                        style={{
                          fontSize: '20px',
                          marginTop: '12px',
                          marginRight: '6px',
                        }}
                      />
                    </Popconfirm>
                  </div>
                </div>
              </div>
            )
          })}
        <div className="text-center mt-3">
          <Button
            icon={<PlusOutlined />}
            className="btn border"
            onClick={() => handleAdd()}
          >
            Add More
          </Button>
        </div>
      </div>
      <div className="w-100 bg-EDECEC p-2 mt-3">
        <b>Please Note: </b>
        <p>You can add only 7 construct</p>
      </div>
      <div className="text-end p-3">
        <Button
          className="bg-theme-save-framwork-popup"
          onClick={() => saveConstruct()}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
