/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { Input } from 'antd'

interface Props {
  placeholder: string
  name: string
  updateQuestionList: any
  forWhat: string
  value?: any
  labelValue?: any
  currentLablePosition?: any
  selectedQuestion?: any
}

export const Label: React.FC<Props> = ({
  placeholder,
  name,
  updateQuestionList,
  forWhat,
  value,
  labelValue,
  currentLablePosition,
  selectedQuestion,
}): JSX.Element => {
  const [isLabelEditableP, setIsLabelEditableP] = useState(false)
  return (
    <div className="d-flex justify-content-space-between">
      <Input
        onClick={() => setIsLabelEditableP(true)}
        onBlur={(e) => {
          setIsLabelEditableP(false)
          updateQuestionList(e.target.value, forWhat, currentLablePosition)
        }}
        placeholder={placeholder}
        name={name}
        key={name + forWhat}
        addonAfter={forWhat === 'question' ? 'Question' : 'Label'}
        draggable="false"
        defaultValue={value}
        className={
          isLabelEditableP
            ? 'editable-label mt-1 mb-1 me-1 ms-1 p-0'
            : 'non-editable-label mt-1 mb-1 me-1 ms-1 p-0'
        }
      />
      {(forWhat === 'multichioce-options' ||
        forWhat === 'checkbox-options' ||
        forWhat === 'dropdown-options') && (
        <Input
          onClick={() => setIsLabelEditableP(true)}
          onBlur={(e) => {
            setIsLabelEditableP(false)
            updateQuestionList(
              e.target.value,
              forWhat,
              currentLablePosition,
              'yes',
            )
          }}
          placeholder="Write Value eg. 0,1,3..."
          addonAfter="Values"
          name={name + currentLablePosition}
          key={name + forWhat + currentLablePosition}
          draggable="false"
          defaultValue={labelValue}
          className={
            isLabelEditableP
              ? 'editable-label mt-1 mb-1 me-1 ms-1 p-0'
              : 'non-editable-label mt-1 mb-1 me-1 ms-1 p-0'
          }
        />
      )}
    </div>
  )
}
