/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Alert, Typography, Modal } from 'antd'
import { Button } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import './style/login.scss'
import { changePassword } from '../../api/API'
import { readCookie } from '../../api/CookieScript'
import Form from '../../components/Form'
import FormItem from '../../components/FormItem'
import Image from '../../components/Image'
import Password from '../../components/InputElements/Password'
import girlPic from '../../assets/images/login-text-img.png'
import lockIcon from '../../assets/images/Icon-material-lock.svg'
import logo from '../../assets/images/Group 9409.svg'
import Label from '../../components/Label'
import Link from '../../components/Link'
import SuccessImg from '../../assets/images/Group 9355.svg'

import {
  CONTACT_US,
  PRODUCT_DESCRIPTION,
  PRODUCT_DESCRIPTION_LINE2,
  RESET_YOUR_PASSWORD,
  CHANGE_YOUR_PASSWORD,
  CHANGE_PASSWORD,
  ENTER_PASSWORD,
  PASSWORD_NOT_MATCH,
  TOKEN_INVALID,
} from '../../constants'

export const ChangePassword: React.FC = (): JSX.Element => {
  const history = useHistory()
  const params = useParams<any>()
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [userId, setUserID] = useState('')
  const [forgotToken, setForgotToken] = useState('')
  const { Text } = Typography

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  useEffect(() => {
    const token = readCookie('gm360_token')
    if (token) {
      history.push('/dashboard')
    }
    setUserID(params.id)
    setForgotToken(params.token)
  }, [])

  const handleChangePassword = () => {
    if (rePassword === '' || password === '') {
      setErrorMsg(ENTER_PASSWORD)
      setIsError(true)
    } else if (rePassword !== password) {
      setErrorMsg(PASSWORD_NOT_MATCH)
      setIsError(true)
    } else {
      changePassword(password, rePassword, userId, forgotToken)
        .then((res: any) => {
          setIsModalVisible(true)
        })
        .catch((error: any) => {
          if (error.response.status === 404) {
            setErrorMsg(TOKEN_INVALID)
            setIsError(true)
          }
          if (error.response.status === 400) {
            setErrorMsg(PASSWORD_NOT_MATCH)
            setIsError(true)
          }
        })
    }
    // setIsModalVisible(true)
  }

  const updateRePassword = (e: any): void => {
    setRePassword(e)
  }
  const updatePassword = (e: any): void => {
    setPassword(e)
  }

  return (
    <div className="login-container">
      <div className="col-1">
        <div className="col-1-section">
          <div className="innerSecWrap">
            <div className="col-1-section-1 headLftTop">
              <Image src={girlPic} alt="girl hold books" />
              <p>
                {PRODUCT_DESCRIPTION}
                <br />
                {PRODUCT_DESCRIPTION_LINE2}
              </p>
              <br />
              <Link url="/user-inquiry" target="">
                <button type="button" className="btn-lg btn-custome btn-theme">
                  {CONTACT_US}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 position-relative">
        <div className="col-2-section">
          <div className="col-2-section-1">
            <div className="logoWrap">
              <Image src={logo} alt="GrowMetrix" imgClass="logoImg" />
            </div>
          </div>
          <div className="col-2-section-2">
            <Label
              title={RESET_YOUR_PASSWORD}
              labelClass="heading-h4 fw-bold text-black"
            />
            <br />
            <Label title={CHANGE_YOUR_PASSWORD} labelClass="heading-h4" />
          </div>
          <Text
            className="text-danger"
            style={{ display: isError ? 'block' : 'none' }}
          >
            {errorMsg}
          </Text>
          <Form>
            <FormItem formItemClass="mb-4">
              <Password
                placeholder="New Password"
                onChange={(e: any) => updatePassword(e)}
                prefix={
                  <Image src={lockIcon} alt="Password icon" preview={false} />
                }
              />
            </FormItem>
            <FormItem formItemClass="mb-4">
              <Password
                placeholder="Re-Type New Password"
                onChange={(e: any) => updateRePassword(e)}
                prefix={
                  <Image src={lockIcon} alt="Password icon" preview={false} />
                }
              />
            </FormItem>
            <FormItem>
              <button
                type="button"
                // eslint-disable-next-line no-return-assign
                onClick={() => handleChangePassword()}
                // onClick={() => (window.location.href = '/dashboard')}
                className="btn-lg w-100 btn-custome"
              >
                {CHANGE_PASSWORD}
              </button>
            </FormItem>
          </Form>
        </div>
        {/* Footer area */}
        <div id="footer_data">
          <div id="footer_description">
            <Text>Copyright 2021 GrowMetrix PTY LTD.All Rights Reserved.</Text>
          </div>
          <div id="footer_menus">
            <a href="#"> TERMS & CONDITIONS</a>
            <a href="#"> COOKIE POLICY</a>
            <a href="#"> PRIVACY POLICY</a>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        width="556px"
        centered
        closable={false}
        maskClosable={false}
      >
        <div className="modalWrapper">
          <img src={SuccessImg} alt="success" className="mt-4" />
          <span className="modalText mt-5 mb-5">
            You have successfully changed your password
          </span>
          <Link url="/login" target="">
            <Button className="btn-lg btn-custome mb-4 bg-theme">
              <span className="modalBtnText">Back to Login</span>
            </Button>
          </Link>
        </div>
      </Modal>
    </div>
  )
}
