/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Table, Button, Modal, Drawer } from 'antd'
import { useState, useEffect } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  EyeOutlined,
  EditOutlined,
  CloseOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import {
  getListOfDistribution,
  distributionsFilter,
  closeDistribution,
} from '../../../../../api/API'
import MoreImg from '../../../../../assets/images/more.svg'
import { EditProjectDetailFrom } from './EditProjectDetailForm'
import FilterBar from './filterbar'

interface Props {
  setUpdateTableData: any
}

// export const ProjectTable: React.FC = () => {
export const ProjectTable: React.FC<Props> = ({ setUpdateTableData }) => {
  const params = useParams<any>()
  const { id } = params // project id
  const [DistributionList, setDistributionList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [distributionId, setDistributionId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [isReadOnly, setIsReadOnly] = useState(false)

  const closeDestributionProject = (
    clodeDistributionid: any,
    currentStatus: any,
  ): void => {
    const data = {
      status: !currentStatus,
    }
    closeDistribution(clodeDistributionid, data).then((res: any) => {
      getDistributionData()
    })
  }

  const columns = [
    {
      title: 'SUBJECT',
      dataIndex: 'subject',
      render: (text: any, record: any) => (
        <div className="d-flex align-items-center">
          <div
            className={
              !record.status ? 'card-deactive-greydot me-1' : 'active-dot me-1'
            }
          />
          <p
            className={
              !record.status
                ? '"d-flex align-items-center text-decoration-line-through mb-0 text-primary'
                : '"d-flex align-items-center mb-0 text-primary'
            }
          >
            {text}
          </p>
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'distribution_id',
    },
    {
      title: 'SENT DATE',
      dataIndex: 'date',
      render: (text: any, data: any) => (
        <p className="mb-0">{text.substring(0, 10)}</p>
      ),
    },
    {
      title: 'TO',
      dataIndex: ['employee_id', 'participant_id'],
      render: (text: any, record: any) => (
        <p className="mb-0">
          {record.employee_id.length + record.participant_id.length}
        </p>
      ),
    },
    {
      title: 'SENT BY',
      dataIndex: 'form_name',
      render: (text: any) => <p className="mb-0"> {text} </p>,
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (text: any, record: any) => (
        <div className="d-flex">
          {/* <p className="m-0 p-0" style={{ alignSelf: 'center' }}>
            {text}
          </p> */}
          <DropdownButton
            title={<img src={MoreImg} alt="menu" className="w-75" />}
            className="more-outlined ms-3"
          >
            <Dropdown.Item
              onClick={() => {
                setIsReadOnly(true)
                setDistributionId(record._id)
                setIsModalVisible(true)
              }}
            >
              <span className="pe-2">
                <EyeOutlined />
              </span>
              View
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setDistributionId(record._id)
                setIsReadOnly(false)
                setIsModalVisible(true)
              }}
            >
              <span className="pe-2">
                <EditOutlined />
              </span>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                closeDestributionProject(record._id, record.status)
              }}
            >
              <span className="pe-2">
                <CloseOutlined />
              </span>
              {!record.status ? 'Active' : 'Deactive'}
            </Dropdown.Item>
            {/* <Dropdown.Item href="#/action-3">
              <span className="pe-2">
                <ClockCircleOutlined />
              </span>
              Set Reminder
            </Dropdown.Item> */}
          </DropdownButton>
        </div>
      ),
    },
  ]

  const getDistributionData = (): any => {
    const data = {
      page_no: currentPage,
    }
    getListOfDistribution(id, data).then((res: any): any => {
      setDistributionList(res.data.data.docs)
      setTotalPages(res.data.data.totalPages)
    })
  }

  const filterProject = (key: string, value: any): void => {
    const existingQury = filterQuery
    if (key === 'name') {
      existingQury[key] = value.target.value
    }
    if (key === 'mode') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }

    if (key === 'company') {
      existingQury[key] = value
    }

    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }
    if (key === 'date_added' && value === null) {
      existingQury[key] = ''
    }

    if (key === 'name' && value.target.value === '') {
      getDistributionData()
      return
    }

    setFilterQuery(existingQury)

    distributionsFilter(id, existingQury).then((res: any): any => {
      setDistributionList(res.data.data)
    })
  }

  useEffect(() => {
    getDistributionData()
  }, [currentPage, setUpdateTableData])

  const onClose = (): any => {
    setIsModalVisible(false)
  }

  return (
    <>
      <FilterBar
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        filterProject={filterProject}
      />
      <Table
        // scroll={{ x: true }}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={DistributionList}
        pagination={false}
        rowKey="_id"
        className="mt-3 h-100"
      />
      <Drawer
        title={
          <>
            {isReadOnly ? (
              <>
                <span className="me-2">
                  <EyeOutlined />
                </span>
                <span>View Distribution</span>
              </>
            ) : (
              <>
                <span className="me-2">
                  <EditOutlined />
                </span>
                <span>Edit Distribution</span>
              </>
            )}
          </>
        }
        visible={isModalVisible}
        onClose={onClose}
        // onOk={handleOk}
        // onCancel={handleCancel}
        width={window.innerWidth > 550 ? 465 : '100%'}
        footer={null}
        className="editProjectDrawer"
      >
        <EditProjectDetailFrom
          setIsModalVisible={setIsModalVisible}
          distributionId={distributionId}
          isReadOnly={isReadOnly}
          isRandom={Math.random()}
        />
      </Drawer>
    </>
  )
}
