import React from 'react'
import './style/label.scss'

interface Props {
  title: string
  style?: React.CSSProperties
  forValue?: string
  labelClass?: string
}

export const Label: React.FC<Props> = ({
  title,
  forValue,
  labelClass,
  style,
}): JSX.Element => {
  return (
    <label htmlFor={forValue} className={labelClass} style={style}>
      {title}
    </label>
  )
}

Label.defaultProps = {
  forValue: '',
  labelClass: 'label',
  style: {},
}
