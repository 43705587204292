/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, notification } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import '../../../style/frameworkpopup.scss'
import { addRaters, getRater, updateRater } from '../../../../../api/API'

const { TextArea } = Input

interface Props {
  setRatersDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getRaters: any
  ratersEditMode: any
  companyId: any
  ratersdrawerVisible: any
}

export const RateForm: React.FC<Props> = ({
  setRatersDrawerVisible,
  getRaters,
  ratersEditMode,
  companyId,
  ratersdrawerVisible,
}): JSX.Element => {
  // const location = useLocation<any>()
  const params = useParams<any>()
  const [color, setColor] = useState('#caecec')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  // Error State
  const [colorErr, setColorErr] = useState('')
  const [nameErr, setNameErr] = useState('')

  const handleChange = (e: any): void => {
    setColor(e.target.value)
  }

  const addNewRaters = (): void => {
    const data = {
      title: name,
      project_id: params.id,
      color,
      description,
      company_id: companyId,
    }
    addRaters(data).then((res: any) => {
      setColorErr('')
      setNameErr('')

      let valid = true
      if (color === '') {
        setColorErr('Select Color')
        valid = false
      }
      if (name === '') {
        setNameErr('Enter Name')
        valid = false
      }
      if (valid) {
        notification.success({
          message: 'Added Sucessfully',
          description: 'Rater successfully added',
        })
        setName('')
        setColor('#000000')
        setDescription('')
        setRatersDrawerVisible(false)
        getRaters()
      }
    })
  }
  // clear all error on drawer open
  useEffect(() => {
    setColorErr('')
    setNameErr('')
  }, [ratersdrawerVisible])

  useEffect(() => {
    if (ratersEditMode !== '') {
      getRater(ratersEditMode).then((res: any) => {
        const { data } = res.data
        setName(data.title)
        setColor(data.color)
        setDescription(data.description)
      })
    } else {
      setName('')
      setColor('#cecece')
      setDescription('')
    }
  }, [ratersEditMode])

  const modifyRater = (): void => {
    setColorErr('')
    setNameErr('')

    let valid = true
    if (color === '') {
      setColorErr('Select Color')
      valid = false
    }
    if (name === '') {
      setNameErr('Enter Name')
      valid = false
    }
    if (valid) {
      const data = {
        title: name,
        project_id: params.id,
        color,
        description,
        company_id: companyId,
      }
      updateRater(ratersEditMode, data).then((res: any) => {
        notification.success({
          message: 'Updated Sucessfully',
          description: 'Rater successfully updated',
        })
        setName('')
        setColor('#cecece')
        setDescription('')
        setRatersDrawerVisible(false)
        getRaters()
      })
    }
  }

  return (
    <div className="mt-4 p-3 framework-container-popups">
      <Row justify="space-between" className="mt-3" gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            Name <span className="text-danger">*</span>
          </label>
          <Input
            placeholder="Eg. Manager, Stakeholder"
            className="popup-sidebar-form-input"
            onChange={(e: any) => setName(e.target.value)}
            value={name}
          />
          <label className="text-danger">{nameErr}</label>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            Color <span className="text-danger">*</span>
          </label>
          <br />
          {/* <Input type="color" className="popup-sidebar-form-input" /> */}
          <div className="color-selector w-100 position-relative">
            <div className="color-picker position-absolute">
              <span className="circle" style={{ background: color }} />
              <input
                type="color"
                defaultValue={color || '#cecece'}
                onChange={handleChange}
                className="hidden"
              />
            </div>
            <input
              type="text"
              className="w-100 input-color"
              value={color}
              onChange={handleChange}
            />
          </div>
          <label className="text-danger">{colorErr}</label>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>Description</label>
          <TextArea
            rows={10}
            className="popup-sidebar-form-input"
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </Col>
      </Row>
      <div className="text-end p-3 raters-button">
        <Button onClick={() => setRatersDrawerVisible(false)}>Cancle</Button>
        {ratersEditMode === '' && (
          <Button
            className="bg-theme-save-framwork-popup ms-1"
            onClick={() => addNewRaters()}
          >
            Save
          </Button>
        )}
        {ratersEditMode !== '' && (
          <Button
            className="bg-theme-save-framwork-popup ms-1"
            onClick={() => modifyRater()}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  )
}
