import React, { useState, useEffect } from 'react'
import './styles/Stats.scss'
import { PieChart, Pie, Cell } from 'recharts'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'
import { EmployeeChart } from './components/employeeStats'
import Share from '../../../../assets/images/Share.svg'
import Tint from '../../../../assets/images/tint.svg'
import { RaterChart } from './components/ratersStats'

interface Props {
  company: any
}
const Stats: React.FC<Props> = ({ company }): JSX.Element => {
  const [data, setData] = useState(company)
  const [empTab, setEmpTab] = useState(true)
  const [raterTab, setRaterTab] = useState(false)

  useEffect(() => {
    setData(company)
  })

  const [radio, setRadio] = useState(1)

  return (
    <div className="stats company-detail-chart">
      <div className="stats__container">
        <div className="statshead">
          <Button
            className="statshead-item"
            id={empTab ? 'active-tab' : 'deactive-tab'}
            onClick={() => {
              setRadio(1)
              setEmpTab(true)
              setRaterTab(false)
            }}
          >
            <span className="stats-radio-title">Employees</span>
            <span className="stats-radio-desc">Check emp participation</span>
          </Button>
          <Button
            className="statshead-item radio-margin"
            onClick={() => {
              setRadio(2)
              setEmpTab(false)
              setRaterTab(true)
            }}
            id={raterTab ? 'active-tab' : 'deactive-tab'}
          >
            <span className="stats-radio-title">Raters</span>
            <span className="stats-radio-desc">Check raters participation</span>
          </Button>
        </div>
        <hr className="stats-line" />
        {/* <ProjectChart /> */}
        {radio === 1 && <EmployeeChart company={data} />}
        {radio === 2 && <RaterChart company={data} />}

        <hr className="stats-line" />
        <div className="stats-footer-wrapper">
          <div className="d-flex w-70 foot">
            <img width="15px" height="15px" alt="" src={Tint} />
            <span className="stats-footer-text1">
              You can share this graphical representation with the above listed
              data
            </span>
          </div>
          {/* <Button variant="outline-secondary">
            <div className="d-flex align-items-center justify-content-center">
              <img width="15px" height="15px" alt="" src={Share} />
              <span className="ms-1 mb-0 p-0 share">Share</span>
            </div>
          </Button> */}
        </div>
      </div>
    </div>
  )
}

export default Stats
