/* eslint-disable import/extensions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, notification, Checkbox } from 'antd'
import '../style/settings.scss'
import { changeUserPasswordApi } from '../../../api/API'
import PasswordStrengthMeter from './PasswordStrength'

export const ChangePassword: React.FC = (): JSX.Element => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPasword] = useState('')

  const [oldPasswordErr, setOldPasswordErr] = useState('')
  const [newPasswordErr, setNewPasswordErr] = useState('')
  const [confirmPasswordErr, setConfirmPaswordErr] = useState('')

  const handleSubmit = (): void => {
    setOldPasswordErr('')
    setNewPasswordErr('')
    setConfirmPaswordErr('')
    let valid = true

    if (oldPassword === '') {
      setOldPasswordErr('Please Insert Old Password')
      valid = false
    }
    if (newPassword === '') {
      setNewPasswordErr('Please Insert New Password')
      valid = false
    }
    if (confirmPassword === '') {
      setConfirmPaswordErr('Please Insert Confirm Password')
      valid = false
    }
    if (confirmPassword !== '' && newPassword !== confirmPassword) {
      setConfirmPaswordErr('Confirm Password Dont match')
      valid = false
    }
    if (valid) {
      if (confirmPassword === newPassword) {
        const data = {
          password: newPassword,
          rePassword: confirmPassword,
          oldPassword,
        }
        // console.log(data)
        changeUserPasswordApi(data).then((res: any): any => {
          const result = res.data
          console.log(result)
          if (result.status === 'error') {
            notification.error({
              message: result.message,
              description: '',
            })
          } else {
            notification.success({
              message: result.message,
              description: '',
            })
            setOldPassword('')
            setNewPassword('')
            setConfirmPasword('')
          }
        })

        setOldPasswordErr('')
        setNewPasswordErr('')
        setConfirmPaswordErr('')
      }
    }
  } //  handle Submit over
  return (
    <Row className="change-password-tab d-flex justify-content-center ">
      <Col xs={24} sm={24} xl={7} xxl={7} lg={15} md={15}>
        <h3 className="text-center">Reset Password</h3>
        <Form name="basic" autoComplete="off">
          <Form.Item>
            <label htmlFor="oldPassword" className="form-head-label">
              Old Password
              <span className="asterik">*</span>
            </label>
            <Input.Password
              onChange={(e: any) => setOldPassword(e.target.value)}
              value={oldPassword}
              allowClear
            />
            <label className="text-danger">{oldPasswordErr}</label>
          </Form.Item>

          <Form.Item>
            <label htmlFor="newPassword" className="form-head-label">
              New Password
              <span className="asterik">*</span>
            </label>
            <Input.Password
              onChange={(e: any) => setNewPassword(e.target.value)}
              value={newPassword}
              allowClear
            />
            <div className="mt-2">
              {newPassword.length >= 1 ? (
                <PasswordStrengthMeter password={newPassword} />
              ) : (
                ''
              )}
            </div>
            <label className="text-danger">{newPasswordErr}</label>
          </Form.Item>

          <Form.Item>
            <label htmlFor="Confirm Password" className="form-head-label">
              Confirm Password
              <span className="asterik">*</span>
            </label>
            <Input.Password
              onChange={(e: any) => setConfirmPasword(e.target.value)}
              // defaultValue={confirmPassword}
              value={confirmPassword}
              allowClear
            />
            <label className="text-danger">{confirmPasswordErr}</label>
          </Form.Item>

          <Form.Item className="text-center mt-4">
            <Button
              type="primary"
              onClick={handleSubmit}
              className="changePasswordBtn"
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
