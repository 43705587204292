/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import './style/inquiry.scss'
import 'antd/dist/antd.css'
import { UserOutlined, MailOutlined, PhoneFilled } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import { notification, Input, Form } from 'antd'
// import Input from '../../components/InputElements/Input'
import LeftImg from '../../assets/images/inquiry-right.png'
import emailImg from '../../assets/images/icon-material-email.svg'
import Logo from '../../assets/images/growmatrix-logo.svg'
import userTie from '../../assets/images/Icon awesome-user-tie.svg'
import phoneIcon from '../../assets/images/Icon awesome-phone-alt.svg'
import Checkbox from '../../components/Checkbox'
import Button from '../../components/Button'

import { createInquiry } from '../../api/API'

const { TextArea } = Input

interface Props {
  placeholder?: string
  label?: string
  prefix?: React.ReactNode
  handleChange?: any
  value: any
  pattern: any
}

const MyInput: React.FC<Props> = ({
  placeholder,
  label,
  prefix,
  handleChange,
  value,
  pattern,
}): JSX.Element => {
  return (
    <div className="input_container">
      <Input
        placeholder={placeholder}
        prefix={prefix}
        onChange={handleChange}
        value={value}
        pattern={pattern}
        required
      />
    </div>
  )
}
MyInput.defaultProps = {
  placeholder: 'default',
  label: 'Default',
  prefix: {},
  handleChange: () => {
    console.log('change')
  },
}

// export const UserInquiry: React.FC = (): JSX.Element => {
export const UserInquiry: React.FC = (): JSX.Element => {
  const location = useLocation()
  const [fname, setfname] = useState('')
  const [lname, setlname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [message, setmessage] = useState('')
  const [checkValue, setCheckValue] = useState(false)

  const [nameErr, setFirstnameErr] = useState('')
  const [lnameErr, setLastnameErr] = useState('')
  const [emailErr, setEmailErr] = useState('')
  const [phoneErr, setPhoneErr] = useState('')
  const [msgErr, setMsgErr] = useState('')
  const namePattern = new RegExp(/^[A-Za-z]+$/)
  const emailPattern = new RegExp(
    // eslint-disable-next-line max-len
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  )
  const patternCall = new RegExp(/^[0-9\b]+$/)
  // handle Add Inquiry method
  const handleAddInquiry = (): any => {
    let valid = true
    setFirstnameErr('')
    setLastnameErr('')
    setEmailErr('')
    setPhoneErr('')
    setMsgErr('')
    console.log(fname)
    if (fname == '' || !namePattern.test(fname)) {
      setFirstnameErr('Enter valid firstname')
      valid = false
    }

    if (lname == '' || !namePattern.test(lname)) {
      setLastnameErr('Enter valid lastname')
      valid = false
    }

    if (email == '' || !emailPattern.test(email)) {
      setEmailErr('Enter Valid Email')
      valid = false
    }

    if (phone.length == 0 || phone.length != 10 || !patternCall.test(phone)) {
      setPhoneErr('Enter Valid Phone No.')
      valid = false
    }

    if (message == '') {
      setMsgErr('Enter valid Message')
      valid = false
    }

    // create object of inquiry data
    if (valid) {
      const data = {
        first_name: fname,
        last_name: lname,
        email,
        phone,
        message,
        periodic_mail: checkValue,
      }
      createInquiry(data).then((res: any): any => {
        const response = res.data
        setCheckValue(false)
        if (res.status !== 200) {
          notification.open({
            type: 'error',
            message: 'Something went wrong',
          })
        } else {
          notification.open({
            type: 'success',
            message: 'Inquiry successfully Added',
          })
        }
        // console.log(response)
        console.log(data)
        setfname('')
        setlname('')
        setemail('')
        setphone('')
        setmessage('')
        setCheckValue(false)
      })
    }
  }

  const setFname = (e: any): void => {
    setfname(e.target.value)
  }
  const setLname = (e: any): void => {
    setlname(e.target.value)
  }
  const setEmail = (e: any): void => {
    setemail(e.target.value)
  }
  const setPhone = (e: any): void => {
    setphone(e.target.value)
  }
  const setcheckValue = (e: any): void => {
    setCheckValue(e.target.checked)
  }
  return (
    <div className="outer-container">
      <div className="inner-section-left col-xs-12 col-md-6">
        <img src={LeftImg} alt="Inquiry Left Banner" className="left-img" />
        <span className="left-text1">
          GrowMetrix 360 assists you further developing you and your employees
          leadership capability
        </span>
        <hr className="left-line" />
        <span className="left-text2">
          Designed by researchers and experienced consultants we deliver
          positive actionable feedback and insights
        </span>
      </div>
      <div className="inner-section-right col-xs-12 col-md-6">
        <div
          className="right-upper"
          style={{ marginTop: location.pathname !== '/help' ? '8%' : '16%' }}
        >
          {location.pathname !== '/help' && (
            <img src={Logo} alt="LOGO" className="rightLogo" />
          )}
          <span className="lower-text1">Great Move!</span>
          <span className="lower-text2">Please fill up the below form</span>
          <MyInput
            placeholder="First Name"
            prefix={<img src={userTie} alt="First Name" />}
            handleChange={setFname}
            value={fname}
            pattern={namePattern}
          />
          <label className="text-danger">{nameErr}</label>
          <MyInput
            placeholder="Last Name"
            prefix={<img src={userTie} alt="Last Name" />}
            handleChange={setLname}
            value={lname}
            pattern={namePattern}
          />
          <label className="text-danger">{lnameErr}</label>
          <MyInput
            placeholder="Work Email"
            prefix={<img src={emailImg} alt="email" />}
            handleChange={setEmail}
            value={email}
            pattern={emailPattern}
          />
          <label className="text-danger">{emailErr}</label>
          <MyInput
            placeholder="Phone"
            prefix={<img src={phoneIcon} alt="phone" />}
            handleChange={setPhone}
            value={phone}
            pattern={patternCall}
          />
          <label className="text-danger">{phoneErr}</label>
          <TextArea
            rows={4}
            placeholder="Enter Your Message"
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            className="textareamsg"
          />
          <label className="text-danger">{msgErr}</label>
          {/* <div>
            <Checkbox checkboxClass="inquiry-checkbox" onClick={setcheckValue} checked={checkValue}>
              <span className="check-text">
                Send me periodic emails with news, insights, and event invites
              </span>
            </Checkbox>
          </div> */}
          <Button btnClass="inquiry-submit" onClick={handleAddInquiry}>
            SUBMIT
          </Button>
          <div className="switch-login-container">
            <span>Already have an acccount ?</span>
            <Link to="/login">
              <span>Go to Login</span>
            </Link>
          </div>
        </div>
        {location.pathname !== '/help' && (
          <div className="right-lower">
            <div className="footer-left">
              <span className="left-text-1">
                Copyright © 2021 GrowMetrix PTY LTD. All Rights Reserved.
              </span>
            </div>
            <div className="footer-right">
              <span className="right-text-1">TERMS & CONDITIONS</span>
              <span className="right-text-2">COOKIE POLICY</span>
              <span className="right-text-2">PRIVACY POLICY</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
