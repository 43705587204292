import React from 'react'
import { Form } from 'react-bootstrap'
import { Label } from './Label'

interface Props {
  data: any
  questionNo: any
  block: any
  updateQuestionList: any
  allowMultiple: boolean
  selectedQuestion: any
}

export const MultiChoice: React.FC<Props> = ({
  data,
  questionNo,
  block,
  updateQuestionList,
  allowMultiple,
  selectedQuestion,
}): JSX.Element => {
  return (
    <div className="mt-2 mb-2">
      {data.map((options: any, index: any) => {
        return (
          <Form.Check
            label={
              <Label
                placeholder={options.text}
                name={`multiselect_question_of_${index}_${questionNo}_${block}`}
                updateQuestionList={updateQuestionList}
                forWhat="multichioce-options"
                value={options.text}
                labelValue={options.value}
                currentLablePosition={index}
                selectedQuestion={selectedQuestion}
              />
            }
            type={allowMultiple ? 'checkbox' : 'radio'}
            name={`matrix_table_scale_point_radio_${index}_${questionNo}_${block}`}
            // eslint-disable-next-line react/no-array-index-key
            key={questionNo + index}
          />
        )
      })}
    </div>
  )
}
