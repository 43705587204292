/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Row, Col } from 'antd'
import { UserCard } from './components/userCard'

interface Props {
  userlist: any
  getDetail: any
  getEmployee: any
}

export const UserGrid: React.FC<Props> = ({
  userlist,
  getDetail,
  getEmployee,
}): JSX.Element => {
  return (
    <>
      <Row gutter={[6, 6]}>
        {userlist.length > 0 &&
          userlist.map((user: any) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={user._id}>
              <UserCard
                data={user}
                getDetail={getDetail}
                getEmployee={getEmployee}
              />
            </Col>
          ))}
      </Row>
    </>
  )
}

export default UserGrid
