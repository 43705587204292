/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { Table, Button, Spin } from 'antd'
import { useState, useEffect, useRef } from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Doughnut, defaults } from 'react-chartjs-2'
import { useHistory, Link, useParams } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import more from '../../../assets/images/more.svg'
import {
  getReportProjectTableData,
  getEmployeeReportTableDataApi,
  currentEmployeeDetail,
  logout,
  exportDataToCSV,
  filterEmployeeReportTableData,
  filterCompanyReportTableData,
} from '../../../api/API'
import { readCookie } from '../../../api/CookieScript'
import { ProjectFilterBar } from '../ProjectFilterBar'
import {
  EMPLOYEE_ROLE,
  COMPANY_ROLE,
  ROLE_KEYWORD,
} from '../../../constants/index'

// const icon = {
//   datasets: [
//     {
//       data: [80, 20],
//       backgroundColor: ['#008174', 'transparent'],
//       hoverOffset: 0,
//     },
//   ],
// }

export const ProjectTable: React.FC = (): JSX.Element => {
  // export const ProjectTable: React.FC<Props> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(0)
  const [employeeID, setEmployeeId] = useState('')
  const [state, setState] = useState({ selectedRowKeys: [] })
  const params = useParams<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [csvData, setCsvData] = useState<any>([])
  const [csvHeader, setHeader] = useState<any>([])
  const [csvFileName, setCsvFileName] = useState('file.csv')
  const myRef = useRef<any>()
  const [filterQuery, setFilterQuery] = useState<any>({})
  const { id } = params // project id

  const history = useHistory()
  const [data, setData] = useState<any>([])

  useEffect(() => {
    callapifordata()
  }, [currentPage])

  const employeeROle = (): void => {
    const pagedata = {
      page_no: currentPage,
    }
    getEmployeeReportTableDataApi(pagedata).then((res: any) => {
      // console.log(res.data.data)
      setData(res.data.data.data)
      setTotalPages(res.data.data.total_pages)
      // setCompleteSurvey(res.data.data.data.surveyPersentage)
      // setNotCompleteSurvey(100 - res.data.data.data.surveyPersentage)
    })

    // get current login user emloyee id
    currentEmployeeDetail().then((res: any) => {
      setEmployeeId(res.data.data._id)
    })
  }

  const companyRole = (): void => {
    const pagedata = {
      page_no: currentPage,
    }
    getReportProjectTableData(pagedata).then((res: any) => {
      // console.log(res.data.data)
      setData(res.data.data.data)
      setTotalPages(res.data.data.total_pages)
      // setCompleteSurvey(res.data.data.data.surveyPersentage)
      // setNotCompleteSurvey(100 - res.data.data.data.surveyPersentage)
      console.log(res.data.data.total_pages)
    })
  }

  const callapifordata = (): void => {
    // this api call if login user is company
    if (readCookie(ROLE_KEYWORD) === COMPANY_ROLE) {
      companyRole()
    }

    // this api call if login user is employee
    if (readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE) {
      employeeROle()
    }
  }

  const exportDataToCsv = (projectId: any, record: any): void => {
    setLoading(true)
    const data1 = {
      employee_id: '',
    }
    exportDataToCSV(projectId, data1).then((result: any) => {
      setLoading(false)
      setHeader(result.data.data.header)
      setCsvData(result.data.data.data)
      setCsvFileName(`${record.project_name}.csv`)
      myRef.current.link.click()
    })
  }

  // table colmns
  const columns = [
    {
      title: 'PROJECT NAME',
      dataIndex: 'project_name',
      render: (text: any, record: any) => (
        <Link to={{ pathname: `/project-detail/${record.id}` }}>{text}</Link>
      ),
    },
    {
      title: 'PROJECT ID',
      dataIndex: 'projectID',
    },
    {
      title: 'EMPLOYEES',
      dataIndex: 'employees',
    },
    {
      title: 'FOCUS AREA',
      dataIndex: 'focusarea',
    },
    {
      title: 'CONSTRUCTS',
      dataIndex: 'construct',
    },
    {
      title: 'RATER CATEGORIES',
      dataIndex: 'raterscategory',
    },
    {
      title: 'CREATED ON',
      dataIndex: 'created_at',
    },
    {
      title: 'RESPONSES',
      dataIndex: 'responses',
    },
    // {
    //   title: 'SURVEY',
    //   dataIndex: 'surveyPersentage',
    // render: (record: any) => {
    //   return (
    //     <div className="card1-chart-wrapper">
    //       <div className="label-wrapper">
    //         <span>{record ? record.surveyPersentage : 0}</span>
    //       </div>
    //       <div className="chart-wrapper">
    //         <Doughnut data={record ? record.surveyPersentage : 0} />
    //       </div>
    //     </div>
    //   )
    // },
    // },
    // {
    //   title: 'RESPONSES',
    //   dataIndex: 'responses',
    //   render: (text: any) => <a href="/project-detail"> 0 from 9 </a>,
    // },
    // {
    //   title: 'SURVEY',
    //   dataIndex: 'servrey',
    //   render: (record: any) => {
    //     return (
    //       <div className="card1-chart-wrapper">
    //         <div className="label-wrapper">
    //           <span>80%</span>
    //         </div>
    //         <div className="chart-wrapper">
    //           <LableChart />
    //         </div>
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Contact Person',
      dataIndex: 'contact_person',
    },
    {
      render: (text: any, record: any) => (
        <>
          <div className="d-flex justify-content-between">
            <Link to={`/reportPdf/${record.id}/project`}>
              <Button> Download Report</Button>
            </Link>

            <Button
              onClick={() => {
                exportDataToCsv(record.id, record)
              }}
            >
              Download CSV
            </Button>

            <CSVLink
              headers={csvHeader}
              data={csvData}
              ref={myRef}
              filename={csvFileName}
            />
          </div>
        </>
      ),
    },
  ]
  const onSelectChange = (selectedRowKeys: any): any => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys)
    setState({ selectedRowKeys })
  }

  const filterCompany = (key: string, value: any): void => {
    // controller.abort()
    console.log('c')

    const existingQury = filterQuery
    if (key === 'name' && value.target.value !== '') {
      existingQury[key] = value.target.value
    }
    if (key === 'status' && value !== 'all') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }
    if (key === 'companyId' && value !== 'all') {
      existingQury[key] = value
    }
    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }
    if (key === 'date_added' && value === null) {
      callapifordata()
      return
    }
    if (key === 'name' && value.target.value === '') {
      callapifordata()
      return
    }
    if (key === 'mode' && value === 'all') {
      callapifordata()
      return
    }
    if (key === 'companyId' && value === 'all') {
      callapifordata()
      return
    }
    setFilterQuery(existingQury)

    if (readCookie(ROLE_KEYWORD) === 'company') {
      filterCompanyReportTableData(existingQury).then((res: any) => {
        // console.log(res.data.data)
        setData(res.data.data.data)
        setTotalPages(res.data.data.total_pages)
      })
    } else if (readCookie(ROLE_KEYWORD) === 'employee') {
      filterEmployeeReportTableData(existingQury).then((res: any) => {
        // console.log(res.data.data)
        setData(res.data.data.data)
        setTotalPages(res.data.data.total_pages)
      })
    }

    // get current login user emloyee id
    currentEmployeeDetail().then((res: any) => {
      setEmployeeId(res.data.data._id)
    })
  }

  return (
    <>
      {loading && <Spin className="spinner-code" />}
      <ProjectFilterBar
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        filterData={filterCompany}
      />
      <Table
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        className="pt-3"
      />
    </>
  )
}
