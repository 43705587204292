/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Select, notification, Button } from 'antd'
import CameraIcon from '../../../../../../assets/images/Icon feather-camera.svg'
import UserIcon from '../../../../../../assets/images/Icon awesome-user-circle.svg'
import { updateEmployeeDetail } from '../../../../../../api/API'

interface Props {
  getProjectsEmployee: any
  data: any
  isEdit: boolean
  setDrawerVisible: any
  setIsEdit: any
}

export const Detail: React.FC<Props> = ({
  getProjectsEmployee,
  data,
  isEdit,
  setDrawerVisible,
  setIsEdit,
}): JSX.Element => {
  const children = []
  const [logo, setlogo] = useState<any>()
  const [priviewImg, setPriviewImg] = useState<any>(data.image)
  const [name, setName] = useState(data.name)
  const [firstName, setFirstName] = useState(data.first_name)
  const [lastName, setLastName] = useState(data.last_name)
  const [email, setEmail] = useState(data.email)
  const [phone, setPhone] = useState(data.phone)
  const [rerenderState, setRerenderState] = useState(Math.random())

  // eslint-disable-next-line no-plusplus
  for (let i = 10; i < 36; i++) {
    children.push(
      // eslint-disable-next-line react/jsx-no-undef
      `<Option key=${i.toString(36) + i}>${i.toString(36) + i}</Option>`,
    )
  }

  useEffect(() => {
    setPriviewImg(data.image)
    setName(data.name)
    setFirstName(data.first_name)
    setLastName(data.last_name)
    setEmail(data.email)
    setTimeout(() => {
      setRerenderState(Math.random())
    }, 100)
    setPhone(data.phone)
  }, [data])

  const handleSubmit = (): void => {
    const formData = new FormData()
    formData.append('image', logo)
    formData.append('name', name)
    formData.append('first_name', firstName)
    formData.append('last_name', lastName)
    formData.append('email', email)
    formData.append('phone', phone)

    // eslint-disable-next-line no-underscore-dangle
    updateEmployeeDetail(formData, data._id).then((res: any) => {
      const result = res.data
      getProjectsEmployee()
      setIsEdit(false)
      setDrawerVisible(false)
      notification.success({
        message: result.message,
        description: '',
      })
      setName('')
      setFirstName('')
      setLastName('')
      setlogo('')
      setEmail('')
      setPhone('')
      setRerenderState(Math.random())
      setTimeout(() => {
        setRerenderState(Math.random())
      }, 100)
    })
  }

  const imagePreview = (e: any): void => {
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPriviewImg(objectUrl)
    setlogo(e.target.files[0])
  }

  return (
    <Form
      className="mt-2 pt-0 user-list-container-popup"
      onFinish={handleSubmit}
      key={rerenderState}
    >
      <div className="d-flex justify-content-around align-items-center">
        <div className="position-relative">
          <p className="text-center">My Profile</p>
          <label htmlFor="myfile">
            <img
              style={{ cursor: 'pointer', objectFit: 'contain' }}
              className="profile-pic"
              src={priviewImg || UserIcon}
              alt="profile-pic"
            />
            <label htmlFor="myfile" className="filebutton">
              <img
                style={{ cursor: 'pointer' }}
                src={CameraIcon}
                alt="Camrea"
                className="w-60 mt-1"
              />
              <span>
                <input
                  key="file"
                  type="file"
                  id="myfile"
                  disabled={isEdit}
                  onChange={(e) => imagePreview(e)}
                />
              </span>
            </label>
          </label>
        </div>
        <p className="w-50 bg-EDECEC p-2 profile-pic-rule">
          please make sure the profile pic file must be less then 1mb and in a
          dimentation of 150 x 150
        </p>
      </div>
      <Row justify="space-between" className="mt-3" gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>
            Name <span className="text-danger">*</span>
          </label>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please enter full name' }]}
          >
            <Input
              placeholder="Name"
              className="popup-sidebar-form-input"
              defaultValue={name}
              key="firstName"
              disabled={isEdit}
              onChange={(e: any) => setName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            First Name <span className="text-danger">*</span>
          </label>
          <Form.Item
            rules={[{ required: false, message: 'Please Enter First Name' }]}
          >
            <Input
              placeholder="First Name"
              className="popup-sidebar-form-input"
              defaultValue={firstName}
              key="firstName"
              disabled={isEdit}
              onChange={(e: any) => setFirstName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <label>
            Last Name <span className="text-danger">*</span>
          </label>
          <Form.Item
            rules={[{ required: false, message: 'Please Enter Last Name' }]}
          >
            <Input
              placeholder="Last Name"
              className="popup-sidebar-form-input"
              defaultValue={lastName}
              key="lastName"
              disabled={isEdit}
              onChange={(e: any) => setLastName(e.target.value)}
            />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>
            Permissions <span className="text-danger">*</span>
          </label>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={['a10', 'c12']}
            // className="popup-sidebar-form-input"
          >
            {children}
          </Select>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>
            Email <span className="text-danger">*</span>
          </label>
          <Form.Item
            rules={[
              { required: false, type: 'email', message: 'Please Enter Email' },
            ]}
          >
            <Input
              className="popup-sidebar-form-input"
              defaultValue={email}
              key="email"
              disabled
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            rules={[{ required: false, message: 'Please Enter Phone Number' }]}
          >
            Phone
            <Input
              className="popup-sidebar-form-input"
              defaultValue={phone}
              key="phone"
              disabled={isEdit}
              onChange={(e: any) => setPhone(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isEdit && (
        <div className="w-100 d-flex justify-content-end mt-4">
          <Button
            type="default"
            onClick={() => {
              setIsEdit(true)
              setDrawerVisible(false)
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="saveButton ms-3">
            Save
          </Button>
        </div>
      )}
    </Form>
  )
}
