import React from 'react'
import { EmployeeTable } from './components/dataandanalysis/employeeTable'

export const DataAndAnalysis: React.FC = () => {
  return (
    <div className="data-analysis-container">
      <EmployeeTable />
    </div>
  )
}
