/* eslint-disable @typescript-eslint/explicit-function-return-type */
const userInfoReducer = (userInfo = [], action) => {
  switch (action.type) {
    case 'STORE_USER_INFO':
      return action.payload
    default:
      return userInfo
  }
}

export { userInfoReducer }
