/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Drawer, Popconfirm, Row, Col, notification } from 'antd'
import Nav from '../../components/Navbar'
import Footer from '../../components/Footer'
import Header from './components/header'
import { TabPanel } from './components/tabpanel'
import 'antd/dist/antd.css'
import './components/style/header.scss'
import { getCompany, removeProject, changeProjectMode } from '../../api/API'
import headIcon from '../../assets/images/Group 9264.svg'
import empHeadIcon from '../../assets/images/Group 9011.svg'
import { EmployeeForm } from './components/employeeForm'
import DrawerFooter from '../../components/Drawer/footer/drawerFooter'
import { ParticipantForm } from './components/participantForm'
import { AddProject } from './components/addProject'

export const CompanyProfile: React.FC = (): JSX.Element => {
  const params = useParams<any>()
  const history = useHistory()
  const [company, setCompany] = useState({})
  const [dataLoading, setDataLoading] = useState(false)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [projectDrawerVisible, setProjectDrawerVisible] = useState(false)
  const [participantDrawerVisible, setParticipantDrawerVisible] =
    useState(false)

  useEffect(() => {
    getCompanyDetail()
  }, [])

  const getCompanyDetail = (): void => {
    // const id = location.state // project id
    getCompany(params.id)
      .then((res: any) => {
        setCompany(res.data.data[0])
      })
      .catch((err: any) => {
        history.push('/company')
      })
  }

  const deleteProject = (pid: any): void => {
    setDataLoading(true)
    removeProject(pid)
      .then((res: any): any => {
        getCompanyDetail()
        setDataLoading(false)
      })
      .catch((error: any) => {
        getCompanyDetail()
      })
  }

  const changeMode = (pid: any, mode: boolean): void => {
    const data = {
      mode,
    }
    setDataLoading(true)
    changeProjectMode(pid, data)
      .then((res: any): any => {
        getCompanyDetail()
        notification.success({
          message: `Company successfully ${mode ? 'Activated' : 'Deactivated'}`,
        })
        setDataLoading(false)
      })
      .catch((error: any) => {
        getCompanyDetail()
      })
  }
  return (
    <div className="min-85vh">
      <div className="project-container site-drawer-render-in-current-wrapper">
        <Header
          company={company}
          getCompanyDetail={getCompanyDetail}
          setDrawerVisible={setDrawerVisible}
          setProjectDrawerVisible={setProjectDrawerVisible}
          setParticipantDrawerVisible={setParticipantDrawerVisible}
        />
        <hr />
        {company && (
          <TabPanel
            company={company}
            deleteProject={deleteProject}
            changeMode={changeMode}
            setProjectDrawerVisible={setProjectDrawerVisible}
          />
        )}
      </div>
      <div className="drawer-wrapper">
        <Drawer
          onClose={() => {
            setProjectDrawerVisible(false)
          }}
          visible={projectDrawerVisible}
          title={
            <span>
              <img
                src={headIcon}
                alt="ADD NEW PROJECT"
                className="drawer-head-icon me-3"
              />
              ADD NEW PROJECT
            </span>
          }
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <AddProject
            setProjectDrawerVisible={setProjectDrawerVisible}
            getCompanyDetail={getCompanyDetail}
          />
        </Drawer>
      </div>
      <div className="drawer-wrapper">
        <Drawer
          onClose={() => {
            setDrawerVisible(false)
          }}
          visible={drawerVisible}
          title={
            <span>
              <img
                src={empHeadIcon}
                alt="ADD/INVITE NEW EMPLOYEES"
                className="drawer-head-icon me-3"
              />
              ADD/INVITE NEW EMPLOYEES
            </span>
          }
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <EmployeeForm
            company={company}
            setDrawerVisible={setDrawerVisible}
            getCompanyDetail={getCompanyDetail}
          />
        </Drawer>
      </div>
      <div className="drawer-wrapper">
        <Drawer
          onClose={() => {
            setParticipantDrawerVisible(false)
          }}
          visible={participantDrawerVisible}
          title={
            <span>
              <img
                src={empHeadIcon}
                alt="ADD/INVITE NEW EMPLOYEES"
                className="drawer-head-icon me-3"
              />
              ADD/INVITE NEW RATER(S)
            </span>
          }
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <ParticipantForm
            company={company}
            // participantDrawerVisible={participantDrawerVisible}
            setDrawerVisible={setParticipantDrawerVisible}
            getCompanyDetail={getCompanyDetail}
          />
        </Drawer>
      </div>
    </div>
  )
}
CompanyProfile.defaultProps = {
  companyId: '',
}
