/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import '../style/drawerFooter.scss'
import Bulb from '../../../assets/images/quick_tip.svg'

const DrawerFooter: React.FC = () => {
  return (
    <div className="modal-footer">
      <img src={Bulb} alt="quick_tip" className="bulb" />
      <div className="tip-container">
        <span className="quick_tip">Quick Tip</span>
        <span className="quick_tip_desc">
          You can add employees to the organisation once the organisation is
          created and activated
        </span>
      </div>
    </div>
  )
}
export default DrawerFooter
