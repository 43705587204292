import React, { useState } from 'react'
import './styles/Stats.scss'
import { PieChart, Pie, Cell } from 'recharts'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'
import EmployeeChart from './components/employeeStats'
import Share from '../../../assets/images/Share.svg'
import Tint from '../../../assets/images/tint.svg'
import { RaterChart } from './components/ratersStats'
import { ProjectChart } from './components/projectStats'

interface Props {
  controller: any
}

const Stats: React.FC<Props> = ({ controller }) => {
  const data = [
    { name: 'Not Completed Survey', survey: 65, color: '#fedd84' },
    { name: 'Completed Survey', survey: 35, color: '#febe13' },
  ]
  const active = 0
  const [radio, setRadio] = useState(1)
  const [empTab, setEmpTab] = useState(true)
  const [raterTab, setRaterTab] = useState(false)

  return (
    <div className="stats dashborad-charts">
      <div className="stats__container mt-lg-3 mt-xl-0 mt-xxl-0">
        <div className="statshead">
          <Button
            className="statshead-item"
            onClick={() => {
              setRadio(1)
              setEmpTab(true)
              setRaterTab(false)
            }}
            id={empTab ? 'active-tab' : 'deactive-tab'}
          >
            <span className="stats-radio-title">Employees</span>
            <span className="stats-radio-desc">Check emp participation</span>
          </Button>
          <Button
            className="statshead-item radio-margin"
            onClick={() => {
              setRadio(2)
              setEmpTab(false)
              setRaterTab(true)
            }}
            id={raterTab ? 'active-tab' : 'deactive-tab'}
          >
            <span className="stats-radio-title">Raters</span>
            <span className="stats-radio-desc">Check raters participation</span>
          </Button>
          {/* <Button
            className="statshead-item radio-margin"
            onClick={() => {
              setRadio(3)
            }}
          >
            <span className="stats-radio-title">Project Management</span>
            <span className="stats-radio-desc">
              Check stats on projects’ management
            </span>
          </Button> */}
        </div>
        <hr className="stats-line" />
        {/* <ProjectChart /> */}
        {radio === 1 && <EmployeeChart controller={controller} />}
        {radio === 2 && <RaterChart controller={controller} />}
        {/* {radio === 3 && <ProjectChart />} */}

        <hr className="stats-line" />
        <div className="stats-footer-wrapper">
          <div className="d-flex w-70 foot">
            <img width="15px" height="15px" alt="" src={Tint} />
            <span className="stats-footer-text1">
              You can share this graphical representation with the above listed
              data
            </span>
          </div>
          {/* <Button variant="outline-secondary">
            <div className="d-flex align-items-center justify-content-center">
              <img width="15px" height="15px" alt="" src={Share} />
              <span className="ms-1 mb-0 p-0 share">Share</span>
            </div>
          </Button> */}
        </div>
      </div>
    </div>
  )
}

export default Stats
