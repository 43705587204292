/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import './styles/employeeStats.scss'
import { useParams } from 'react-router-dom'
import { PieChart, Pie, Cell } from 'recharts'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'
import arrowDown from '../../../../../assets/images/arrow_down.svg'
import { getEmployeeSurveyStatusApi } from '../../../../../api/API'

interface Props {
  // percentage?: number
  // data?: any[]
  company: any
}
export const EmployeeChart: React.FC<Props> = ({
  // percentage,
  // data,
  company,
}): JSX.Element => {
  // const ndata = data === undefined ? [] : data
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(100)
  const params = useParams<any>()
  const { id } = params // get company id from url

  // get chart of given company id
  useEffect(() => {
    console.log(company)
    getEmployeeSurveyStatusApi(id).then((res: any): any => {
      setCompleteSurvey(res.data.data.completedSurveyStatus)
      setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    })
  }, [])

  // fill chart data
  const data = [
    {
      name: 'Not Completed Survey',
      // survey: notCompleteSurvey,
      survey: 100 - completeSurvey,
      // notCompleteSurvey === null
      //   ? setNotCompleteSurvey(100)
      //   : 100 - completeSurvey,
      color: '#fedd84',
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: '#febe13',
    },
  ]
  return (
    <div className="emp-pie-wrapper">
      <div className="emp-statsbody">
        <div className="emp-pie">
          <PieChart width={370} height={370} className="">
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={110}
              innerRadius={55}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <span className="emp-percent">{completeSurvey}%</span>
        </div>
      </div>
      <div className="pie-label-wrapper d-flex justify-content-center">
        <div className="d-flex w-50">
          <div className="emp-dot1 me-3" />
          <span className="emp-label-text">Not Completed Survey</span>
        </div>
        <div className="d-flex">
          <div className="emp-dot2 me-3" />
          <span className="emp-label-text">Completed Survey</span>
        </div>
      </div>
    </div>
  )
}
// EmployeeChart.defaultProps = {
//   percentage: 35,
//   data: [
//     { name: 'Not Completed Survey', survey: 65, color: '#fedd84' },
//     { name: 'Completed Survey', survey: 35, color: '#fedd84' },
//   ],
// }
