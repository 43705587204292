import React, { useState } from 'react'
import { Row, Col } from 'antd'

import { ProjectTable } from './components/distribution/projectTable'
import { ProjectList } from './components/user/userlistdata'
import { ProjectDetailFrom } from './components/distribution/ProjectDetailFrom'

export const Distribution: React.FC = () => {
  const [updateTableData, setUpdateTableData] = useState(false)
  return (
    <div className="distribution-container">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} className="border p-3">
          <ProjectTable setUpdateTableData={setUpdateTableData} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} className="pt-lg-2 ps-xl-3">
          <ProjectDetailFrom setUpdateTableData={setUpdateTableData} />
        </Col>
      </Row>
    </div>
  )
}
