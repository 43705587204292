/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { Alert, Typography, Modal } from 'antd'
import './style/resetPassword.scss'
import { Button } from 'react-bootstrap'
import girlPic from '../../assets/images/login-text-img.png'
import emailIcon from '../../assets/images/icon-material-email.svg'
import logo from '../../assets/images/Group 9409.svg'
import Label from '../../components/Label'
import Input from '../../components/InputElements/Input'
import Image from '../../components/Image'
import Form from '../../components/Form'
import FormItem from '../../components/FormItem'
import Link from '../../components/Link'
import {
  CONTACT_US,
  PRODUCT_DESCRIPTION,
  PRODUCT_DESCRIPTION_LINE2,
  RESET_WELCOME,
  RESET_WELCOME_SUB,
  BACK_TO_LOGIN,
  RESET_BUTTON_TEXT,
} from '../../constants'
import { forgotPassword } from '../../api/API'
import SuccessImg from '../../assets/images/Group 9355.svg'

export const ResetPassword: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState('')
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { Text } = Typography
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const setEmailId = (e: any): void => {
    setEmail(e)
  }

  const handleSubmit = (): void => {
    setErrorMsg('')
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (email === '' || !email.match(emailRegex)) {
      setErrorMsg('Please Enter Email Address')
      setIsError(true)
    } else {
      forgotPassword(email)
        .then((res: any): any => {
          // const response = res.data
          console.log(res)
          if (res.status !== 200) {
            setIsError(true)
            setErrorMsg('Please Enter Valid Email Address')
          } else if (res.data.code === 400) {
            setIsError(true)
            setErrorMsg('Please Enter Valid Email Address')
          } else {
            setIsSuccess(true)
            setIsModalVisible(true)
          }
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 400) {
              setIsError(true)
              setErrorMsg('Please Enter Valid Email Address')
            }
          }
        })
    }
  }

  return (
    <div className="login-container">
      <div className="col-1">
        <div className="col-1-section">
          <div className="innerSecWrap">
            <div className="col-1-section-1 headLftTop">
              <Image src={girlPic} alt="girl hold books" preview={false} />
              <p>
                {PRODUCT_DESCRIPTION}
                <br />
                {PRODUCT_DESCRIPTION_LINE2}
              </p>
              <br />

              <button type="button" className="btn-lg btn-custome">
                {CONTACT_US}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="column-2 position-relative">
        <div className="col-2">
          <div className="col-2-section">
            <div className="col-2-section-1">
              <div className="logoWrap">
                <Image
                  src={logo}
                  alt="GrowMetrix"
                  preview={false}
                  imgClass="logoImg"
                />
              </div>
            </div>
            <div className="col-2-section-2">
              <Label
                title={RESET_WELCOME}
                labelClass="heading-h4 fw-bold text-black"
              />
              <br />
              <Label title={RESET_WELCOME_SUB} labelClass="heading-h4-desc" />
            </div>
            {/* <Alert
              message="We will send you mail for change your password"
              type="success"
              style={{ display: isSuccess ? 'block' : 'none' }}
            /> */}
            <Form>
              <FormItem formItemClass="mb-4">
                <Input
                  placeholder="Email"
                  prefix={<Image src={emailIcon} alt="email" preview={false} />}
                  onChange={setEmailId}
                />
                <Text
                  className="text-danger"
                  style={{ display: isError ? 'block' : 'none' }}
                >
                  {errorMsg}
                </Text>
              </FormItem>
              <FormItem>
                <div
                  className="forgot-password  justify-content-end"
                  style={{ fontSize: '1.2rem' }}
                >
                  {/* <Label forValue="rememberCheck" title="Remember me" /> */}
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link url="/login" target="">
                      {BACK_TO_LOGIN}
                    </Link>
                  }
                </div>
              </FormItem>
              <FormItem>
                <button
                  type="button"
                  className="btn-lg w-100 btn-custome"
                  onClick={handleSubmit}
                >
                  {RESET_BUTTON_TEXT}
                </button>
              </FormItem>
            </Form>
          </div>
        </div>
        {/* Footer area */}
        <div id="footer_data">
          <div id="footer_description">
            <Text>Copyright 2021 GrowMetrix PTY LTD.All Rights Reserved.</Text>
          </div>
          <div id="footer_menus">
            <a href="#"> TERMS & CONDITIONS</a>
            <a href="#"> COOKIE POLICY</a>
            <a href="#"> PRIVACY POLICY</a>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        width="556px"
        centered
        closable={false}
        maskClosable={false}
      >
        <div className="modalWrapper">
          <img src={SuccessImg} alt="success" className="mt-4" />
          <span className="modalText mt-5 mb-5">
            We will send you mail for change your password
          </span>
          <Link url="/login" target="">
            <Button className="btn-lg btn-custome mb-4 bg-theme">
              <span className="modalBtnText">Back to Login</span>
            </Button>
          </Link>
        </div>
      </Modal>
    </div>
  )
}
