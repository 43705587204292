import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  url: string
  children: React.ReactNode
  target: string
  className?: string
}

export const Anchor: React.FC<Props> = ({
  url,
  children,
  target,
  className,
}) => {
  return (
    <Link to={url} target={target} className={className}>
      {children}
    </Link>
  )
}
Anchor.defaultProps = {
  className: '',
}
