/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { Table, Tag, Space, Button } from 'antd'
import '../style/participants.scss'
import { getAllParticipation } from '../../../api/API'
import EditIcon from '../../../assets/images/Icon open-pencil.svg'
import TrashIcon from '../../../assets/images/Icon feather-trash-2.svg'

interface Props {
  getParticipantDetail: any
}

export const ParticipantList: React.FC<Props> = ({
  getParticipantDetail,
}): JSX.Element => {
  const [participantList, setParticipantList] = useState()

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'survey',
      dataIndex: 'survey_completed',
      render: (text: any) => (
        <Tag color={text === 'No' ? 'volcano' : 'geekblue'} key={text}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button onClick={() => getParticipantDetail(record._id)}>
            <img src={EditIcon} alt="Edit" className="w-75" />
          </Button>
          <Button danger>
            <img src={TrashIcon} alt="Delete" className="w-75" />
          </Button>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    getAllParticipation().then((res: any) => {
      const { data } = res.data
      setParticipantList(data)
    })
  }, [])

  return (
    <Table columns={columns} dataSource={participantList} pagination={false} />
  )
}
