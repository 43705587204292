/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Row, Col, Button, Input } from 'antd'
import {
  CopyOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusOutlined,
  BgColorsOutlined,
} from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { DropdownButton, Dropdown, Accordion } from 'react-bootstrap'
import { FreeText } from './elements/FreeText'
import { MultiChoice } from './elements/Multichoice'
import { Checkboxes } from './elements/CheckBox'
import { DropDown } from './elements/DropDown'
import { MatrixTable } from './elements/MatrixTable'
import { QuestionActions } from './elements/QuestionActions'
import { QuestionCompnent } from './elements/Question'
import { HTMLEditor } from './elements/HTMLEditor'
import Group9190 from '../../../../../assets/images/Group 9190.svg'
import moreIcon from '../../../../../assets/images/more.svg'
import MultiChoiceIcon from '../../../../../assets/images/Icon open-list-rich.svg'
import FreeTextIcon from '../../../../../assets/images/Group 9208.svg'
import CheckboxIcon from '../../../../../assets/images/Icon material-playlist-add-check.svg'
import DropdownIcon from '../../../../../assets/images/Icon awesome-spell-check.svg'
import MetrixTableIcon from '../../../../../assets/images/Icon metro-table.svg'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { v4: uuidv4 } = require('uuid')

interface Props {
  selectedBlock: any
  setSelectedBlock: React.Dispatch<React.SetStateAction<number>>
  setSelectedQuestion: React.Dispatch<React.SetStateAction<number>>
  selectedQuestion: any
  setSelectedQuestionType: React.Dispatch<React.SetStateAction<string>>
  setNumberOfChoice: React.Dispatch<React.SetStateAction<number>>
  setMetrixtableScaleArrayLength: React.Dispatch<React.SetStateAction<number>>
  setSelectedQuestionKeyFocusArea: React.Dispatch<React.SetStateAction<string>>
  setSelectedQuestionConstruct: React.Dispatch<React.SetStateAction<string>>
  handleMoveToTrash: any
  updateQuestionList: any
  questionList: any
  setQuestionList: React.Dispatch<React.SetStateAction<any[]>>
  addQuestionToList: any
  addQuestionsTodb: any
  metrixtableScaleArray: any
  setQuestionAction: React.Dispatch<React.SetStateAction<string>>
  FocusAreaData: any
  construct: any
  setSelectedQuestionRaters: React.Dispatch<React.SetStateAction<any[]>>
  moveQuestion: any
}

// eslint-disable-next-line @typescript-eslint/ban-types
// const BlockForm = forwardRef<CanShowAlert, {}>((props, ref) => {
const BlockForm: React.FC<Props> = ({
  selectedBlock,
  setSelectedBlock,
  setSelectedQuestion,
  selectedQuestion,
  setSelectedQuestionType,
  setNumberOfChoice,
  setMetrixtableScaleArrayLength,
  setSelectedQuestionKeyFocusArea,
  setSelectedQuestionConstruct,
  handleMoveToTrash,
  updateQuestionList,
  questionList,
  setQuestionList,
  addQuestionToList,
  addQuestionsTodb,
  metrixtableScaleArray,
  setQuestionAction,
  FocusAreaData,
  construct,
  setSelectedQuestionRaters,
  moveQuestion,
}) => {
  const params = useParams<any>()
  let questionCounter = 1
  const handleDuplicate = (i: any): void => {
    const values = [...questionList]
    values.splice(i, 0, values[i])
    setQuestionList(values)
  }

  const handleAddNewBlock = (): void => {
    // addQuestionToList('free-text', 'yes')
    const currentList2 = [...questionList]
    const questions = {
      questionCounter: uuidv4(),
      questionType: 'html-editor',
      questionTitle: 'Write your question here',
      htmlData: {},
      keyfocusArea: '',
      construct: '',
      options: '',
      metrixtableScaleArray: [1, 2, 3, 4, 5],
      isMandatory: false,
      allowMultiple: false,
      asktoraters: [],
      questionPosition: 1,
    }

    const newQuestion = {
      id: uuidv4(),
      blockNo: currentList2.length + 1,
      project_id: params.id, // project id
      data: [questions],
    }

    currentList2.push(newQuestion)

    setQuestionList(currentList2)
  }

  const handleRemove = (i: any): void => {
    const list = [...questionList]
    list.splice(i, 1)
    setQuestionList(list)
    // addQuestionsTodb()
  }

  const addNewQuestion = (e: any, i: number): void => {
    setSelectedQuestionType(e)
    addQuestionToList(e, questionList[i].data.length) // type, question number
  }

  const addPageBreak = (e: any, i: number): void => {
    setSelectedQuestionType(e)
    addQuestionToList(e, 'yes', 'below', i)
  }

  const addDuplicateQuestion = (
    blockIndex: number,
    questionIndex: number,
  ): any => {
    setSelectedBlock(blockIndex)
    setSelectedQuestion(questionIndex)
    const values = [...questionList]
    const { questionType } = values[blockIndex].data[questionIndex]

    // setSelectedQuestionType(questionType)
    // addQuestionToList(questionType, 'duplicate')

    // const values = [...questionList]
    // const { questionType } = values[blockNo].data[questionNo]

    setSelectedQuestionType(questionType)
    addQuestionToList(questionType, 'duplicate', 'below', questionIndex)

    // setBlock(values)
  }

  const moveQuestionToTrash = (questionid: any): any => {
    const qdb = {
      question_id: questionid,
    }
    handleMoveToTrash(qdb)
  }

  const removeQuestion = (blockNo: number, questionNo: any): any => {
    const list = [...questionList]
    console.log(questionList[blockNo].data[questionNo])
    if (list.length > 0) {
      moveQuestionToTrash(
        questionList[blockNo].data[questionNo].questionCounter,
      )
      list[blockNo].data.splice(questionNo, 1)
      setQuestionList(list)
    }
    // addQuestionsTodb()
    setQuestionAction('removed')
  }

  const handleAddAbove = (blockNo: number, questionNo: number): any => {
    const values = [...questionList]
    const { questionType } = values[blockNo].data[questionNo]

    setSelectedQuestionType(questionType)
    addQuestionToList(questionType, 'yes', 'above', questionNo)
    // setBlock(values)
  }

  const handleAddBelow = (blockNo: number, questionNo: number): any => {
    const values = [...questionList]
    const { questionType } = values[blockNo].data[questionNo]

    setSelectedQuestionType(questionType)
    addQuestionToList(questionType, 'yes', 'below', questionNo)
  }

  // for question swap

  let dragStartIndex: any
  let dragEndIndex: any

  const swapQuestion = (StartIndex: any, EndIndex: any): void => {
    const list = [...questionList]
    if (list[selectedBlock] && list[selectedBlock].data[StartIndex]) {
      const temp2 = list[selectedBlock].data[StartIndex]
      list[selectedBlock].data[StartIndex] = list[selectedBlock].data[EndIndex]
      list[selectedBlock].data[EndIndex] = temp2
      setQuestionList(list)
    }

    // addQuestionsTodb()
  }

  const dragStart = (e: any): void => {
    dragStartIndex = e.target
      .closest('.questions-container')
      .getAttribute('data-index')
    console.log('start', dragStartIndex)
  }
  const dragEnd = (e: any): void => {
    // swapQuestion(dragStartIndex, dragEndIndex)
    // console.log(dragStartIndex, dragEndIndex)
    // moveQuestion(dragStartIndex, dragEndIndex)
  }

  const dragOver = (e: any): void => {
    e.preventDefault()
  }

  const onDrop = (e: any): void => {
    e.preventDefault()
    dragEndIndex = e.target
      .closest('.questions-container')
      .getAttribute('data-index')
    console.log('stop', dragEndIndex)
    moveQuestion(dragStartIndex, dragEndIndex)
  }

  // for block swap

  let dragStartBlockIndex: any
  let dragEndBlockIndex: any

  const swapBlock = (StartIndex: any, EndIndex: any): void => {
    const list = [...questionList]
    if (list.length > 0) {
      const temp2 = list[StartIndex]
      list[StartIndex] = list[EndIndex]
      list[EndIndex] = temp2
      setQuestionList(list)
    }
    // addQuestionsTodb()
  }

  const dragStartBlock = (e: any): void => {
    dragStartBlockIndex = e.target.closest('.block').getAttribute('data-index')
  }

  const dragEndBlock = (e: any): void => {
    swapBlock(dragStartBlockIndex, dragEndBlockIndex)
  }

  const onDropBlock = (e: any): void => {
    e.preventDefault()
    dragEndBlockIndex = e.target.closest('.block').getAttribute('data-index')
  }

  const getColorCodeForFocusArea = (id: any): string => {
    const colorString = FocusAreaData.map((d: any) =>
      d._id === id ? d.color : null,
    )
    const color = colorString.toString()
    const colorf = color.replaceAll(',', '')
    return colorf
  }

  const getColorCodeFoConstruct = (focuId: any, conId: any): string => {
    const colorString = FocusAreaData.map((d: any) =>
      d._id === focuId
        ? d.constructs.map((c: any) => (c._id === conId ? c.color : ''))
        : '',
    )
    const color = colorString.toString()
    const colorf = color.replaceAll(',', '')
    return colorf
  }

  return (
    <>
      <div aria-hidden="true">
        {questionList.length > 0 &&
          questionList.map((x: any, i: any) => {
            return (
              <Accordion defaultActiveKey="0" key={x.id}>
                <div
                  className={`block-no-${i} p-2 block mb-3`}
                  key={x.id}
                  data-index={i}
                  aria-hidden="true"
                  onDrop={(e: any) => onDropBlock(e)}
                  onMouseDown={() => setSelectedBlock(i)}
                >
                  <Row className="p-2">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <span className="d-flex block-title w-fit-content text-white">
                        <span
                          className="d-flex cursor-move"
                          draggable="true"
                          onDragEnd={(e: any) => dragEndBlock(e)}
                          onDragStart={(e: any) => dragStartBlock(e)}
                          onMouseDown={() => setSelectedBlock(i)}
                        >
                          <div className="move-portion" />
                          <div className="move-portion" />
                          <div className="move-portion" />
                        </span>
                        <span className="ms-2"> block {i + 1} </span>
                      </span>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="block-button d-flex"
                    >
                      <Accordion.Toggle
                        eventKey="0"
                        className="accordion-toggle"
                      >
                        {/* <DownCircleOutlined /> */}
                        <img src={Group9190} alt="show/hide" />
                      </Accordion.Toggle>
                      <DropdownButton
                        className="comman-button"
                        title={
                          <img src={moreIcon} alt="menu" className="w-75" />
                        }
                      >
                        <Dropdown.Item onClick={() => handleRemove(i)}>
                          <DeleteOutlined className="me-2" />
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDuplicate(i)}>
                          <CopyOutlined className="me-2" />
                          Duplicate
                        </Dropdown.Item>
                      </DropdownButton>
                      <CloseOutlined onClick={() => handleRemove(i)} />
                    </Col>
                  </Row>
                  <Accordion.Collapse eventKey="0">
                    <div
                      className="input-elements p-3"
                      onDragOver={(e: any) => dragOver(e)}
                    >
                      {x.data.map((question: any, index: any) => {
                        return (
                          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                          <div
                            className="questions-container"
                            // eslint-disable-next-line react/no-array-index-key
                            key={question.questionCounter}
                            // draggable="true"
                            onDragEnd={(e: any) => dragEnd(e)}
                            onDragStart={(e: any) => dragStart(e)}
                            onDrop={(e: any) => onDrop(e)}
                            data-index={index}
                            onMouseDown={() => {
                              setSelectedBlock(i)
                              setSelectedQuestionKeyFocusArea(
                                question.keyfocusArea,
                              )
                              setSelectedQuestionConstruct(question.construct)
                            }}
                          >
                            {(question.questionType !== 'page-break' ||
                              question.questionType !== 'html-editor') && (
                              <div className="d-flex">
                                <div className="question-label qheader mt-2 ms-2">
                                  <span className="question-prefix" />
                                  Question
                                  {
                                    // eslint-disable-next-line no-plusplus
                                    questionCounter++
                                  }
                                </div>
                                {question.questionType !== 'page-break' && (
                                  <div
                                    className="key-cofus-area mt-2 ms-2"
                                    style={{
                                      display: question.keyfocusArea
                                        ? 'block'
                                        : 'none',
                                      backgroundColor: getColorCodeForFocusArea(
                                        question.keyfocusArea,
                                      ),
                                    }}
                                  >
                                    {FocusAreaData.map((d: any) =>
                                      d._id === question.keyfocusArea
                                        ? d.name
                                        : '',
                                    )}
                                    {/* {question.keyfocusArea} */}
                                    <Input
                                      type="hidden"
                                      value={question.keyfocusArea}
                                      name={`key_focus_area_of_question_${index}_${i}`}
                                    />
                                  </div>
                                )}
                                {question.questionType !== 'page-break' && (
                                  <div
                                    className="constrcuct mt-2 ms-2"
                                    style={{
                                      display: question.construct
                                        ? 'block'
                                        : 'none',
                                      backgroundColor: getColorCodeFoConstruct(
                                        question.keyfocusArea,
                                        question.construct,
                                      ),
                                    }}
                                  >
                                    {FocusAreaData.map((d: any) =>
                                      d._id === question.keyfocusArea
                                        ? d.constructs.map((c: any) =>
                                            c._id === question.construct
                                              ? c.name
                                              : '',
                                          )
                                        : '',
                                    )}
                                    <Input
                                      type="hidden"
                                      value={question.construct}
                                      name={`construct_of_question_${index}_${i}`}
                                    />
                                  </div>
                                )}
                              </div>
                            )}

                            {question.questionType === 'html-editor' && (
                              <div
                                className={`p-2 pt-2 pb-3 question d-flex html-editor ${
                                  selectedQuestion === index &&
                                  selectedBlock === i
                                    ? 'border-hilight'
                                    : 'remove-hilight'
                                }`}
                                onClick={() => {
                                  setSelectedBlock(i)
                                  setSelectedQuestion(index)
                                  setSelectedQuestionType(question.questionType)
                                  setSelectedQuestionRaters(
                                    question.asktoraters,
                                  )
                                }}
                              >
                                <div
                                  className="d-flex cursor-move"
                                  onClick={() => setSelectedBlock(i)}
                                  draggable="true"
                                >
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                </div>
                                <div className="width-fill-full ms-2 h-100">
                                  <HTMLEditor
                                    updateQuestionList={updateQuestionList}
                                    defaultEditorState={question.htmlData}
                                    questionIndex={index}
                                  />
                                  <QuestionActions
                                    handleDuplicate={addDuplicateQuestion}
                                    handleAddAbove={handleAddAbove}
                                    handleAddBelow={handleAddBelow}
                                    removeQuestion={removeQuestion}
                                    addNewQuestion={addNewQuestion}
                                    addPageBreak={addPageBreak}
                                    questionIndex={index}
                                    blockIndex={i}
                                    questionType={question.questionType}
                                  />
                                </div>
                              </div>
                            )}
                            {question.questionType === 'free-text' && (
                              <div
                                className={`p-2 pt-2 pb-3 question d-flex ${
                                  selectedQuestion === index &&
                                  selectedBlock === i
                                    ? 'border-hilight'
                                    : 'remove-hilight'
                                }`}
                                onClick={() => {
                                  setSelectedBlock(i)
                                  setSelectedQuestion(index)
                                  setSelectedQuestionType(question.questionType)
                                  setSelectedQuestionRaters(
                                    question.asktoraters,
                                  )
                                  setSelectedQuestionKeyFocusArea(
                                    question.keyfocusArea,
                                  )
                                  setSelectedQuestionConstruct(
                                    construct.length > 0
                                      ? question.construct
                                      : '',
                                  )
                                }}
                              >
                                <div
                                  className="d-flex cursor-move"
                                  onClick={() => setSelectedBlock(i)}
                                  draggable="true"
                                >
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                </div>
                                <div className="width-fill-full ms-2">
                                  <QuestionCompnent
                                    block={i}
                                    question={index}
                                    updateQuestionList={updateQuestionList}
                                    value={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].questionTitle
                                        ? questionList[i].data[index]
                                            .questionTitle
                                        : ''
                                    }
                                    setSelectedBlock={setSelectedBlock}
                                    setSelectedQuestion={setSelectedQuestion}
                                    isMandatory={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].isMandatory
                                        ? questionList[i].data[index]
                                            .isMandatory
                                        : ''
                                    }
                                  />
                                  <FreeText
                                    placeholder="Click here to wite question"
                                    name={`answer_of_question_${index}_${i}`}
                                  />
                                  <QuestionActions
                                    handleDuplicate={addDuplicateQuestion}
                                    handleAddAbove={handleAddAbove}
                                    handleAddBelow={handleAddBelow}
                                    removeQuestion={removeQuestion}
                                    addNewQuestion={addNewQuestion}
                                    addPageBreak={addPageBreak}
                                    questionIndex={index}
                                    blockIndex={i}
                                  />
                                </div>
                              </div>
                            )}
                            {question.questionType === 'multiple-choice' && (
                              <div
                                className={`p-2 pt-1 question d-flex ${
                                  selectedQuestion === index &&
                                  selectedBlock === i
                                    ? 'border-hilight'
                                    : 'remove-hilight'
                                }`}
                                onClick={() => {
                                  setSelectedBlock(i)
                                  setSelectedQuestion(index)
                                  setSelectedQuestionType(question.questionType)
                                  setSelectedQuestionRaters(
                                    question.asktoraters,
                                  )
                                  setNumberOfChoice(
                                    parseInt(question.options.length, 10),
                                  )
                                  setSelectedQuestionKeyFocusArea(
                                    question.keyfocusArea,
                                  )
                                  setSelectedQuestionConstruct(
                                    construct.length > 0
                                      ? question.construct
                                      : '',
                                  )
                                }}
                              >
                                <div
                                  className="d-flex cursor-move"
                                  onClick={() => setSelectedBlock(i)}
                                  draggable="true"
                                >
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                </div>
                                <div className="width-fill-full ms-2">
                                  <QuestionCompnent
                                    block={i}
                                    question={index}
                                    updateQuestionList={updateQuestionList}
                                    value={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].questionTitle
                                        ? questionList[i].data[index]
                                            .questionTitle
                                        : ''
                                    }
                                    setSelectedBlock={setSelectedBlock}
                                    setSelectedQuestion={setSelectedQuestion}
                                    selectedQuestion={selectedQuestion}
                                    isMandatory={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].isMandatory
                                        ? questionList[i].data[index]
                                            .isMandatory
                                        : ''
                                    }
                                  />
                                  <MultiChoice
                                    data={question.options}
                                    questionNo={index}
                                    block={i}
                                    updateQuestionList={updateQuestionList}
                                    allowMultiple={question.allowMultiple}
                                    selectedQuestion={selectedQuestion}
                                  />
                                  <QuestionActions
                                    handleDuplicate={addDuplicateQuestion}
                                    handleAddAbove={handleAddAbove}
                                    handleAddBelow={handleAddBelow}
                                    removeQuestion={removeQuestion}
                                    addNewQuestion={addNewQuestion}
                                    addPageBreak={addPageBreak}
                                    questionIndex={index}
                                    blockIndex={i}
                                  />
                                </div>
                              </div>
                            )}
                            {question.questionType === 'checkbox' && (
                              <div
                                className={`p-2 pt-1 question d-flex ${
                                  selectedQuestion === index &&
                                  selectedBlock === i
                                    ? 'border-hilight'
                                    : 'remove-hilight'
                                }`}
                                onClick={() => {
                                  setSelectedBlock(i)
                                  setSelectedQuestion(index)
                                  setSelectedQuestionType(question.questionType)
                                  setSelectedQuestionRaters(
                                    question.asktoraters,
                                  )
                                  setNumberOfChoice(
                                    parseInt(question.options.length, 10),
                                  )
                                  setSelectedQuestionKeyFocusArea(
                                    question.keyfocusArea,
                                  )
                                  setSelectedQuestionConstruct(
                                    construct.length > 0
                                      ? question.construct
                                      : '',
                                  )
                                }}
                              >
                                <div
                                  className="d-flex cursor-move"
                                  onClick={() => setSelectedBlock(i)}
                                  draggable="true"
                                >
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                </div>
                                <div className="width-fill-full ms-2">
                                  <QuestionCompnent
                                    block={i}
                                    question={index}
                                    updateQuestionList={updateQuestionList}
                                    value={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].questionTitle
                                        ? questionList[i].data[index]
                                            .questionTitle
                                        : ''
                                    }
                                    setSelectedBlock={setSelectedBlock}
                                    setSelectedQuestion={setSelectedQuestion}
                                    isMandatory={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].isMandatory
                                        ? questionList[i].data[index]
                                            .isMandatory
                                        : ''
                                    }
                                  />
                                  <Checkboxes
                                    data={question.options}
                                    block={i}
                                    updateQuestionList={updateQuestionList}
                                    allowMultiple={question.allowMultiple}
                                  />
                                  <QuestionActions
                                    handleDuplicate={addDuplicateQuestion}
                                    handleAddAbove={handleAddAbove}
                                    handleAddBelow={handleAddBelow}
                                    removeQuestion={removeQuestion}
                                    addNewQuestion={addNewQuestion}
                                    addPageBreak={addPageBreak}
                                    questionIndex={index}
                                    blockIndex={i}
                                  />
                                </div>
                              </div>
                            )}
                            {question.questionType === 'dropdown' && (
                              <div
                                className={`p-2 pt-1 question d-flex ${
                                  selectedQuestion === index &&
                                  selectedBlock === i
                                    ? 'border-hilight'
                                    : 'remove-hilight'
                                }`}
                                onClick={() => {
                                  setSelectedBlock(i)
                                  setSelectedQuestion(index)
                                  setSelectedQuestionType(question.questionType)
                                  setSelectedQuestionRaters(
                                    question.asktoraters,
                                  )
                                  setNumberOfChoice(
                                    parseInt(question.options.length, 10),
                                  )
                                  setSelectedQuestionKeyFocusArea(
                                    question.keyfocusArea,
                                  )
                                  setSelectedQuestionConstruct(
                                    construct.length > 0
                                      ? question.construct
                                      : '',
                                  )
                                }}
                              >
                                <div
                                  className="d-flex cursor-move"
                                  onClick={() => setSelectedBlock(i)}
                                  draggable="true"
                                >
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                </div>
                                <div className="width-fill-full ms-2">
                                  <QuestionCompnent
                                    block={i}
                                    question={index}
                                    updateQuestionList={updateQuestionList}
                                    value={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].questionTitle
                                        ? questionList[i].data[index]
                                            .questionTitle
                                        : ''
                                    }
                                    setSelectedBlock={setSelectedBlock}
                                    setSelectedQuestion={setSelectedQuestion}
                                    isMandatory={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].isMandatory
                                        ? questionList[i].data[index]
                                            .isMandatory
                                        : ''
                                    }
                                  />
                                  <DropDown
                                    data={question.options}
                                    block={i}
                                    updateQuestionList={updateQuestionList}
                                  />
                                  <QuestionActions
                                    handleDuplicate={addDuplicateQuestion}
                                    handleAddAbove={handleAddAbove}
                                    handleAddBelow={handleAddBelow}
                                    removeQuestion={removeQuestion}
                                    addNewQuestion={addNewQuestion}
                                    addPageBreak={addPageBreak}
                                    questionIndex={index}
                                    blockIndex={i}
                                  />
                                </div>
                              </div>
                            )}
                            {question.questionType === 'matrix-table' && (
                              <div
                                className={`p-2 pt-1 question d-flex ${
                                  selectedQuestion === index &&
                                  selectedBlock === i
                                    ? 'border-hilight'
                                    : 'remove-hilight'
                                }`}
                                onClick={() => {
                                  setSelectedBlock(i)
                                  setSelectedQuestion(index)
                                  setSelectedQuestionType(question.questionType)
                                  setSelectedQuestionRaters(
                                    question.asktoraters,
                                  )
                                  setNumberOfChoice(question.options.length - 1)
                                  setMetrixtableScaleArrayLength(
                                    metrixtableScaleArray.length,
                                  )
                                  setSelectedQuestionKeyFocusArea(
                                    question.keyfocusArea,
                                  )
                                  setSelectedQuestionConstruct(
                                    construct.length > 0
                                      ? question.construct
                                      : '',
                                  )
                                }}
                              >
                                <div
                                  className="d-flex cursor-move"
                                  onClick={() => setSelectedBlock(i)}
                                  draggable="true"
                                >
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                  <div className="move-portion" />
                                </div>
                                <div className="width-fill-full ms-2">
                                  <QuestionCompnent
                                    block={i}
                                    question={index}
                                    updateQuestionList={updateQuestionList}
                                    value={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].questionTitle
                                        ? questionList[i].data[index]
                                            .questionTitle
                                        : ''
                                    }
                                    setSelectedBlock={setSelectedBlock}
                                    setSelectedQuestion={setSelectedQuestion}
                                    isMandatory={
                                      questionList[i] &&
                                      questionList[i].data &&
                                      questionList[i].data[index] &&
                                      questionList[i].data[index].isMandatory
                                        ? questionList[i].data[index]
                                            .isMandatory
                                        : ''
                                    }
                                  />
                                  <MatrixTable
                                    data={question.options}
                                    block={i}
                                    metrixtableScaleArray={
                                      metrixtableScaleArray
                                    }
                                    allowMultiple={question.allowMultiple}
                                    updateQuestionList={updateQuestionList}
                                  />
                                  <QuestionActions
                                    handleDuplicate={addDuplicateQuestion}
                                    handleAddAbove={handleAddAbove}
                                    handleAddBelow={handleAddBelow}
                                    removeQuestion={removeQuestion}
                                    addNewQuestion={addNewQuestion}
                                    addPageBreak={addPageBreak}
                                    questionIndex={index}
                                    blockIndex={i}
                                  />
                                </div>
                              </div>
                            )}
                            {question.questionType === 'page-break' && (
                              <div
                                className="p-2 pt-1 question d-flex"
                                onClick={() => {
                                  setSelectedBlock(i)
                                  setSelectedQuestion(index)
                                  setSelectedQuestionType(question.questionType)
                                }}
                              >
                                <div className="d-flex width-fill-full ms-2">
                                  <hr className="page-break-hr" />
                                  <div className="page-break-text">
                                    Page Break
                                  </div>
                                  <hr className="page-break-hr" />
                                  <div className="page-break-remove">
                                    <CloseOutlined
                                      className="cursor-pointer"
                                      onClick={() => removeQuestion(i, index)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      })}
                      <div className="mt-3 mb-2">
                        <DropdownButton
                          className="add-question-button"
                          title={
                            <>
                              <PlusOutlined className="me-1" /> Add New Question
                            </>
                          }
                          onSelect={(e: any) => addNewQuestion(e, i)}
                        >
                          <Dropdown.Item eventKey="multiple-choice">
                            <img
                              src={MultiChoiceIcon}
                              alt="Multiple Choice"
                              className="me-2"
                            />
                            Multiple Choice
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="free-text">
                            <img
                              src={FreeTextIcon}
                              alt="FreeTextIcon"
                              className="me-2"
                            />
                            Free Text
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="checkbox">
                            <img
                              src={CheckboxIcon}
                              alt="CheckboxIcon"
                              className="me-2"
                            />
                            Checkboxes
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="dropdown">
                            <img
                              src={DropdownIcon}
                              alt="DropdownIcon"
                              className="me-2"
                            />
                            Dropdown
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="matrix-table">
                            <img
                              src={MetrixTableIcon}
                              alt="MetrixTableIcon"
                              className="me-2"
                            />
                            Matrix Table
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="html-editor">
                            <BgColorsOutlined
                              className="pe-1"
                              style={{ fontSize: '26px' }}
                            />
                            Html Editor
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            )
          })}
      </div>
      <div className="text-center mt-3">
        <Button className="btn" onClick={() => handleAddNewBlock()}>
          <PlusOutlined />
          Add Block
        </Button>
      </div>
    </>
  )
}

export default BlockForm
