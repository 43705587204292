export const UserList = [
  {
    id: 1,
    name: 'Jhon Smith ',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: true,
  },
  {
    id: 2,
    name: 'Josh Mark',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: true,
  },
  {
    id: 3,
    name: 'Jolly Peter',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: false,
  },
  {
    id: 4,
    name: 'Alena Dio',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: true,
  },
  {
    id: 5,
    name: 'Alena Dio',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: false,
  },
  {
    id: 6,
    name: 'Alena Dio',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: true,
  },
  {
    id: 7,
    name: 'Alena Dio',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: false,
  },
  {
    id: 8,
    name: 'Alena Dio',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: true,
  },
  {
    id: 9,
    name: 'Alena Dio',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: false,
  },
  {
    id: 10,
    name: 'Alena Dio',
    user_id: 'GM0001',
    document_id: 'GM02132',
    image:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    gravater:
      'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI',
    online: true,
  },
]

export const EmployessList = [
  {
    id: 1,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    reg_by: 'HR',
    reg_date: '20-JAN-2021',
    email: 'milan@techytt.com',
    phone: '+1-12(050)-9568',
    participant: '5',
    responses: '0 of 5',
    status: '0%',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    reg_by: 'HR',
    reg_date: '20-JAN-2021',
    email: 'milan@techytt.com',
    phone: '+1-12(050)-9568',
    participant: '5',
    responses: '0 of 5',
    status: '0%',
  },
  {
    id: 3,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    reg_by: 'HR',
    reg_date: '20-JAN-2021',
    email: 'milan@techytt.com',
    phone: '+1-12(050)-9568',
    participant: '5',
    responses: '0 of 5',
    status: '0%',
  },
]

export const ProjectList = [
  {
    id: 1,
    subject: 'Milan Nayak',
    project_id: 'TT0001',
    to: 'HR',
    sent_date: '20-JAN-2021',
    sent_by: 'milan@techytt.com',
  },
  {
    id: 2,
    subject: 'Milan Nayak',
    project_id: 'TT0001',
    to: 'HR',
    sent_date: '20-JAN-2021',
    sent_by: 'milan@techytt.com',
  },
  {
    id: 3,
    subject: 'Milan Nayak',
    project_id: 'TT0001',
    to: 'HR',
    sent_date: '20-JAN-2021',
    sent_by: 'milan@techytt.com',
  },
]

export const RatesList = [
  {
    id: 1,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Co Worker',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
  {
    id: 1,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Co Worker',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
  {
    id: 1,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Co Worker',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
  {
    id: 1,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Co Worker',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
  {
    id: 1,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Co Worker',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
  {
    id: 1,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Co Worker',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
  {
    id: 2,
    emp_name: 'Milan Nayak',
    emp_id: 'TT0001',
    surveys: '02',
    designation: 'Manager',
  },
]
