/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Input } from 'antd'
import './style/password.scss'

interface Props {
  onChange?: any
  // inputType?: string
  // disabled?: boolean
  placeholder?: string
  // inputField?: string
  // inputIcon?: string
  // inputId?: string
  // icon?: boolean
  prefix?: React.ReactNode
}

export const PasswordComponent: React.FC<Props> = ({
  onChange,
  // inputType,
  // disabled,
  placeholder,
  // inputField,
  // inputIcon,
  // inputId,
  // icon,
  prefix,
}): JSX.Element => {
  return (
    <>
      {/* {icon && renderIcons()}
      <input
        className={icon ? 'icons-alignment' : ''}
        type={inputType}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        id={inputId}
      /> */}
      <Input.Password
        placeholder={placeholder}
        prefix={prefix}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  )
}

PasswordComponent.defaultProps = {
  // inputType: 'text',
  // disabled: false,
  placeholder: '',
  prefix: {},
  // inputField: '',
  // inputIcon: '',
  // inputId: '',
  // icon: false,
  // // eslint-disable-next-line @typescript-eslint/no-empty-function
  // onChange: () => {},
}
