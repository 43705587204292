/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { notification, Form, Input, Button, Upload, Select } from 'antd'
import {
  getAllRaters,
  getMyProjectEmployeeLists,
  updateParticipationApi,
} from '../../../../../../api/API'
import CheckBox from '../../../../../../components/Checkbox'
import Doubt from '../../../../../../assets/images/users.svg'

interface Props {
  setParticipantDrawerVisible: any
  projectid: any
  participationId: any
  participantData: any
}
export const EditParticipantForm: React.FC<Props> = ({
  setParticipantDrawerVisible,
  projectid,
  participationId,
  participantData,
}): JSX.Element => {
  const [name, setname] = useState(participantData.name)
  const [first_name, setFirstName] = useState(participantData.first_name)
  const [last_name, setLastName] = useState(participantData.last_name)
  const [email, setemail] = useState(participantData.email)
  const [phone, setphone] = useState(participantData.phone)
  const [chk, setchk] = useState(false)
  const [raterList, setRaterList] = useState([])

  const [selected_raters, setselectedRaters] = useState(
    participantData.selected_raters,
  )
  const [employeeList, setEmployeeList] = useState<any>([])
  const [employeeId, setEmployeeId] = useState<any>(participantData.employee_id)
  const [pId, setParticipationId] = useState<any>(
    participantData.participationId,
  )

  // Error Stats
  const [nameErr, setnameErr] = useState('')
  const [first_nameErr, setFirstNameErr] = useState('')
  const [last_nameErr, setLastNameErr] = useState('')
  const [emailErr, setemailErr] = useState('')
  const [phoneErr, setphoneErr] = useState('')

  const { Option } = Select

  useEffect(() => {
    setname(participantData.name)
    setFirstName(participantData.first_name)
    setLastName(participantData.last_name)
    setemail(participantData.email)
    setphone(participantData.phone)
    setselectedRaters(participantData.selected_raters)
    setEmployeeId(participantData.employee_id)

    setParticipationId(participantData.participationId)
    // get list of raters using project id
    getAllRaters(projectid).then((res: any) => {
      const { data } = res.data
      setRaterList(data)
    })

    // get list of employees using project id
    // getMyProjectEmployeeLists(projectid).then((res: any) => {
    //   setEmployeeList(res.data.data)
    // })
  }, [participantData])
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  const handleSubmit = (): void => {
    setnameErr('')
    setFirstNameErr('')
    setLastNameErr('')
    setemailErr('')
    setphoneErr('')

    // check Validation
    let valid = true
    if (name == '') {
      setnameErr('Please Enter Valid Name')
      valid = false
    }
    if (first_name == '') {
      setFirstNameErr('Please Enter Valid First Name')
      valid = false
    }
    if (last_name == '') {
      setLastNameErr('Please Enter Valid Last Name')
      valid = false
    }
    if (email == '' || !email.match(emailRegex)) {
      setemailErr('Please Enter Valid Email')
      valid = false
    }
    // if(phone==''){
    //   setphoneErr("Please Enter Valid Phone")
    //   valid = false
    // }
    if (valid) {
      // Create Object of Updated Data
      const apidata = {
        name,
        first_name,
        last_name,
        phone,
        email,
        selected_raters,
        company: participantData.company._id,
        project_id: participantData.project_id,
        employee_id: employeeId,
        participation_id: pId,
      }

      // edit participant api
      updateParticipationApi(apidata, participationId).then((res: any) => {
        if (res.status !== 200) {
          notification.error({
            message: 'something went wrong refresh pages',
          })
        } else {
          notification.success({
            message: 'Participant Update successfully',
          })
        }
        setParticipantDrawerVisible(false)
      }) // api function over
    } // if over
  }
  return (
    <Form
      // name="participationForm"
      className="form-wrapper p-3"
      onFinish={handleSubmit}
      // initialValues={participantData}
    >
      <div className="survey-inner-formd">
        <div className="form-group">
          <label htmlFor="inputName4" className="form-label">
            Name
            <span className="asterik">*</span>
          </label>
          <Form.Item
            // name="name"
            // initialValue={participantData.name}
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input
              className="form-control"
              onChange={(e: any) => setname(e.target.value)}
              value={name}
              placeholder="Please enter Name"
            />
            <label className="text-danger">{nameErr}</label>
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 pe-1">
            <label htmlFor="inputForFname" className="form-label">
              First Name
              <span className="asterik">*</span>
            </label>
            <Form.Item
              rules={[{ required: true, message: 'Please enter first name' }]}
            >
              <Input
                className="form-control"
                id="inputForFname"
                onChange={(e: any) => setFirstName(e.target.value)}
                value={first_name}
                placeholder="Enter First Name"
              />
              <label className="text-danger">{first_nameErr}</label>
            </Form.Item>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputForLname" className="form-label">
              Last Name
              <span className="asterik">*</span>
            </label>
            <Form.Item
              rules={[{ required: true, message: 'Please enter last name' }]}
            >
              <Input
                className="form-control"
                id="inputForLname"
                onChange={(e: any) => setLastName(e.target.value)}
                value={last_name}
                placeholder="Enter Last Name"
              />
              <label className="text-danger">{last_nameErr}</label>
            </Form.Item>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputEmail4" className="form-label">
            Email
            <span className="asterik">*</span>
          </label>
          <Form.Item
            // name="email"
            rules={[
              {
                type: 'email',
                message: 'The email is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter E-mail!',
              },
            ]}
          >
            <Input
              onChange={(e: any) => setemail(e.target.value)}
              placeholder="Please enter E-mail!"
              value={email}
            />
            <label className="text-danger">{emailErr}</label>
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Phone
              {/* <span className="asterik">*</span> */}
            </label>
            <Form.Item>
              <Input
                onChange={(e: any) => setphone(e.target.value)}
                placeholder="Enter phone number"
                value={phone}
                maxLength={15}
              />
              <label className="text-danger">{phoneErr}</label>
            </Form.Item>
          </div>
          {/* <div className="col-md-6 reminder-wrapper">
            <CheckBox
              checkboxClass="survey-checkbox"
              onClick={() => {
                setchk(!chk)
              }}
            >
              <span className="reminder-text">Send Reminders ?</span>
              <img src={Doubt} alt="question" className="reminder-img" />
            </CheckBox>
          </div> */}
        </div>
        <div className="form-group">
          <label htmlFor="inputRater" className="form-label">
            Select Raters
          </label>
          <Form.Item
            rules={[{ required: true, message: 'Please Select Raters' }]}
          >
            <Select
              // onChange={(e: any) => getRaters(e)}
              value={selected_raters}
              onChange={(e: any) => setselectedRaters(e)}
              placeholder="Select Project"
            >
              {raterList.length > 0 &&
                raterList.map((category: any) =>
                  category.title != 'Self' ? (
                    <Option key={category._id} value={category._id}>
                      {category.title}
                    </Option>
                  ) : null,
                )}
            </Select>
          </Form.Item>
        </div>
        {/* <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Employee
              <span className="asterik">*</span>
            </label>
            <Form.Item
              rules={[{ required: true, message: 'Please Select Employee' }]}
            >
              <Select
                value={employeeId}
                onChange={(e: any) => setEmployeeId(e)}
                placeholder="Select Project"
              >
                {employeeList &&
                  employeeList.map((emp: any) => (
                    <Option value={emp._id} key={emp._id}>
                      {emp.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div> */}
        {/* <div className="form-row">
          <div className="col-md-12 reminder-wrapper">
            <CheckBox
              checkboxClass="survey-checkbox"
              onClick={() => {
                setchk(!chk)
              }}
            >
              <span className="reminder-text">Send Reminders ?</span>
              <img src={Doubt} alt="question" className="reminder-img" />
            </CheckBox>
          </div>
        </div> */}
        <div className="survey-note-wrapper">
          <span className="survey-note-title">Please Note</span>
          <span className="survey-note-desc">
            User will receive an invite email to join to the particular Company
          </span>
        </div>
      </div>
      <div className="button-group">
        <Button
          className="cancelButton"
          onClick={() => setParticipantDrawerVisible(false)}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="saveButton">
          Save
        </Button>
      </div>
    </Form>
  )
}
