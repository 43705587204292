import React from 'react'
import { Link } from 'react-router-dom'
import './listItem.scss'

interface Props {
  id?: number
  empName?: string
  surveys?: number
  empId?: number
  designation?: string
}
export const ListItem: React.FC<Props> = ({
  id,
  empName,
  surveys,
  empId,
  designation,
}): JSX.Element => {
  const newid = id === undefined ? 1 : id
  return (
    <div className="raterslist-wrapper wrapper-white">
      <div className="col-md-4">
        <span className="m-text-head-name">{empName}</span>
      </div>
      <div className="col-md-3">
        <span className="m-text-head">{empId}</span>
      </div>
      <div className="col-md-2">
        <span className="m-text-head">{surveys}</span>
      </div>
      <div className="col-md-3">
        <span className="m-text-head">{designation}</span>
      </div>
      {/* <img src={} alt="" className="bin-img" />
      <img/> */}
    </div>
  )
}

ListItem.defaultProps = {
  id: 9,
  empName: 'Work Place Survey ',
  surveys: 20,
  empId: 10,
  designation: 'Co worker',
}
