import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  HashRouter,
  BrowserRouter,
} from 'react-router-dom'
import { MyReports } from '../views/my-reports/myReports'
import { Templates } from '../views/my-projects/Templates'
import { projectList } from '../views/my-projects/projectList'
import Navbar from '../components/Navbar'
import FooterComponent from '../components/Footer'
import Dashboard from '../views/dashboard'
import Login from '../views/login'
import Company from '../views/company'
import ResetPassword from '../views/reset-password'
import { ProjectDetail } from '../views/my-projects/projectDetail'
import { CompanyProfile } from '../views/company/companyProfile'
import UserInquiry from '../views/user'
import { DrawerCheck } from '../views/my-projects/tabs/components/user/components/Drawer/drawerCheck'
import { ChangePassword } from '../views/change-password/changePassword'
import { UserSurvey } from '../views/user-survey/user-survey'
import { Test } from '../views/reportPdf/test'
import { ReportPdf } from '../views/reportPdf/reportPdf'
import { ReportPDF2 } from '../views/reportPdf/reportPDF2'
import { Settings } from '../views/settings/Settings'
import participants from '../views/participants'

const LayoutMain: React.FC = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route path="/user-inquiry" component={UserInquiry} />
        <Route path="/login" component={Login} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/change-password/:id/:token" component={ChangePassword} />
        <Route path="/UserSurvey/:userId/:surveyID" component={UserSurvey} />
        <Route path="/reportPdf/:project/:employee" component={ReportPDF2} />
        <Route
          path="/reportPdftest/:project/:employee"
          component={ReportPDF2}
        />
        <div className="position-relative">
          <Navbar />
          <Route path="/help" component={UserInquiry} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/my-reports" component={MyReports} />
          <Route path="/my-project" component={projectList} />
          <Route path="/company-detail/:id" component={CompanyProfile} />
          <Route path="/company" component={Company} />
          {/* <Route path="/myproject" component={projectList} /> */}
          <Route path="/template" component={Templates} />
          <Route path="/project-detail/:id" component={ProjectDetail} />
          <Route path="/userdrawer" component={DrawerCheck} />
          <Route path="/settings" component={Settings} />
          <Route path="/users" component={participants} />
          <FooterComponent />
        </div>
      </Switch>
    </BrowserRouter>
  )
}

export default LayoutMain
