/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Row, Col, Drawer, Spin } from 'antd'
import { Button } from 'react-bootstrap'
import { FilterBar } from './components/user/filterbar'
// import { User } from './components/user/filterbar'
import { UserGrid } from './UserGrid'
import { UserListView } from './UserListView'
import { UserDetail } from './components/user/userDetail'
import { UserList } from './components/user/userlistdata'
import {
  getProjectsEmployeeApi,
  getEmployeeDetail,
  filterProjectEmployeesApi,
} from '../../../api/API'
// import { UserCard } from './components/user/userCard'
import Image from '../../../components/Image'
import Label from '../../../components/Label'
import addCompany from '../../../assets/images/addCompany.svg'
import { CREATE_NEW, DO_NOT_EMPLOYEE } from '../../../constants/index'

interface Props {
  projectDetail: any
  getProjectDetailFn: any
}

export const User: React.FC<Props> = ({
  projectDetail,
  getProjectDetailFn,
}) => {
  // const location = useLocation()
  const params = useParams<any>()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [userList, setUserList] = useState(UserList)
  const [details, setDetails] = useState()
  const [participantList, setParticipantList] = useState()
  const [filterQuery, setFilterQuery] = useState<any>({})

  const [viewType, setviewType] = useState<any>()
  const [xl, setXl] = useState<number>(8)
  const [lg, setLg] = useState<number>(12)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [EmployeeId, setEmployeeId] = useState('')

  useEffect(() => {
    getProjectsEmployee()
  }, [currentPage, projectDetail])

  const getProjectsEmployee = (): void => {
    const data = {
      page_no: currentPage,
    }
    setLoading(true)
    console.log(params.id)
    getProjectsEmployeeApi(params.id, data).then((res: any): any => {
      setUserList(res.data.data.docs)
      setTotalPages(res.data.data.totalPages)
      setLoading(false)

      setviewType(
        <UserGrid
          getProjectsEmployee={getProjectsEmployee}
          userlist={res.data.data.docs}
          getDetail={getDetail}
          getProjectDetailFn={getProjectDetailFn}
        />,
      )
    })
  }

  const getDetail = (id: any, isEditable: any): void => {
    setEmployeeId(id)
    getEmployeeDetail(id).then((res: any) => {
      const { data } = res.data

      setDetails(data.detail)
      setParticipantList(data.participant)
      if (isEditable === 'true') {
        setIsEdit(false)
      } else {
        setIsEdit(true)
      }
      setDrawerVisible(true)
    })
  }

  // const [viewType, setviewType] = useState(<UserGrid />)
  const changeViewType = (type: string): any => {
    if (type === 'grid') {
      setviewType(
        <UserGrid
          getProjectsEmployee={getProjectsEmployee}
          userlist={userList}
          getDetail={getDetail}
          getProjectDetailFn={getProjectDetailFn}
        />,
      )
      // setLg(12)
      // setXl(8)
    } else {
      setviewType(<UserListView userlist={userList} getDetail={getDetail} />)
      // setLg(24)
      // setXl(12)
    }
  }

  const filterProjectEmployees = (key: string, value: any): void => {
    const existingQury = filterQuery
    if (key === 'name' && value.target.value !== '') {
      existingQury[key] = value.target.value
    }
    if (key === 'status' && value !== 'all') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }
    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }
    if (key === 'date_added' && value === null) {
      getProjectsEmployee()
      return
    }
    if (key === 'name' && value.target.value === '') {
      getProjectsEmployee()
      return
    }
    if (key === 'mode' && value === 'all') {
      getProjectsEmployee()
      return
    }
    setFilterQuery(existingQury)
    filterProjectEmployeesApi(params.id, existingQury)
      .then((res: any): any => {
        setUserList(res.data.data.docs)
        setviewType(
          <UserGrid
            getProjectsEmployee={getProjectsEmployee}
            userlist={res.data.data.docs}
            getDetail={getDetail}
            getProjectDetailFn={getProjectDetailFn}
          />,
        )
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          console.log(error)
        }
      })
  }

  return (
    <div className="user-list-container">
      <FilterBar
        getProjectsEmployee={getProjectsEmployee}
        filterProjectEmployees={filterProjectEmployees}
        changeViewType={changeViewType}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <hr />
      {viewType}
      {loading && <Spin className="spinner-code" />}
      <div className="h-100vh">
        {/* display when there is no user */}
        {userList.length === 0 && !loading && (
          // <p className="text-center h-100">Projects Not Available</p>
          <div className="company-main">
            <div className="company-main-section">
              <Image
                src={addCompany}
                alt="Add User"
                style={{ marginBottom: '20px', maxWidth: '100px' }}
              />
              <Label
                title={DO_NOT_EMPLOYEE}
                style={{
                  marginBottom: '20px',
                  fontSize: '1rem',
                  fontFamily: 'Calibri',
                  color: '#b6becc',
                  textAlign: 'center',
                }}
              />
            </div>
          </div>
        )}

        {/* {userList.length === 0 && (
          <p className="text-center w-100">Users not avilable </p>
        )}
        {userList.length > 0 && (
          <Row gutter={[6, 6]}>
            {userList.map((user) => (
              <Col xs={24} sm={12} md={12} lg={lg} xl={xl} key={user.id}>
                <UserCard data={user} getDetail={getDetail} />
              </Col>
            ))}
          </Row>
        )} */}
        <div className="drawer-wrapper">
          <Drawer
            closable={false}
            visible={drawerVisible}
            width={window.innerWidth > 550 ? 465 : '100%'}
          >
            <UserDetail
              getProjectsEmployee={getProjectsEmployee}
              setDrawerVisible={setDrawerVisible}
              details={details}
              participantList={participantList}
              employeeId={EmployeeId}
              getDetail={getDetail}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          </Drawer>
        </div>
      </div>
    </div>
  )
}
