import React from 'react'
import './styles/drawerGraph.scss'

import { PieChart, Pie, Cell } from 'recharts'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'

interface Props {
  percentage?: number
  data?: any[]
}
export const DrawerChart: React.FC<Props> = ({
  percentage,
  data,
}): JSX.Element => {
  const ndata = data === undefined ? [] : data
  return (
    <div className="drawer-pie-wrapper">
      <div className="drawer-statsbody">
        <div className="drawer-pie">
          <PieChart width={208} height={208} className="">
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={80}
              innerRadius={40}
              fill="#8884d8"
            >
              {ndata.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <span className="drawer-percent">{percentage}%</span>
        </div>
      </div>
      <div className="drawer-pie-label-wrapper">
        <div className="d-flex w-50">
          <div className="drawer-dot1 me-3" />
          <span className="drawer-label-text">Not Completed Survey</span>
        </div>
        <div className="d-flex">
          <div className="drawer-dot2 me-3" />
          <span className="drawer-label-text">Completed Survey</span>
        </div>
      </div>
    </div>
  )
}
DrawerChart.defaultProps = {
  percentage: 35,
  data: [
    { name: 'Not Completed Survey', survey: 65, color: '#fedd84' },
    { name: 'Completed Survey', survey: 35, color: '#febe13' },
  ],
}
