/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { ProjectCard } from './projectCard'
import { UserList } from './projectlistdata'

interface Props {
  company: any
  list: any
  deleteProject: any
  changeMode: any
}
export const ProjectGrid: React.FC<Props> = ({
  company,
  list,
  deleteProject,
  changeMode,
}): JSX.Element => {
  const [data, setData] = useState(company)
  const [projectList, setProjectList] = useState(list)
  // useEffect(() => {
  //   setData(company)
  //   setProjectList(list)
  // })
  return (
    <>
      <Row gutter={[6, 6]}>
        {projectList.map((project: any) => (
          <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} key={project._id}>
            <ProjectCard
              newdata={project}
              deleteProject={deleteProject}
              changeMode={changeMode}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}
