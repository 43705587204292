/* eslint-disable @typescript-eslint/explicit-function-return-type */
const projectInfoReducer = (projectInfo = [], action) => {
  let response = projectInfo
  switch (action.type) {
    case 'STORE_PROJECT_INFO':
      response = projectInfo
      break
    case 'ADD_PROJECT_INFO':
      projectInfo.push(action.payload)
      response = action.payload
      break
    default:
      response = projectInfo
      break
  }
  return response
}

export { projectInfoReducer }
