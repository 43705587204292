import React from 'react'
import EmployeeChart from './Charts/employee-chart'
import RaterChart from './Charts/rater-chart'

interface Props {
  participantList: any
  employeeId: any
}

export const Framwork: React.FC<Props> = ({
  participantList,
  employeeId,
}): JSX.Element => {
  // export const Framwork: React.FC = (): JSX.Element => {
  return (
    <div className="user-detail-charts-in-popup">
      <EmployeeChart
        participantList={participantList}
        employeeId={employeeId}
      />
      <RaterChart employeeId={employeeId} />
    </div>
  )
}
