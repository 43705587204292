/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Form } from 'react-bootstrap'
import { Label } from './Label'

interface Props {
  data: any
  block: any
  updateQuestionList: any
  allowMultiple: boolean
  metrixtableScaleArray: any
}

export const MatrixTable: React.FC<Props> = ({
  data,
  block,
  metrixtableScaleArray,
  updateQuestionList,
  allowMultiple,
}): JSX.Element => {
  return (
    <table className="mt-2 mb-2 matrix-table">
      <thead>
        <tr>
          <th>&nbsp;</th>
          {metrixtableScaleArray.map((_: any, index: any) => {
            return (
              <th key={index}>
                <Label
                  placeholder={`Click to write Scale Point ${index}`}
                  name={`matrix_table_scale_point_${index}_${block}`}
                  updateQuestionList={updateQuestionList}
                  forWhat="scale-point-value"
                  currentLablePosition={index}
                />
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((options: any, index: any) => {
          return (
            <tr key={index}>
              <td style={{ width: '20%' }}>
                <Label
                  placeholder={`Click to write Scale Point statement ${index}`}
                  name={`matrix_table_scale_point_statement_${index}_${block}`}
                  updateQuestionList={updateQuestionList}
                  forWhat="scale-point-statement"
                  value={options.text}
                  currentLablePosition={index}
                />
              </td>
              {metrixtableScaleArray.map((_: any, indexofspa: any) => (
                <td key={indexofspa} className="text-center">
                  <Form.Check
                    type={allowMultiple ? 'checkbox' : 'radio'}
                    name={`matrix_table_scale_point_radio_${index}_${block}`}
                  />
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
