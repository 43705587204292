import React, { useState, useEffect } from 'react'
import 'antd/dist/antd.css'
import './style/report.scss'
import Nav from '../../components/Navbar'
import Footer from '../../components/Footer'
import Header from './header'
import FilterBar from './filterbar'
import { ProjectTable } from './table/projectTable'
import { CompanyTable } from './table/companyTable'
// import { EmployeeProjectTable } from './table/employeeProjectTable'
import { projectList, companyList } from './list'
import { readCookie } from '../../api/CookieScript'
import {
  SUPER_ADMIN_ROLE,
  COMPANY_ROLE,
  ROLE_KEYWORD,
} from '../../constants/index'
// import { CompanyFilterBar } from './companyFilterBar'
// import { ProjectFilterBar } from './ProjectFilterBar'

const options = [
  { label: 'Company', value: 'company' },
  { label: 'Project', value: 'project' },
]
export const MyReports: React.FC = (): JSX.Element => {
  const [state, setstate] = useState('company')
  const [list, setList] = useState<any>()
  const role = readCookie('role')
  // const onChangeTab = (e: any): any => {
  // if (e.target.value === 'company') {
  useEffect(() => {
    if (readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE) {
      setList(<CompanyTable />)
    } else {
      setList(<ProjectTable />)
    }
  }, [])

  // return setstate(e.target.value)
  // }
  return (
    <div className="min-85vh">
      <Header tabName={options} state={state} />
      <div className="report-container min-h-100vh">
        <hr />
        {list}
      </div>
    </div>
  )
}
