import React from 'react'
import { Button } from 'antd'
import './style/button.scss'

interface Props {
  children?: React.ReactNode
  onClick?: () => void
  btnClass?: string
  // btnType?: 'submit' | 'button' | 'reset'
  // disabled?: boolean
  // width?: number
  // height?: number
}

export const ButtonComponent: React.FC<Props> = ({
  children,
  onClick,
  btnClass,
  // btnType,
  // disabled,
  // width,
  // height,
}): JSX.Element => {
  return (
    <Button onClick={onClick} className={`ant-btn btn ${btnClass}`}>
      {children}
    </Button>
  )
}

ButtonComponent.defaultProps = {
  // btnType: 'button',
  // disabled: false,
  // width: 120,
  // height: 40,
  children: 'Button',
  btnClass: 'btn-theme',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
}
