import React, { useState } from 'react'
import { Button, Drawer } from 'antd'
import { TabPanel } from './myDrawer'
import { DrawerHeader } from './userDrawerHeader'

export const DrawerCheck: React.FC = (): JSX.Element => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  return (
    <div className="min-85vh">
      <Button onClick={() => setDrawerVisible(true)}>Open User Drawer</Button>
      <Drawer
        onClose={() => {
          setDrawerVisible(false)
        }}
        visible={drawerVisible}
        title={<DrawerHeader />}
        width={462}
        // footer={<DrawerFooter />}
      >
        <TabPanel />
      </Drawer>
    </div>
  )
}
